import { Box, Popover, Typography } from "@mui/material";
import useUserInfo from "hooks/useUserInfo";

function ChatPopover(props: any) {
  const { anchorEl, onClose, open, ...other } = props;
  const { user } = useUserInfo();

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      open={open}
      PaperProps={{
        sx: { width: "300px" },
      }}
      sx={{ top: "10px" }}
      onClose={onClose}
      {...other}
    >
      <Box
        sx={{
          py: 1.5,
          px: 2,
        }}
      >
        <Typography variant="overline">Account</Typography>
        <Typography color="text.secondary" variant="body2">
          {user?.fullName}
        </Typography>
      </Box>
    </Popover>
  );
}

export default ChatPopover;
