import { useSnackbar } from "components/snackbar";
import { IResponse } from "constants/interfaces";
import useDecodedData from "hooks/useDecodedData";
import AppRoutes from "navigation/appRoutes";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { uploadVideoVoice } from "services/activity.service";
import { saveSetting } from "services/powerdialder.service";
import {
  deleteContactFormId,
  deleteTemplateId,
  saveTemplate,
  saveVideoTemplate,
} from "services/templates.service";
import { QueryKeys } from "utils/QueryKeys";

export interface ITemplate {
  id: number;
  userId: number;
  subject: string;
  type: string;
  templateText: string;
  status: number;
  title: string;
}
export interface ITemplatesRequest {
  id?: number;
  title?: string;
  type: string;
  subject: string;
  userId?: number;

  templateText: string;
  customFields?: string;
  client: number;
  customField: string;
  templateUsers: number[];
}

const useApiActions = () => {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const decoded = useDecodedData();
  const trySave = async (values: ITemplatesRequest, type?: string) => {
    try {
      const response: IResponse = await saveTemplate(
        {
          ...values,
          userId: values?.userId || Number(decoded?.id),
        },
        type,
      );
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.templates]);
        queryClient.invalidateQueries([QueryKeys.userSpecificTemplates]);
        snackbar?.show({
          title: response?.message,
          type: "success",
        });
      }
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
    }
  };

  const trySaveVideoVoice = async (values: any) => {
    try {
      const response: IResponse = await saveVideoTemplate({
        ...values,
        userId: Number(decoded?.id),
      });
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.templates]);
        queryClient.invalidateQueries([QueryKeys.videoTemplates]);
        queryClient.invalidateQueries([QueryKeys.voiceTemplates]);
        snackbar?.show({
          title: response?.message,
          type: "success",
        });
      }
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
    }
  };

  const deleteTemplateSingleId = async (id: number) => {
    try {
      const response: IResponse = await deleteTemplateId(id);
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.templates]);
        queryClient.invalidateQueries([QueryKeys.videoTemplates]);
        queryClient.invalidateQueries([QueryKeys.voiceTemplates]);
        queryClient.invalidateQueries([QueryKeys.userSpecificTemplates]);
        snackbar?.show({
          title: response?.message,
          type: "success",
        });
      }
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
    }
  };

  const deleteContactFormSingleId = async (id: number) => {
    try {
      const response: IResponse = await deleteContactFormId(id);
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.templates]);
        queryClient.invalidateQueries([QueryKeys.videoTemplates]);
        queryClient.invalidateQueries([QueryKeys.voiceTemplates]);
        snackbar?.show({
          title: response?.message,
          type: "success",
        });
      }
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
    }
  };

  const tryUploadVideoVoice = async (values: any) => {
    try {
      const response: IResponse = await uploadVideoVoice({
        ...values,
        userId: Number(decoded.id),
      });
      if (response.statusCode === 200) {
        snackbar?.show({
          title: "File uploaded successfully",
          type: "success",
        });
      }
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return err;
    }
  };
  const trySavePowerDialSetting = async (values: any) => {
    try {
      const response: IResponse = await saveSetting({
        ...values,
        userId: Number(decoded.id),
      });
      if (response.statusCode === 200) {
        navigate(
          `${AppRoutes.POWER_CALLING_DIALER}/${values.fromStart}/${values.dontCallConnected}`,
        );

        snackbar?.show({
          title: "Setting saved successfully!",
          type: "success",
        });
      }
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return err;
    }
  };
  return {
    trySave,
    deleteTemplateSingleId,
    deleteContactFormSingleId,
    trySaveVideoVoice,
    tryUploadVideoVoice,
    trySavePowerDialSetting,
  };
};

export { useApiActions };

