/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { IIndexes } from "pages/user/activity/query/useFetchDueToday";
import { useAppDispatch } from "redux/store";

export interface IContactsReducer {
  activityIndexes?: IIndexes[];
}

const initialState: IContactsReducer = {
  activityIndexes: [],
};

interface IPayload {
  activityIndexes: IIndexes[];
}

interface IAction {
  payload: IPayload;
  type: string;
}

const activitySlice = createSlice({
  name: "activity",
  initialState,
  reducers: {
    setActivityIndexes: (state, action: IAction) => {
      state.activityIndexes = action.payload.activityIndexes;
    },
  },
});

export const { setActivityIndexes } = activitySlice.actions;

export const useActivityActions = () => {
  const dispatch = useAppDispatch();
  return {
    setActivityIndexes: (payload: IPayload) =>
      dispatch(setActivityIndexes(payload)),
  };
};
const { reducer } = activitySlice;

export default reducer;
