import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import SettingsVoiceIcon from "@mui/icons-material/SettingsVoice";
import { Divider, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAudioRecorder } from "react-audio-voice-recorder";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

interface IVoiceNote {
  formik: any;
  setImageListData: any;
  setPreviewList: any;
}

function VoiceNote(props: IVoiceNote) {
  const { setPreviewList, setImageListData } = props;
  const recorderControls = useAudioRecorder();

  const getChats = useSelector((state: RootState) => state.chats);
  const { selectedChatRoomId } = getChats;
  const [counter, setCounter] = useState(0);
  const [, setBase64File] = useState<any>();
  const [, setAudioUrl] = useState<any>();
  const [isActive, setIsActive] = useState(false);
  const {
    recordingBlob,

    startRecording,
    stopRecording,
  } = recorderControls;

  useEffect(() => {
    let timer: any = null;
    if (isActive) {
      timer = setInterval(() => setCounter(counter + 1), 1000);
    }

    return () => clearInterval(timer);
  }, [counter, isActive]);

  useEffect(() => {
    if (selectedChatRoomId) {
      setIsActive(false);
      setCounter(0);
    }
  }, [selectedChatRoomId]);

  useEffect(() => {
    if (recordingBlob) {
      const reader = new FileReader();
      reader.readAsDataURL(recordingBlob);
      reader.onloadend = function () {
        const base64data = reader.result;
        setBase64File(base64data);
      };
      const url = URL.createObjectURL(recordingBlob);
      const file = new File([recordingBlob], "recording.mp3", {
        type: "audio/mpeg",
        lastModified: Number(new Date()),
      });
      setPreviewList((prevImages: any) => [
        ...prevImages,
        { url, fileType: "audio/mpeg" },
      ]);
      setImageListData([file]);
      setAudioUrl?.(url);
    }
  }, [recordingBlob]);

  useEffect(() => {
    if (selectedChatRoomId) {
      setIsActive(false);
      setCounter(0);
    }
  }, [selectedChatRoomId]);

  const handleStartRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log({ stream });
      startRecording();
      if (typeof setAudioUrl === "function") {
        setAudioUrl("");
      }
      setIsActive(true);
    } catch (err) {
      console.log({ err });
      alert("No microphone found or access denied.");
    }
  };

  const handleStopRecording = () => {
    stopRecording();
    setIsActive(false);
    setCounter(0);
  };

  // const handleReset = () => {
  //   stopRecording();
  //   setAudioUrl?.("");
  //   setIsActive(false);
  //   setCounter(0);
  // };

  function fmtMSS(s: number) {
    // eslint-disable-next-line no-return-assign, no-param-reassign
    return (s - (s %= 60)) / 60 + (s > 9 ? ":" : ":0") + s;
  }
  return (
    <>
      <Divider orientation="vertical" sx={{ height: 28, m: 0.4 }} />
      <IconButton
        aria-label="voice-note"
        color="success"
        sx={{ p: "2px 5px", width: "25px" }}
        type="button"
        onClick={() => {
          isActive ? handleStopRecording() : handleStartRecording();
        }}
      >
        {isActive ? (
          <Stack direction="row">
            <Tooltip title="Stop Recording">
              <SettingsVoiceIcon color="success" />
            </Tooltip>
            <Typography sx={{ textAlign: "right", fontSize: "17px" }}>
              {fmtMSS(counter)}
            </Typography>
          </Stack>
        ) : (
          <Tooltip title="Start Recording">
            <KeyboardVoiceIcon color="primary" />
          </Tooltip>
        )}
      </IconButton>
    </>
  );
}

export default VoiceNote;
