import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Checkbox,
  DialogContent,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Slider from "components/layouts/popup-modals/Slider";
import React from "react";
import Colors from "../__mock__/colors.json";

interface IColorSlider {
  open: boolean;
  handleClose: () => void;
  setColor?: any;
  colorData?: any;
  handleUpdateColor?: (data?: any) => void;
  isCloseFunction?: boolean;
  formik?: any;
  onChange?: (data?: any) => void;
}

function ColorPickerSlider(props: IColorSlider) {
  const {
    handleClose,
    open,
    setColor,
    colorData,
    handleUpdateColor,
    isCloseFunction,
    formik,
    onChange,
  } = props;

  const handleChange = (item: any) => {
    onChange?.(item);
    setColor({
      contactId: colorData?.contactId,
      activityId: colorData?.activityId,
      color: item?.color,
    });
    formik?.setFieldValue("colour", item?.color);
    setTimeout(() => {
      isCloseFunction
        ? handleClose()
        : handleUpdateColor?.({
            color: item?.color,
            contactId: colorData?.contactId,
          });
    }, 500);
  };

  return (
    <div>
      <Slider
        backgroundColor="#fff"
        open={open}
        size="sm"
        onClose={handleClose}
      >
        <DialogContent>
          <Box display="flex" justifyContent="space-between">
            <DialogTitle style={{ padding: "0" }}>Table Color</DialogTitle>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="center"
              spacing={2}
            >
              <IconButton
                aria-label="close"
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: "red",
                }}
                onClick={() => handleClose()}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
          </Box>
          <Paper style={{ padding: 5 }}>
            {/* <PerfectScrollbar> */}
            <Box sx={{ width: 665 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox" sx={{ width: "50px" }}>
                      <Checkbox
                        checked={false}
                        color="primary"
                        onChange={() => {}}
                      />
                    </TableCell>

                    <TableCell
                      padding="checkbox"
                      style={{
                        minWidth: 200,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          // justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          component="p"
                          sx={{
                            fontSize: "inherit",
                            fontWeight: "600",
                          }}
                        >
                          Color
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Colors?.map((item: any) => {
                    return (
                      <TableRow key={item?.id}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={item?.color === colorData?.color}
                            color="primary"
                            onChange={() => handleChange(item)}
                          />
                        </TableCell>

                        <TableCell
                          padding="checkbox"
                          style={{
                            minWidth: 100,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              // justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Stack
                              style={{
                                width: "24px",
                                height: "20px",
                                borderRadius: "2px",
                                backgroundColor: item?.color,
                              }}
                            />
                            <Typography
                              component="p"
                              sx={{
                                fontSize: "inherit",
                                fontWeight: "600",
                                marginLeft: "10px",
                              }}
                            >
                              {item?.desc}
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
            {/* </PerfectScrollbar> */}
          </Paper>
        </DialogContent>
      </Slider>
    </div>
  );
}

export default React.memo(ColorPickerSlider);
