/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line import/newline-after-import
import { useCallback, useEffect, useRef, useState } from "react";

declare const window: any;

const useEventCallback = (fn: any, dependencies: any) => {
  const ref = useRef(() => {
    throw new Error("Cannot call an event handler while rendering.");
  });

  useEffect(() => {
    ref.current = fn;
  }, [fn, ...dependencies]);

  return useCallback(
    (args: any) => {
      const fn: any = ref.current;
      return fn(args);
    },
    [ref],
  );
};

const useSpeechRecognition = (props: any = {}) => {
  const { onEnd = () => {}, onResult = () => {}, onError = () => {} } = props;
  const recognition: any = useRef(null);
  const [listening, setListening] = useState(false);
  const [supported, setSupported] = useState(false);

  const processResult = (event: any) => {
    const transcript = Array.from(event.results)
      .map((result: any) => result[0])
      .map((result: any) => result.transcript)
      .join("");

    onResult(transcript);
  };

  const handleError = (event: any) => {
    if (event.error === "not-allowed") {
      recognition.current.onend = () => {};
      setListening(false);
    }
    onError(event);
  };

  const listen = useEventCallback(
    (args = {}) => {
      if (listening || !supported) return;
      const {
        lang = "",
        interimResults = true,
        continuous = false,
        maxAlternatives = 1,
        grammars,
      }: any = args;
      setListening(true);
      recognition.current.lang = lang;
      recognition.current.interimResults = interimResults;
      recognition.current.onresult = processResult;
      recognition.current.onerror = handleError;
      recognition.current.continuous = continuous;
      recognition.current.maxAlternatives = maxAlternatives;
      if (grammars) {
        recognition.current.grammars = grammars;
      }
      // SpeechRecognition stops automatically after inactivity
      // We want it to keep going until we tell it to stop
      recognition.current.onend = () => recognition.current.start();
      recognition.current.start();
    },
    [listening, supported, recognition],
  );

  const stop = useEventCallback(() => {
    if (!listening || !supported) return;
    recognition.current.onresult = () => {};
    recognition.current.onend = () => {};
    recognition.current.onerror = () => {};
    setListening(false);
    recognition.current.stop();
    onEnd();
  }, [listening, supported, recognition, onEnd]);

  useEffect(() => {
    if (typeof window === "undefined") return;
    window.SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    if (window.SpeechRecognition) {
      setSupported(true);
      recognition.current = new window.SpeechRecognition();
    }
  }, []);

  return {
    listen,
    listening,
    stop,
    supported,
  };
};

export default useSpeechRecognition;
