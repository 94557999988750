/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { useAppDispatch } from "redux/store";

export interface ISelectedClintId {
  clintId: number;
}

const initialState: ISelectedClintId = {
  clintId: 0,
};

interface IPayload {
  clintId: number;
}

interface IAction {
  payload: IPayload;
  type: string;
}
const clintIdSlice = createSlice({
  name: "selectedClintId",
  initialState,
  reducers: {
    setClintId: (state, action: IAction) => {
      state.clintId = action.payload.clintId;
    },
  },
});

export const { setClintId } = clintIdSlice.actions;

export const useClintIdSliceActions = () => {
  const dispatch = useAppDispatch();
  return {
    setClintId: (payload: IPayload) => dispatch(setClintId(payload)),
  };
};
const { reducer } = clintIdSlice;
export default reducer;
