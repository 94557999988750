import Landing_page from "./Landing_page.png";
import brownies from "./brownies.webp";
import checkIcon from "./checkicon.png";
import logo from "./contactaholic-logo.png";
import emailIcon from "./emailicon.png";
import penIcon from "./img_writing.png";
import pdfIcon from "./pdficon.png";
import personalIcon from "./personalIcon.png";
import Starcbuck from "./starbucks.jpg";

const Imagespath = {
  Landing_page,
  personalIcon,
  emailIcon,
  penIcon,
  pdfIcon,
  logo,
  checkIcon,
  Starcbuck,
  brownies,
};

export default Imagespath;
