/* eslint-disable @typescript-eslint/naming-convention */

import { useSnackbar } from "components/snackbar";
import { IResponse } from "constants/interfaces";
import { useQueryClient } from "react-query";
import {
  IAddCallTranscription,
  IToggleContactStatus,
  UpdateCallNoteById,
  UpdateDispositionSid,
  addCallSchedule,
  addCallTranscription,
  blindTransfer,
  callEnd,
  deleteSuggestions,
  fetchSuggestions,
  getAgentCallBySid,
  getCompanyDetailsByConfId,
  holdUnhold,
  removeConference,
  saveSuggestions,
  startCallWrap,
  toggleContact,
  updateCallNote,
  updateCallSid,
} from "services/callManager.service";
import { QueryKeys } from "utils/QueryKeys";
import { IAddCallSchedule } from "../hook/useAddCallScheduleForm";

const useAPIActions = () => {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();

  const trySave = async () => {
    try {
      const response: any = await saveSuggestions();
      snackbar?.show({
        title: response?.message,
        type: "success",
      });
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return false;
    }
  };
  const tryDelete = async () => {
    try {
      const response: any = await deleteSuggestions();
      snackbar?.show({
        title: "delete successfully",
        type: "success",
      });
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return false;
    }
  };

  const tryUpdateDispositionSid = async (data: any) => {
    try {
      const response: any = await UpdateDispositionSid(data);
      queryClient.invalidateQueries([QueryKeys.getAllConversation]);
      queryClient.invalidateQueries([QueryKeys.getAllCallLog]);
      snackbar?.show({
        title: response?.message,
        type: "success",
      });
      return response;
    } catch (err: any) {
      return false;
    }
  };
  const tryUpdateCallNoteById = async (data: any) => {
    try {
      const response: any = await UpdateCallNoteById(data);
      // snackbar?.show({
      //   title: response?.message,
      //   type: "success",
      // });
      console.info("success", response?.message);
      return response;
    } catch (err: any) {
      return false;
    }
  };
  const tryStartCallWrap = async (confId: string, userId: number) => {
    try {
      const response: any = await startCallWrap(confId, userId);
      snackbar?.show({
        title: response?.message,
        type: "success",
      });
      return response;
    } catch (err: any) {
      return false;
    }
  };
  const tryUpdateCallNote = async (data: any) => {
    try {
      const response: any = await updateCallNote(data);
      snackbar?.show({
        title: response?.message,
        type: "success",
      });
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return false;
    }
  };
  const tryUpdateCallSid = async (values: any, from = "outbond") => {
    try {
      const response: any = await updateCallSid(values, from);

      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return false;
    }
  };

  const tryGetCompanyDetailsByConfId = async (confId: string) => {
    try {
      const response: any = await getCompanyDetailsByConfId(confId);

      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return err;
    }
  };
  const tryHoldUnhold = async (values: any) => {
    try {
      const response: any = await holdUnhold(values);

      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return false;
    }
  };

  const tryCallEnd = async (values: any) => {
    try {
      console.log("callEnd");
      const response: any = await callEnd(values);

      return response;
    } catch (err: any) {
      return false;
    }
  };

  const tryRemoveConference = async (values: any) => {
    try {
      console.log("callEnd");
      const response: any = await removeConference(values);

      return response;
    } catch (err: any) {
      return false;
    }
  };
  const tryTransferInvite = async (values: any) => {
    try {
      const response: any = await blindTransfer(values);

      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return false;
    }
  };

  const tryGetAgentCallBySid = async (sid: string) => {
    try {
      const response: any = await getAgentCallBySid(sid);
      if (response.status === 200) {
        return response.data;
      }
      return response;
    } catch (error: any) {
      snackbar?.show({
        title: error?.message || "Something went wrong!",
        type: "error",
      });
      return error;
    }
  };
  const getSuggestions = async (data: any): Promise<any> => {
    try {
      const response: any = await fetchSuggestions(data);
      if (response.statusCode === 200) {
        return response.data;
      }
    } catch (error: any) {
      snackbar?.show({
        title: error?.message || "Something went wrong!",
        type: "error",
      });
    }
    return null;
  };

  const tryAddCallSchedule = async (body: IAddCallSchedule) => {
    try {
      const response: IResponse = await addCallSchedule({
        ...body,
      });
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([
          QueryKeys.customerCallsSchedule,
          body.customerId,
        ]);
        queryClient.invalidateQueries([QueryKeys.getAllCallBackList]);
        snackbar?.show({
          title: response?.message,
          type: "success",
        });
      } else {
        snackbar?.show({
          title: response?.message,
          type: "error",
        });
      }
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
    }
  };

  const tryToggleContact = async (
    body: IToggleContactStatus,
  ): Promise<boolean> => {
    try {
      const response: IResponse = await toggleContact(body);

      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.contacts]);
        snackbar?.show({
          title: response.message,
          type: "success",
        });
        return true;
      }
      return false;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
    }
    return false;
  };

  const tryAddCallTranscription = async (body: IAddCallTranscription) => {
    try {
      await addCallTranscription(body);
      return true;
    } catch (err: any) {
      return true;
    }
  };

  return {
    trySave,
    tryDelete,
    getSuggestions,
    tryUpdateCallSid,
    tryUpdateDispositionSid,
    tryHoldUnhold,
    tryUpdateCallNote,
    tryTransferInvite,
    tryAddCallSchedule,
    tryToggleContact,
    tryStartCallWrap,
    tryCallEnd,
    tryGetAgentCallBySid,
    tryAddCallTranscription,
    tryGetCompanyDetailsByConfId,
    tryUpdateCallNoteById,
    tryRemoveConference,
  };
};

export { useAPIActions };
