import { DIALER_DIGITS } from "constants/constants";
import DialerButton from "pages/call-manager/atoms/DialerButton";

function NumberPaid(props: any) {
  const { handleClick } = props;
  return (
    <>
      <DialerButton data={DIALER_DIGITS.Raw1} handleClick={handleClick} />
      <DialerButton data={DIALER_DIGITS.Raw2} handleClick={handleClick} />
      <DialerButton data={DIALER_DIGITS.Raw3} handleClick={handleClick} />
      <DialerButton data={DIALER_DIGITS.Raw4} handleClick={handleClick} />
    </>
  );
}

export default NumberPaid;
