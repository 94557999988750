import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    dialpadIcon: {
      borderRadius: "50%",
      backgroundColor: "#fff",
      width: "2.5rem",
      height: "2.5rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    dialpadIcon_Container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    phoneNumber_TextField: {
      width: "21rem",
      borderRadius: "3rem",
    },
    personIcon: {
      borderRadius: "50%",
      backgroundColor: "#fff",
      width: "2.5rem",
      height: "2.5rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    contact_Container: {
      backgroundColor: "#143159",
      display: "flex",
      justifyContent: "space-between",
      padding: "20px 50px",
    },
    callEndIcon_Container: {
      borderRadius: "0rem",
      backgroundColor: "#68ebcb",
      "&:hover": {
        backgroundColor: "#9ffce7",
      },
    },
    callFrom_Container: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "0.6rem",
    },
    callFrom: {
      width: 245,
      backgroundColor: "#E5E4E2",
      borderRadius: "10px",
      placeholder: "123456789",
    },
    option: {
      fontSize: 12, // Set the font size you want for the options
    },
    inputRoot: {
      fontSize: 12, // Set the font size you want for the input
    },
  }),
);
