import {
  Autocomplete,
  CircularProgress,
  Stack,
  TextField,
} from "@mui/material";
import useDecodedData from "hooks/useDecodedData";
import { debounce } from "lodash";
import {
  ISearchContacts,
  useFetchContactsBySearch,
} from "pages/user/contacts/query/useFetchContactBySearch";
import { SyntheticEvent, useCallback, useEffect, useState } from "react";

interface IProps {
  style?: any;
  selectedContact: (data: any) => void;
}
function ContactSearch(props: IProps) {
  const { style, selectedContact } = props;
  const [dataContacts, setDataContacts] = useState<ISearchContacts[]>([]);
  const [searchText, setSearchText] = useState("");
  const [openOptions, setOpenOptions] = useState(false);
  const [selectedValue, setSelectedValue] = useState<ISearchContacts | null>(
    null,
  );
  const decoded = useDecodedData();

  const {
    data: contactList,
    refetch: refetchContacts,
    isLoading,
    isRefetching,
  } = useFetchContactsBySearch(
    `RoleName=${decoded?.RoleName}&userid=${decoded.id}&search=${searchText}`,
    true,
    false,
  );

  useEffect(() => {
    if (contactList?.data) {
      setDataContacts(contactList.data);
    }
  }, [contactList?.data]);

  // const handleContactSearch = (data: any) => {
  //   selectedContact?.(data);
  //   setSearchText(`${data?.firstName} ${data?.lastName}`);
  //   setDataContacts([]);
  // };

  // const handleContactSearchClose = () => {
  //   setSearchText("");
  //   setDataContacts([]);
  // };

  const debouncedSearch = debounce(() => {
    refetchContacts();
  }, 1000);

  const handleSearchContact = useCallback(
    (_: React.SyntheticEvent, value: string) => {
      setSearchText(value);
      if (value) debouncedSearch();
    },
    [],
  );

  const handleContactSelect = (e: SyntheticEvent, newValue: any) => {
    console.log({ newValue });
    selectedContact(newValue);
    setSelectedValue(newValue);
  };

  const getOptionKey = (option: any) => option.id;

  return (
    <Stack
      alignItems="center"
      direction="row"
      gap={1}
      sx={{
        width: "100%",
        maxWidth: 550,
        position: "relative",
        marginBottom: "1rem",
        marginTop: "1rem",
        ...style,
      }}
    >
      {/* <AutoComplete
        defaultValue={selectedValue}
        getOptionLabel={(item: any) =>
          `${item.firstName || ""} ${item.lastName || ""}`
        }
        handleChange={(event, newValue) => {
          handleContactSelect(event, newValue);
        }}
        inputChange={(e: BaseSyntheticEvent) =>
          handleSearchContact(e.target.value)
        }
        label="Search contacts"
        options={dataContacts || []}
      /> */}
      <Autocomplete
        getOptionLabel={(option: any) =>
          `${option.firstName} ${option.lastName}`
        }
        isOptionEqualToValue={(option, value) =>
          getOptionKey(option) === getOptionKey(value)
        }
        loading={isLoading}
        open={openOptions}
        options={dataContacts || []}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading || isRefetching ? (
                    <CircularProgress color="inherit" size={16} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            label="Search contact"
          />
        )}
        sx={{ width: "100%" }}
        value={selectedValue}
        onChange={handleContactSelect}
        onClose={() => setOpenOptions(false)}
        onInputChange={handleSearchContact}
        onOpen={() => setOpenOptions(true)}
      />
      {/* {isLoading || isRefetching ? <CircularProgress size={26} /> : null} */}
    </Stack>
  );
}

export default ContactSearch;
