/**
 * @format
 */
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";

export interface IAddCallSchedule {
  id: number;
  customerId: number;
  userId: number;
  date: string;
  duration: string;
  durationCount: number;
  isCompleted: boolean;
  setReminderBefore: number;
  time_stamp: string;
  isSendEmailToAttendee: boolean;
  isReminder: boolean;
  // scheduleTimeStamp:string;
}
const defaultValues: IAddCallSchedule = {
  id: 0,
  customerId: 0,
  userId: 0,
  date: "",
  duration: "",
  durationCount: 0,
  isCompleted: false,
  setReminderBefore: 0,
  time_stamp: "",
  isSendEmailToAttendee: false,
  isReminder: false,
  // scheduleTimeStamp: "",
};

const schema = Yup.object().shape({
  date: Yup.string().required("required"),
});

const useAddCallScheduleFrom = (
  onSubmit: (
    values: IAddCallSchedule,
    formikHelpers: FormikHelpers<IAddCallSchedule>,
  ) => void | Promise<unknown>,
  initialValues: IAddCallSchedule = defaultValues,
) => {
  return useFormik<IAddCallSchedule>({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit,
  });
};

export default useAddCallScheduleFrom;
