// import useDecodedData from "hooks/useDecodedData";
import { useQuery } from "react-query";
import { getAllChatFiles } from "services/chat.services";
import { QueryKeys } from "utils/QueryKeys";

export interface IChatFile {
  chatRoomId: number;
  chatId: number;
  fileUrl: string;
  message: string;
  timeStamp?: string;
}
interface IChatFileParams {
  chatRoomId: number;
  enabled?: boolean | false;
  type?: string;
}

export interface ChatImgFile {
  statusCode: number;
  data: IChatFile[];
  totalCount: number;
}

export interface GetAllChatFiles {
  chatRoomId: number;
  chatId: number;
  fileUrl: string;
  message: string;
  timeStamp?: string;
}

async function tryGetAllChatFiles(
  chatRoomId: number,
  type?: string,
): Promise<ChatImgFile> {
  try {
    const response = await getAllChatFiles(chatRoomId, type);

    if (response.statusCode === 200) {
      return response;
    }

    return {
      data: [],
      statusCode: 500,
      totalCount: 0,
    };
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

const useFetchGetFile = ({ chatRoomId, enabled, type }: IChatFileParams) => {
  const cacheKey = [QueryKeys.allChatFiles];
  return useQuery(
    cacheKey,

    () => {
      return tryGetAllChatFiles(chatRoomId, type);
    },
    {
      enabled,
    },
  );
};

export { useFetchGetFile };
