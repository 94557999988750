import {
  IClientLoginRequest,
  IClientLoginResponse,
} from "pages/auth/client-Login/query/useClientLogin";
import { IClientLogoutRequest } from "pages/auth/client-Login/query/useClientLogOut";
import { IResponseCompanyEmailDropDown } from "pages/auth/login/query/useFetchCompanyEmail";
import { IPermissionAccessResponse } from "pages/auth/login/query/useFetchUserAccess";
import { ILoginRequest, ILoginResponse } from "pages/auth/login/query/useLogin";
import { ILogoutRequest } from "pages/auth/login/query/useLogOut";
import { IPaymentDoneResponse } from "pages/auth/payment/query/useApiActions";
import { IInvoiceSummaryResponse } from "pages/auth/payment/query/useFetchInvoiceSummary";
import client from "utils/ApiClient";
import {
  ISignupRequest,
  ISignupResponse,
} from "../pages/auth/signup/query/useSignupApi";
import API_URLS from "./endPoints";

async function login(values: ILoginRequest): Promise<ILoginResponse> {
  return client.post(API_URLS.LOGIN, values);
}
async function clientLogin(
  values: IClientLoginRequest,
): Promise<IClientLoginResponse> {
  return client.post(API_URLS.CLIENT_LOGIN, values);
}
async function forgotPassword(values: any): Promise<any> {
  return client.post(API_URLS.FORGOT_PASSWORD, values);
}
async function resetPassword(values: any): Promise<any> {
  return client.post(API_URLS.RESET_PASSWORD, values);
}
async function adminSignup(values: ISignupRequest): Promise<ISignupResponse> {
  return client.post(API_URLS.ADMIN_SIGNUP, values);
}

async function logout(req: ILogoutRequest): Promise<ILoginResponse> {
  return client.post(`${API_URLS.LOGOUT}/${req?.id}/${req?.fcmToken}`);
}
async function logoutSuperAdmin(req: ILogoutRequest): Promise<ILoginResponse> {
  return client.post(`${API_URLS.LOGOUT_SUPER_ADMIN}/${req?.id}`);
}
async function logoutClient(
  req: IClientLogoutRequest,
): Promise<IClientLoginResponse> {
  console.log({ req });
  return client.post(`${API_URLS.LOGOUT_CLIENT}/${req?.id}`);
}
async function generateToken(values: string): Promise<any> {
  return client.post(`${API_URLS.GENERATE_TOKEN}?tempstring=${values}`);
}

async function validateToken(values: string): Promise<any> {
  return client.get(`${API_URLS.VALIDATE_TOKEN}?jwtToken=${values}`);
}
async function fetchCompanyEmailDropDown(
  email: string,
): Promise<IResponseCompanyEmailDropDown> {
  const url = `${API_URLS.FETCH_ALL_COMPANY_EMAIL_DROP_DOWN}?email=${email}`;
  return client.get(url);
}
async function fetchUserAccess(
  userId: string,
): Promise<IPermissionAccessResponse> {
  return client.get(
    `${API_URLS.GET_USER_COMMUNICATION_ACCESS}?userId=${userId}`,
  );
}
async function fetchClientAccess(
  userId: string,
): Promise<IPermissionAccessResponse> {
  return client.get(
    `${API_URLS.GET_CLIENT_COMMUNICATION_ACCESS}?userId=${userId}`,
  );
}

async function sendPaymentRequest(values: any): Promise<IPaymentDoneResponse> {
  return client.post(`${API_URLS.SEND_PAYMENT_REQUEST}`, values);
}

async function sendCheckoutPaymentRequest(
  values: any,
): Promise<IPaymentDoneResponse> {
  return client.post(`${API_URLS.SEND_CHECKOUT_PAYMENT_REQUEST}`, values);
}

async function bonusPaymentRequest(values: any): Promise<any> {
  return client.post(`${API_URLS.BONUS_PAYMENT_REQUEST}`, values);
}
async function fetchInvoiceSummary(
  paymentId: number,
  // pageNo: number,
  // pageLimit = 10,
): Promise<IInvoiceSummaryResponse> {
  // const url = `${API_URLS.FETCH_CONTACT_V2}?page=${pageNo + 1}&pageSize=${
  //   pageLimit || PER_PAGE
  // }`;
  const url = `${API_URLS.FETCH_INVOICE_SUMMARY}?paymentId=${paymentId}`;
  return client.get(url);
}
async function uploadInvoicePdf(values: any): Promise<any> {
  const url = `${API_URLS.UPLOAD_INVOICE}`;
  return client.post(url, values);
}

async function logoutAll(
  id: number,
  companyId: number,
): Promise<ILoginResponse> {
  return client.post(`${API_URLS.ALL_LOGOUT}/${id}/${companyId}`);
}

async function getAccounts(id: number): Promise<ILoginResponse> {
  return client.get(`${API_URLS.GET_ACCOUNTS}/${id}`);
}

async function addAccount(data: any): Promise<ILoginResponse> {
  return client.post(`${API_URLS.ADD_ACCOUNT}`, data);
}
async function deleteAccount(id: any): Promise<any> {
  return client.delete(`${API_URLS.DELETE_ACCOUNT}/${id}`);
}

async function updateTimeZone(body: any): Promise<any> {
  return client.patch(`${API_URLS.UPDATE_TIMEZONE}`, body);
}
export {
  addAccount,
  adminSignup,
  bonusPaymentRequest,
  clientLogin,
  deleteAccount,
  fetchClientAccess,
  fetchCompanyEmailDropDown,
  fetchInvoiceSummary,
  fetchUserAccess,
  forgotPassword,
  generateToken,
  getAccounts,
  login,
  logout,
  logoutAll,
  logoutClient,
  logoutSuperAdmin,
  resetPassword,
  sendCheckoutPaymentRequest,
  sendPaymentRequest,
  updateTimeZone,
  uploadInvoicePdf,
  validateToken
};

