import { IResponse } from "constants/interfaces";
import { IAddCallCampScriptRequest } from "pages/admin/call-campaign/campaign-import/component/tabs/contact-import/component/campaign-Script/query/useApiActions";
import { ICallCampEditForm } from "pages/admin/call-campaign/hook/useCallCampEditForm";
import { ICamRequest } from "pages/admin/call-campaign/hook/useCamForm";
import { ICallCampaignResponse } from "pages/admin/call-campaign/query/useFetchCallCampaign";
import { IResponseCallCampaignSetting } from "pages/admin/call-campaign/query/useFetchCallCampaignById";
import { IResponseDialableStatus } from "pages/admin/call-campaign/query/useFetchDialableStatuses";
import { IResponseCampaignCustomers } from "pages/admin/call-campaign/screen/CallCampaignDetails/query/useFetchCampaignDetails";
import { IRequestPermission } from "pages/admin/call-campaign/screen/CustomerDetails/query/useCustomerDetailAPIActions";
import client from "utils/ApiClient";
import API_URLS from "./endPoints";

async function saveCallCampaign(request: ICamRequest): Promise<IResponse> {
  return client.post(`${API_URLS.ADD_CALL_CAMPAIGN}?`, request);
}
async function editCallCampaign(
  request: ICallCampEditForm,
): Promise<IResponse> {
  return client.put(`${API_URLS.EDIT_CALL_CAMPAIGN}?`, request);
}

async function getCallCampaign(
  request: any,
  search = "",
): Promise<ICallCampaignResponse> {
  let url = `${API_URLS.GET_ALL_CALL_CAMPAIGN}?${
    request.roleName === "Client" ? request?.clientId : request?.userId
  }${request?.appointmentSetterId}${request?.page}${request?.pageSize}`;

  if (search) {
    url = `${url}&search=${search}`;
  }

  return client.get(url);
  // return client.get(
  //   `${API_URLS.GET_ALL_CALL_CAMPAIGN}?${
  //     request.roleName === "Client" ? request?.clientId : request?.userId
  //   }${request?.appointmentSetterId}${request?.page}${request?.pageSize}`,
  // );
}
async function fetchCallCampaignById(
  campaignId: string,
): Promise<IResponseCallCampaignSetting> {
  return client.get(`${API_URLS.GET_CAMPAIGN_SETTING_BY_ID}?id=${campaignId}`);
}

async function fetchMaxCall(id: number, to: any): Promise<IResponse> {
  return client.get(`${API_URLS.GET_MAX_CALL}/${id}?toNumber=${to}`);
}

async function getALLContacts(): Promise<IResponse> {
  return client.get(`${API_URLS.FETCH_ALL_CUSTOMERS}/?page=1&pageSize=10&contactId=31&status=0
  `);
}
async function getASByID(): Promise<IResponse> {
  return client.get(`${API_URLS.FETCH_ASSIGN_APPOINMENT_BY_CONTACTID}`);
}
async function getNextNumber(id: any): Promise<IResponse> {
  return client.get(`${API_URLS.GET_NEXT_NUMBER}?campaignId=${id}`);
}
async function removeNumber(id: any): Promise<IResponse> {
  return client.put(`${API_URLS.REMOVE_NUMBER}`, `"${id}"`);
}

async function getTimeSlots(request: any): Promise<IResponse> {
  return client.get(`${API_URLS.GET_TIME_SLOTS}?userId=${request?.userId}`);
}
async function getSaleStatuses(): Promise<IResponse> {
  const URL = `${API_URLS.FETCH_ALL_DISPOSITION}?isSales=true`;
  return client.get(URL);
}

async function getDialableStatuses(): Promise<IResponseDialableStatus> {
  return client.get(`${API_URLS.FETCH_ALL_DISPOSITION}`);
}

async function fetchCustomerDetails(request: any): Promise<IResponse> {
  return client.get(`${API_URLS.GET_CUSTOMER_BY_ID}?customerId=${request}`);
}
async function fetchCustomerPosition(request: {
  camId: number;
  customerId: number;
  statusType: string;
}): Promise<IResponse> {
  let url = `${API_URLS.GET_CUSTOMER_POSITION}?campaignId=${request.camId}&CurrentID=${request.customerId}`;
  if (request.statusType) {
    url = `${url}&type=${request.statusType}`;
  }
  return client.get(url);
}

async function fetchTimeSlots(): Promise<IResponse> {
  return client.get(`${API_URLS.GET_TIMESLOTES}`);
}

async function getCampaignDetails(
  campaignId: string,
  page: number,
  pageSize: number,
  type: string,
  search = "",
): Promise<IResponseCampaignCustomers> {
  // return client.get(
  //   `${API_URLS.GET_CAMPAIGN_DETAILS}?campaignId=${campaignId}&page=${page}&pageSize=${pageSize}`,
  // );
  let url = `${API_URLS.GET_CAMPAIGN_DETAILS}?campaignId=${campaignId}&page=${page}&pageSize=${pageSize}&type=${type}`;
  if (search) {
    url = `${url}&search=${search}`;
  }
  return client.get(url);
}

async function getCustomerCallSchedule(
  customerId: string,
  page: number,
  pageSize: number,
): Promise<IResponse> {
  const URL = `${API_URLS.GET_CALL_CAMPAIGN_SCHEDULE}?customerId=${customerId}&page=${page}&pageSize=${pageSize}`;
  return client.get(URL);
}

async function getCustomerCallScheduleCount(
  customerId: number,
  userId: number,
): Promise<IResponse> {
  const URL = `${API_URLS.GET_CALL_CAMPAIGN_SCHEDULE_COUNT}?customerId=${customerId}&userId=${userId}`;
  return client.get(URL);
}
async function fetchCallerIds(): Promise<IResponse> {
  const URL = `${API_URLS.GET_CALLER_IDS}`;
  return client.get(URL);
}
async function editCustomerDetails(values: any): Promise<IResponse> {
  const URL = `${API_URLS.EIDT_CUSTOMER_DETAILS}`;
  return client.put(URL, values);
}
async function requestPermission(
  values: IRequestPermission,
): Promise<IResponse> {
  const URL = `${API_URLS.REQUEST_PERMISSION}`;
  return client.put(URL, values);
}

async function addCallCampScript(
  values: IAddCallCampScriptRequest,
): Promise<IResponse> {
  const URL = `${API_URLS.ADD_CALL_CAMPAIGN_SCRIPT}`;
  return client.post(URL, values);
}

async function removeCallCampaign(
  // values: any,
  campaignId: number,
): Promise<IResponse> {
  const URL = `${API_URLS.DELETE_CALL_CAMPAIGN}?campaignId=${campaignId}`;

  return client.delete(URL);
}

async function removeCallCampaignImportHistory(
  values: any,
): Promise<IResponse> {
  const URL = `${API_URLS.DELETE_IMPORT_HISTORY}/${values}`;
  return client.delete(URL);
}

async function deleteCallCampScript(values: any): Promise<IResponse> {
  const URL = `${API_URLS.DELETE_CALL_CAMPAIGN_SCRIPT}`;
  return client.delete(URL, values);
}

export {
  addCallCampScript,
  deleteCallCampScript,
  editCallCampaign,
  editCustomerDetails,
  fetchCallCampaignById,
  fetchCallerIds,
  fetchCustomerDetails,
  fetchCustomerPosition,
  fetchMaxCall,
  fetchTimeSlots,
  getALLContacts,
  getASByID,
  getCallCampaign,
  getCampaignDetails,
  getCustomerCallSchedule,
  getCustomerCallScheduleCount,
  getDialableStatuses,
  getNextNumber,
  getSaleStatuses,
  getTimeSlots,
  removeCallCampaign,
  removeCallCampaignImportHistory,
  removeNumber,
  requestPermission,
  saveCallCampaign
};

