import {
  FormControl,
  FormHelperText,
  FormLabel,
  InputAdornment,
  TextField as InputField,
  InputProps,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import * as React from "react";
import palette from "theme/palette";
import { isExistDnc } from "utils";

type IOnSelect = (_: SelectChangeEvent) => void;

export interface IOptions {
  value?: string;
  id?: string;
}

interface Props extends InputProps {
  style?: any;
  label?: string;
  helperText?: string | any;
  icon?: React.ReactElement;
  iconEnd?: boolean;
  isSelect?: boolean;
  onSelectHandler?: IOnSelect;
  selectValue?: string | number;
  menuItems?: any[];
  className?: string;
  hasAllValue?: boolean;
  minDate?: any;
  maxLength?: number;
  isDnc?: boolean;
  variant?: "outlined" | "standard" | "filled";
  dncList?: string[];
  selectStyle?: any;
  onSelectBlur?: () => void;
  inputHeight?: string | number;
}

const CustomField = styled(InputField)({
  // "& input + fieldset": {
  //   border: "none",
  //   borderBottom: "1px solid transparent",
  // },
  // "& input:valid + fieldset": {
  //   borderColor: " green",
  //   borderWidth: 2,
  // },
  // "& input:invalid + fieldset": {
  //   borderColor: "red",
  //   borderWidth: 2,
  // },
  // "& input:valid:focus + fieldset": {
  //   borderLeftWidth: 6,
  //   padding: "4px !important", // override inline-style
  // },
});

function TextField(props: Props) {
  const {
    name,
    type,
    value = "",
    disabled,
    error,
    label,
    onChange,
    onSelectHandler,
    onBlur,
    onFocus,
    placeholder,
    required,
    style = {
      width: "100%",
      marginBottom: "10px",
      display: "flex",
      justifyContent: "flex-end",
    },
    helperText = "",
    icon,
    iconEnd,
    isSelect,
    menuItems,
    className,
    hasAllValue,
    minDate,
    isDnc,
    dncList,
    variant = "outlined",
    selectStyle,
    onSelectBlur,
    inputHeight,
  } = props;
  const inputStyles = {
    // eslint-disable-next-line no-unneeded-ternary
    height: inputHeight ? inputHeight : "40px",
  };
  return (
    <FormControl sx={style}>
      {label && (
        <FormLabel
          htmlFor={name}
          sx={{
            color: "inherit",

            fontSize: {
              xs: "12px",
              md: "14px",
              xl: "1rem",
              lg: "13px",
              fontWeight: "500",
            },
            marginTop: "5px",
            textTransform: "capitalize",
          }}
        >
          {label}{" "}
          {required ? (
            <span style={{ color: palette.error.main }}>*</span>
          ) : null}
        </FormLabel>
      )}

      {!isSelect ? (
        type?.includes("date") ? (
          <CustomField
            aria-describedby="my-helper-text"
            className={className}
            color="success"
            disabled={disabled}
            error={error}
            id={name}
            inputProps={{
              min: minDate,
              // maxLength,
            }}
            name={name}
            placeholder={placeholder}
            required={required}
            sx={{
              ...inputStyles,
              backgroundColor: "#fff",
              borderRadius: "8px",
              fontSize: { xs: "1rem", xl: "1.3rem" },
              width: "100%",
              "& .MuiInputBase-input": {
                height: "40px", // Set the desired height for the input
                padding: "5px 14px",
              },
              "& .MuiInputBase-root": {
                height: inputStyles, // Set the desired height for the whole input field
              },
            }}
            type={type}
            value={value}
            variant={variant}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
          />
        ) : (
          <CustomField
            aria-describedby="my-helper-text"
            className={className}
            color="success"
            disabled={disabled}
            error={error}
            id={name}
            InputProps={{
              startAdornment: iconEnd ? null : (
                <InputAdornment position="start">{icon}</InputAdornment>
              ),
              endAdornment: iconEnd ? (
                <InputAdornment position="end">{icon}</InputAdornment>
              ) : null,

              // min: minDate,
              maxRows: 5,
            }}
            name={name}
            placeholder={placeholder}
            required={required}
            sx={{
              ...inputStyles,
              backgroundColor: "#fff",
              borderRadius: "8px",
              fontSize: { xs: "1rem", xl: "1.3rem" },
              width: "100%",
              "& .MuiInputBase-input": {
                height: "40px", // Set the desired height for the input
                padding: "5px 14px",
              },
              "& .MuiInputBase-root": {
                height: inputStyles, // Set the desired height for the whole input field
              },
            }}
            type={type}
            value={value}
            variant={variant}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
          />
        )
      ) : (
        <Select
          displayEmpty
          className={className}
          disabled={disabled}
          error={error}
          inputProps={{ "aria-label": "Without label" }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: "450px",
                overflowY: "auto",
              },
            },
          }}
          style={{ height: "40px", ...selectStyle }}
          sx={{ backgroundColor: "#fff" }}
          value={value?.toString()}
          onBlur={onSelectBlur}
          onChange={onSelectHandler}
        >
          {menuItems?.map((item) => {
            const values = Object.values(item).join(",");
            return (
              <MenuItem key={item.value} value={hasAllValue ? values : item.id}>
                {item.value}
                {isDnc ? (
                  isExistDnc(dncList || [], item.value) ? (
                    <span
                      style={{
                        color: palette.error.main,
                        marginLeft: "5px",
                      }}
                    >
                      This is set as DNC
                    </span>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </MenuItem>
            );
          })}
        </Select>
      )}

      {helperText && (
        <FormHelperText id="my-helper-text" sx={{ color: palette.error.dark }}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default TextField;
