/**
 * @format
 */
import { FormikHelpers, useFormik } from "formik";
import { IChatRequest } from "../query/useApiAction";

const defaultValues: IChatRequest = {
  selectedChatRoomId: 0,
  toUser: 0,
  fromUser: 0,
  message: "",
  timeStamp: "",
  files: [],
};

const useAddChatForm = (
  onSubmit: (
    values: IChatRequest,
    formikHelpers: FormikHelpers<IChatRequest>,
  ) => void | Promise<unknown>,
  initialValues = defaultValues,
) => {
  return useFormik<IChatRequest>({
    initialValues,
    enableReinitialize: true,
    onSubmit,
  });
};

export default useAddChatForm;
