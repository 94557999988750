import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState, useAppDispatch } from "redux/store";

// interface ICallLogsFilter {
//   filter: string;
//   filterType: string;
//   daysType: string;
//   dispositionStatus: any;
//   user: string;
//   startDate: string;
//   endDate: string;
//   date: string;
// }

interface IinitialState {
  callLogsFilter: {};
  clearCallLogsFilter: null;
}

interface IPayload {
  callLogsFilter: {};
}

interface IAction {
  payload: IPayload;
  type: string;
}

const initialState: IinitialState = {
  callLogsFilter: {
    filter: "",
    filterType: "",
    daysType: "",
    dispositionStatus: "",
    dispositionStatusValue: null,
    user: "",
    userValue: "",
    date: "",
    startDate: null,
    endDate: null,
    page: 0,
    pageSize: 10,
  },
  clearCallLogsFilter: null,
};

const callLogsSlice = createSlice({
  name: "CallLogs",
  initialState,
  reducers: {
    setCallLogFilter: (state, action: IAction) => {
      // eslint-disable-next-line no-param-reassign
      state.callLogsFilter = action.payload.callLogsFilter;
    },
    // clearCallLogsFilter: (state, action) => {
    //   // eslint-disable-next-line no-param-reassign
    //   state.setCallLogsFilter =
    // },
  },
});

export const { setCallLogFilter } = callLogsSlice.actions;

const callLogsState = (state: RootState) => state.callLogs;

export const callLogsFilterData = createSelector(
  [callLogsState],
  (state) => state.callLogsFilter || [],
);

export const useCallLogsActions = () => {
  const dispatch = useAppDispatch();
  return {
    setCallLogFilter: (payload: IPayload) =>
      dispatch(setCallLogFilter(payload)),
  };
};
const { reducer } = callLogsSlice;

export default reducer;
