/**
 * @format
 */
import { useQuery } from "react-query";
import { fetchContactsSearch } from "services/contacts.service";
import { QueryKeys } from "utils/QueryKeys";

export interface Address {
  id: number;
  contactId: number;
  googleAddress: string;
  street: string;
  state: string;
  city: string;
  zipCode: string;
  status: number;
  createdOn: string;
  createdBy: string;
  updatedOn?: any;
  updatedBy?: any;
}

export interface Card {
  id: number;
  contactId: number;
  name: string;
  type: number;
  cCno: number;
  expDate: string;
  security: string;
  address: string;
  dateOFPayment: string;
  status: number;
  createdOn: string;
  createdBy?: any;
  updatedOn?: any;
  updatedBy?: any;
}

export interface ContractDetails {
  id: number;
  contactId: number;
  serviceAgreementMonth: number;
  setupFee: number;
  employeeType: number;
  serviceAgreementDays: number;
  hoursPerDayWork: number;
  virtualEmployees: number;
  rate: number;
  totalHoursPerMonth?: any;
  perAppointmentFee?: any;
  totalHoursPerCycle: number;
  daysPriorTocancel: number;
  status: number;
  createdOn: string;
  updatedOn?: any;
}

export interface ISearchContacts {
  id: number;
  firstName: string;
  lastName: string;
  phone?: string;
  email?: string;
  mobile?: string;
}

export interface IData {
  userId: 0;
  data: ISearchContacts[];
  totalDocs: number;
  limit: number;
  totalPages: number;
  page: number;
  statusCode: number;
}

export interface IContactsResponse {
  userId: 0;
  data: ISearchContacts[];
  statusCode: number;
  totalDocs: 0;
  limit: 10;
  totalPages: 0;
  page: 0;
}

async function getContacts(
  query: string,
  isClient = false,
): Promise<IContactsResponse> {
  try {
    const response: any = await fetchContactsSearch(query, isClient);
    if (response.statusCode === 200) {
      return response;
    }
    return {
      userId: 0,
      data: [],
      totalDocs: 0,
      statusCode: 404,
      limit: 10,
      totalPages: 0,
      page: 0,
    };
  } catch (error) {
    return Promise.reject(error);
  }
}

const useFetchContactsBySearch = (
  query = "",
  isClient = false,
  enabled = true,
) => {
  const cacheKey = [QueryKeys.contactsBySearch];
  return useQuery(
    cacheKey,
    () => {
      return getContacts(query, isClient);
    },
    {
      enabled,
      refetchOnWindowFocus: false,
    },
  );
};

export { useFetchContactsBySearch };

