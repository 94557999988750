/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { ATTACHMENT_URL } from "config";
import { IPermissionAccess } from "pages/auth/login/query/useFetchUserAccess";
import { useAppDispatch } from "redux/store";

export interface IUserState {
  token: string;
  isLoggedIn: boolean;
  companyId: number;
  signalRConnectionId: string;
  getCommunicationAccess: IPermissionAccess;
  profilePic?: string;
  companyName?: string;
}

const initialState: IUserState = {
  token: "",
  isLoggedIn: false,
  companyId: 0,
  signalRConnectionId: "",
  getCommunicationAccess: {} as IPermissionAccess,
  profilePic: "",
  companyName: "",
};

interface IPayload {
  isLoggedIn?: boolean;
  token?: string;
  companyId?: number;
  signalRConnectionId?: string;
  getCommunicationAccess?: IPermissionAccess;
  profilePic?: string;

  companyName?: string;
}

interface IAction {
  payload: IPayload;
  type: string;
}
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserCredential: (state, action: IAction) => {
      state.isLoggedIn = action.payload.isLoggedIn || false;
      state.token = action.payload.token || "";
      state.companyId = action.payload.companyId || 0;
      state.companyName = action.payload.companyName || "";
      state.signalRConnectionId = action.payload.signalRConnectionId || "";
    },
    setSwitchAccount: (state, action: IAction) => {
      state.token = action.payload.token || "";
      state.companyId = action.payload.companyId || 0;
      state.companyName = action.payload.companyName || "";
    },
    setCompanyId: (state, action: IAction) => {
      state.companyId = action.payload.companyId || 0;
      state.companyName = action.payload.companyName || "";
    },
    setProfilePic: (state, action: IAction) => {
      state.profilePic = action.payload.profilePic
        ? `${ATTACHMENT_URL}${action.payload.profilePic}`
        : "";
    },
    setSignalRConnectionId: (state, action: IAction) => {
      state.signalRConnectionId = action.payload.signalRConnectionId || "";
    },
    setCommunicationPermission: (state, action: IAction) => {
      state.getCommunicationAccess =
        action.payload.getCommunicationAccess || ({} as IPermissionAccess);
    },
  },
});

export const {
  setUserCredential,
  setCompanyId,
  setSignalRConnectionId,
  setCommunicationPermission,
  setProfilePic,
  setSwitchAccount,
} = authSlice.actions;

export const useAuthActions = () => {
  const dispatch = useAppDispatch();
  return {
    setUserCredential: (payload: IPayload) =>
      dispatch(setUserCredential(payload)),
    setCompanyId: (payload: IPayload) => dispatch(setCompanyId(payload)),
    setSignalRConnectionId: (payload: IPayload) =>
      dispatch(setSignalRConnectionId(payload)),
    setCommunicationPermission: (payload: IPayload) =>
      dispatch(setCommunicationPermission(payload)),
    setProfilePic: (payload: IPayload) => dispatch(setProfilePic(payload)),
    setSwitchAccount: (payload: IPayload) =>
      dispatch(setSwitchAccount(payload)),
  };
};
const { reducer } = authSlice;
export default reducer;
