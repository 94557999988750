// Import the useDropzone hooks from react-dropzone
import {
  Box,
  CircularProgress,
  FormHelperText,
  Stack,
  Typography,
} from "@mui/material";
import { FILE_URL } from "config";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Accept, FileRejection, useDropzone } from "react-dropzone";
import palette from "theme/palette";

interface IProps {
  handleDrop: (acceptedFiles: File[], fileRejections?: FileRejection[]) => void;
  accept?: Accept;
  fileName?: string;
  error: string;
  styles?: any;
  loading?: boolean;
  fileUrl?: string;
}
function Dropzone(props: IProps) {
  const { handleDrop, accept, fileUrl, fileName, error, styles, loading } =
    props;
  const onDrop = (acceptedFiles: File[], fileRejections?: FileRejection[]) => {
    handleDrop?.(acceptedFiles, fileRejections);
  };
  // Initializing useDropzone hooks with options
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
  });

  /* 
    useDropzone hooks exposes two functions called getRootProps and getInputProps
    and also exposes isDragActive boolean
  */

  return (
    <Stack>
      <Stack
        sx={{
          textAlign: "center",
          padding: "2rem",
          border: "3px purple dashed",
          width: "60%",
          margin: "1rem auto",
          ...styles,
        }}
        {...getRootProps()}
        {...props}
      >
        {loading && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress size={20} />
          </Box>
        )}
        <input className="dropzone-input" value="" {...getInputProps()} />
        <Box className="text-center">
          {isDragActive ? (
            <Typography>Release to drop the files here</Typography>
          ) : fileName ? (
            <Typography>{fileName}</Typography>
          ) : fileUrl ? (
            <img
              alt="drag upload"
              loading="lazy"
              src={`${FILE_URL}/${fileUrl}`}
              style={{ width: "150px", height: "100px" }}
            />
          ) : (
            <Typography>
              Drag and drop some files here, or click to select files
            </Typography>
          )}
        </Box>
      </Stack>
      <FormHelperText
        sx={{
          color: palette.error.main,
          fontSize: "12px",
          textAlign: "center",
        }}
      >
        {error}
      </FormHelperText>
    </Stack>
  );
}

export default Dropzone;
