/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { UserRoleByRoleIdData } from "pages/user/contacts/query/useFetchUserRoleByRoleName";
import { IChat } from "pages/user/inbox/query/useFetchChats";
import { useAppDispatch } from "redux/store";

export type IMarkUnread = {
  messageId: number;
  roomId: number;
};

export interface IChatUsersListReducer {
  selectedChatRoomId?: number | 0;
  // selectedChatRoom?: IChatRoom | null;
  selectedChatRoom?: any;
  markUnread?: IMarkUnread | null;
  pageNumber?: number;

  chatUsers?: UserRoleByRoleIdData[];
  openUnreadChats?: boolean;
  sentMessage?: any;

  toggleChatList?: boolean;
  toggleChatRoom?: boolean;
  toggleChatRoomList?: boolean;
  selectedChatMessage?: IChat | null;
  toUserData?: any;
}

const initialState: IChatUsersListReducer = {
  selectedChatRoomId: 0,
  selectedChatRoom: null,
  markUnread: null,
  pageNumber: 1,
  toggleChatRoomList: false,
  chatUsers: [],
  openUnreadChats: false,
  sentMessage: null,
  toUserData: null,
};

interface IPayload {
  selectedChatRoomId?: number | 0;
  // selectedChatRoom?: IChatRoom | null | undefined;
  selectedChatRoom?: any;
  markUnread?: IMarkUnread | null;
  pageNumber?: number;

  chatUsers?: UserRoleByRoleIdData[];
  openUnreadChats?: boolean;
  sentMessage?: any;

  toggleChatRoom?: boolean;
  toggleChatList?: boolean;
  toggleChatRoomList?: boolean;
  selectedChatMessage?: IChat | null;
  toUserData?: any;
}

interface IAction {
  payload: IPayload;
  type: string;
}

const chatsSlice = createSlice({
  name: "chats",
  initialState,
  reducers: {
    setSelectedChatRoomId: (state, action: IAction) => {
      state.selectedChatRoomId = action.payload.selectedChatRoomId;
    },
    setSelectedChatMessage: (state, action: IAction) => {
      state.selectedChatMessage = action.payload.selectedChatMessage;
    },
    setSentMessage: (state, action: IAction) => {
      state.sentMessage = action.payload.sentMessage;
    },
    setSelectedChatRoom: (state, action: IAction) => {
      state.selectedChatRoom = action.payload.selectedChatRoom;
    },
    setOpenUnreadChats: (state, action: IAction) => {
      state.openUnreadChats = action.payload.openUnreadChats;
    },
    setPageNumber: (state, action: IAction) => {
      state.pageNumber = action.payload.pageNumber;
    },
    setChatUSers: (state, action: IAction) => {
      state.chatUsers = action.payload.chatUsers;
    },
    setToggleChatRoom: (state, action: IAction) => {
      state.toggleChatRoom = action?.payload?.toggleChatRoom;
    },
    setToggleChatRoomList: (state, action: IAction) => {
      state.toggleChatRoomList = action?.payload?.toggleChatRoomList;
    },
    setToggleChatList: (state, action: IAction) => {
      state.toggleChatList = action?.payload?.toggleChatList;
    },
    setToUserData: (state, action: IAction) => {
      state.toUserData = action?.payload?.toUserData;
    },
  },
});

export const {
  setSelectedChatRoomId,
  setSentMessage,
  setSelectedChatRoom,
  setPageNumber,
  setChatUSers,
  setToggleChatRoom,
  setToggleChatList,
  setOpenUnreadChats,
  setSelectedChatMessage,
  setToggleChatRoomList,
  setToUserData,
} = chatsSlice.actions;

export const useChatsActions = () => {
  const dispatch = useAppDispatch();
  return {
    setSentMessage: (payload: IPayload) => dispatch(setSentMessage(payload)),
    setSelectedChatRoomId: (payload: IPayload) =>
      dispatch(setSelectedChatRoomId(payload)),
    setSelectedChatMessage: (payload: IPayload) =>
      dispatch(setSelectedChatMessage(payload)),
    setSelectedChatRoom: (payload: IPayload) =>
      dispatch(setSelectedChatRoom(payload)),
    setOpenUnreadChats: (payload: IPayload) =>
      dispatch(setOpenUnreadChats(payload)),
    setPageNumber: (payload: IPayload) => dispatch(setPageNumber(payload)),
    setChatUSers: (payload: IPayload) => dispatch(setChatUSers(payload)),
    setToggleChatRoom: (payload: IPayload) =>
      dispatch(setToggleChatRoom(payload)),
    setToggleChatRoomList: (payload: IPayload) =>
      dispatch(setToggleChatRoomList(payload)),
    setToggleChatList: (payload: IPayload) =>
      dispatch(setToggleChatList(payload)),
    setToUserData: (payload: IPayload) => dispatch(setToUserData(payload)),
  };
};
const { reducer } = chatsSlice;

export default reducer;
