/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { createSelector, createSlice } from "@reduxjs/toolkit";
import { IPreference } from "pages/user/contacts/query/useFetchPreferences";
import { RootState, useAppDispatch } from "redux/store";

export interface IDashboardReducers {
  preferenceList?: IPreference[];
}

const initialState: IDashboardReducers = {
  preferenceList: [],
};

interface IPayload {
  preferenceList?: IPreference[];
}

interface IAction {
  payload: IPayload;
  type: string;
}

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setPreferenceList: (state, action: IAction) => {
      state.preferenceList = action.payload.preferenceList;
    },
  },
});

export const { setPreferenceList } = dashboardSlice.actions;

const dashboardState = (state: RootState) => state.dashboard;
export const preferenceList = createSelector(
  [dashboardState],
  (state) => state.preferenceList || [],
);

export const useDashboardSliceActions = () => {
  const dispatch = useAppDispatch();
  return {
    setPreferenceList: (payload: IPayload) =>
      dispatch(setPreferenceList(payload)),
  };
};
const { reducer } = dashboardSlice;

export default reducer;
