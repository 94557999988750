const states = {
  CONNECTING: "Connecting",
  READY: "Ready",
  INCOMING: "Incoming",
  ON_CALL: "On call",
  OFFLINE: "Offline",
  MEDIA: "Media",
};

export default states;
