/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { IMenuItems } from "components/multi-value";
import { ILeadGroup } from "pages/admin/settings/screens/lead-group/query/useFetchLeadGroups";
import { useAppDispatch } from "redux/store";

export type MemberData = { id: number; value: string };

export interface ILeadGroupReducer {
  leadGroup: ILeadGroup | undefined;
  selectedMembers: IMenuItems | undefined;
}

const initialState: ILeadGroupReducer = {
  leadGroup: undefined,
  selectedMembers: [],
};

interface IPayload {
  leadGroup?: ILeadGroup;
  selectedMembers?: MemberData[];
}

interface IAction {
  payload: IPayload;
  type: string;
}
const leadGroupSlice = createSlice({
  name: "leadGroup",
  initialState,
  reducers: {
    setLeadGroup: (state, action: IAction) => {
      state.leadGroup = action?.payload?.leadGroup;
    },
    setSelectedMembers: (state, action: IAction) => {
      state.selectedMembers = action?.payload?.selectedMembers;
    },
    removeLeadGroup: (state) => {
      state.leadGroup = undefined;
    },
    removeSelectedMembers: (state) => {
      state.selectedMembers = undefined;
    },
  },
});

export const {
  setLeadGroup,
  removeLeadGroup,
  setSelectedMembers,
  removeSelectedMembers,
} = leadGroupSlice.actions;

export const useLeadGroupActions = () => {
  const dispatch = useAppDispatch();
  return {
    setLeadGroup: (payload: IPayload) => dispatch(setLeadGroup(payload)),
    setSelectedMembers: (payload: IPayload) =>
      dispatch(setSelectedMembers(payload)),
    removeLeadGroup: () => dispatch(removeLeadGroup()),
    removeSelectedMembers: () => dispatch(removeSelectedMembers()),
  };
};
const { reducer } = leadGroupSlice;

export default reducer;
