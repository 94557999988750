import Close from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";

interface IAutocomplete {
  data?: any[];
  placeholder?: string;
  label?: string;
  name?: string;
  value?: string;
  handleSearch: (data: string) => void;
  handleData: (data: any) => void;
  noWidth?: boolean;
  listStyle?: any;
  inputStyle?: any;
  handleClose?: () => void;
  isLoading?: boolean;
  open?: boolean;
}

function Autocomplete(props: IAutocomplete) {
  const {
    handleSearch,
    data,
    handleData,
    placeholder,
    label,
    name,
    value,
    noWidth,
    listStyle,
    inputStyle,
    handleClose,
    isLoading,
    open,
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const dropdownOpen = open !== undefined ? open : isOpen;

  useEffect(() => {
    if (value) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [value]);

  return (
    <Stack
      sx={{
        position: "relative",
      }}
    >
      <FormControl>
        {label && (
          <FormLabel
            htmlFor={name}
            sx={{
              color: "inherit",
              borderRadius: "8px",
              fontSize: { xs: "14px", xl: "1.1rem", fontWeight: "500" },
              marginTop: "5px",
              textTransform: "capitalize",
            }}
          >
            {label}
          </FormLabel>
        )}
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {isLoading ? <CircularProgress size={17} /> : <SearchIcon />}
              </InputAdornment>
            ),
          }}
          placeholder={placeholder}
          sx={{
            backgroundColor: "#fff",
            borderRadius: "8px",
            fontSize: { xs: "1rem", xl: "1.3rem" },
            width: "100%",
            "& .MuiInputBase-input": {
              height: "40px", // Set the desired height for the input
            },
            "& .MuiInputBase-root": {
              height: "40px", // Set the desired height for the whole input field
            },
            ...(inputStyle && inputStyle),
          }}
          type="text"
          value={value}
          onBlur={() => {
            setTimeout(() => setIsOpen(false), 100); // Delay to allow click event on suggestions
          }}
          onChange={(event) => {
            handleSearch(event.target.value);
            setIsOpen(true);
          }}
          onFocus={() => setIsOpen(true)}
        />
      </FormControl>
      {dropdownOpen && data && data.length > 0 && (
        <Box
          sx={{
            position: "absolute",
            top: noWidth ? 90 : 60,
            zIndex: 1111,
            ...(noWidth && { width: "100%" }),
            width: "100%",
            maxHeight: "230px",
            overflowY: "auto",
          }}
        >
          <List
            sx={{
              ...(!noWidth && { width: "100%" }),
              bgcolor: "background.paper",
              border: "1px solid #f4f4f4",
              maxHeight: "230px",
              overflowY: "auto",
              paddingLeft: "1rem",
              borderRadius: 1,
              borderWidth: 1,
              ...(listStyle && listStyle),
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton
                onClick={() => {
                  handleSearch("");
                  handleClose?.();
                }}
              >
                <Close />
              </IconButton>
            </Box>
            {data.map((dataItem) => (
              <ListItem
                key={dataItem.id}
                disableGutters
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  handleData(dataItem);
                  setIsOpen(false);
                }}
                onMouseDown={(e) => e.preventDefault()}
              >
                <Stack width="100%">
                  <ListItemText
                    primary={
                      dataItem?.firstName
                        ? `${dataItem?.firstName || ""} ${dataItem?.lastName || ""}`
                        : dataItem?.fullName
                    }
                  />
                  <Divider />
                </Stack>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Stack>
  );
}

export default Autocomplete;
