/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { createSelector, createSlice } from "@reduxjs/toolkit";
import { IEditContact } from "pages/user/contacts/details/hooks/useEditContactForm";
import { RootState, useAppDispatch } from "redux/store";

export interface ICustomersReducers {
  selectedInputs?: string[];
  selectedContactId?: string;
  selectedContact?: IEditContact;
}

const initialState: ICustomersReducers = {
  selectedInputs: [],
  selectedContact: {} as IEditContact,
  selectedContactId: "",
};

interface IPayload {
  selectedInputs?: string[];
  selectedContact?: IEditContact;
  selectedContactId?: string;
}

interface IAction {
  payload: IPayload;
  type: string;
}

const reconciliationSlice = createSlice({
  name: "reconciliation",
  initialState,
  reducers: {
    setSelectedInput: (state, action: IAction) => {
      state.selectedInputs = action.payload.selectedInputs;
    },

    setSelectedContact: (state, action: IAction) => {
      state.selectedContact = action.payload.selectedContact;
    },
    setSelectedContactId: (state, action: IAction) => {
      state.selectedContactId = action.payload.selectedContactId;
    },

    resetInput: (state) => {
      state.selectedInputs = [];
    },
  },
});

const reconciliationStates = (state: RootState) => state.reconciliation;

export const selectedInputsSelector = createSelector(
  [reconciliationStates],
  (state) => state.selectedInputs || [],
);
export const selectedContactSelector = createSelector(
  (state: RootState) => state.reconciliation.selectedContact,
  (selectedContact) => selectedContact || ({} as IEditContact),
);
export const selectedContactIdSelector = createSelector(
  (state: RootState) => state.reconciliation.selectedContactId,
  (selectedContactId) => selectedContactId || "",
);

export const {
  setSelectedInput,
  resetInput,
  setSelectedContact,
  setSelectedContactId,
} = reconciliationSlice.actions;

export const useReconciliationActions = () => {
  const dispatch = useAppDispatch();
  return {
    setSelectedInput: (payload: IPayload) =>
      dispatch(setSelectedInput(payload)),
    setSelectedContact: (payload: any) => dispatch(setSelectedContact(payload)),
    setSelectedContactId: (payload: any) =>
      dispatch(setSelectedContactId(payload)),

    resetInput: () => dispatch(resetInput()),
  };
};
const { reducer } = reconciliationSlice;

export default reducer;
