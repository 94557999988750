import { UserRoleByRoleIdData } from "pages/user/contacts/query/useFetchUserRoleByRoleName";
import { useState } from "react";

const useChatListingUtils = () => {
  const [openList, setOpenList] = useState(false);

  const filterUserData = (data: string, users: UserRoleByRoleIdData[]) => {
    if (!data || !users) {
      return [];
    }

    const value = data.toLowerCase();
    const filteredContacts = users.filter(
      (i: any) =>
        i?.email?.toLowerCase()?.includes(value) ||
        i?.fullName?.toLowerCase()?.includes(value),
    );

    return filteredContacts;
  };

  const handleOpenContactList = () => {
    setOpenList(true);
  };
  const handleCloseContactList = () => {
    setOpenList(false);
  };

  return {
    handleOpenContactList,
    handleCloseContactList,
    filterUserData,
    openList,
  };
};

export default useChatListingUtils;
