// Avatar.tsx
import GroupsIcon from "@mui/icons-material/Groups";
import { Box, Avatar as MuiAvatar } from "@mui/material";
import { ATTACHMENT_URL } from "config";
import React from "react";
import { getInitials } from "utils/get-initials";

interface IProps {
  isGroup: boolean;
  profileUrl: string;
  fromProfileUrl: string;
  toProfileUrl: string;
  toUserName: string;
  toUser: number;
  decodedId: number;
}

function ChatListAvatar(props: IProps) {
  const {
    isGroup,
    profileUrl,
    fromProfileUrl,
    toProfileUrl,
    toUserName,
    toUser,
    decodedId,
  } = props;

  return (
    <Box sx={{ backgroundColor: "#bdbdbd", borderRadius: "50%" }}>
      {isGroup ? (
        <MuiAvatar
          src={`${ATTACHMENT_URL}${profileUrl}`}
          sx={{ backgroundColor: "#bdbdbd" }}
        >
          <GroupsIcon />
        </MuiAvatar>
      ) : (
        <MuiAvatar
          src={
            Number(decodedId) === toUser
              ? `${ATTACHMENT_URL}${fromProfileUrl}`
              : `${ATTACHMENT_URL}${toProfileUrl}`
          }
          sx={{ backgroundColor: "#bdbdbd" }}
        >
          {getInitials(toUserName)}
        </MuiAvatar>
      )}
    </Box>
  );
}

export default React.memo(ChatListAvatar);
