/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { INewChat } from "pages/user/inbox";
import { createContext } from "react";
import { SignalData } from "simple-peer";
import {
  ICallerIdWithCampaign,
  IRefetch,
  SocketContextProps,
} from "./interface.socket";

export const initialValue = {
  userToCall: "",
  isReceivingCall: false,
  from: "",
  name: "",
  signal: {},
  isVideoCall: false,
  toUser: "",
  toUserId: 0,
};

export const SocketContext = createContext<SocketContextProps>({
  onlineUserList: [],
  setOnlineUserList: (userId: number[]) => {},
  handleOnlineUserList: (userId: number, type: string) => {},
  setCallerIdWithCampaign: (callerId: ICallerIdWithCampaign[]) => {},
  callerIdWithCampaign: [],
  reset: () => {},
  setSelectedCallerId: (callerId: string) => {},
  selectedCallerId: "",
  neverBeenContacted: false,
  setNeverBeenContacted: (data: any) => {},
  sort: null,
  setSort: (data: any) => {},
  refetchBunch: null,
  setRefetchBunch: (data: IRefetch) => {},
  filterUrl: null,
  setFilterUrl: () => {},
  setNewChatMessage: (data: INewChat) => {},
  newChatMessage: null,
  chatRooms: [],
  setChatRooms: () => {},
  call: {
    userToCall: "",
    isReceivingCall: false,
    from: "",
    name: "",
    signal: {},
    isVideoCall: false,
    toUserId: 0,
  },
  openCallScreen: false,
  userCameraOn: false,
  callAccepted: false,
  callRejected: false,
  inComingConfCall: false,
  seconds: 0,
  callPopup: true,
  voiceCallMiniPopup: true,
  myVideo: { current: null },
  userVideo: { current: null },
  stream: undefined,
  name: "",
  whoIsTyping: "",
  setName: () => {},
  fromName: "",
  setFromName: () => {},
  callEnded: false,
  cameraOn: false,
  onConfCall: false,
  setCameraOn: () => {},
  audioOn: false,
  setAudioOn: () => {},
  me: "",
  toUser: "",
  toUserName: "",
  videoConfId: "",
  twilioCallState: null,
  device: null,
  twilioCallConnection: null,
  setTwilioCallConnection: () => {},
  toNumber: "",
  setToNumber: () => {},
  fromNumber: "",
  setFromNumber: () => {},
  incomingData: null,
  setInComingData: () => {},
  callCutByClient: false,
  setCallCutByClient: () => {},
  callerIds: [],
  setCallerIds: () => {},
  endPopUp: false,
  setEndPopUp: () => {},
  twilioRinging: false,
  setTwilioRinging: () => {},
  acceptedCall: null,
  setAcceptedCall: () => {},

  setDevice: () => {},
  callUser: () => {},
  setToUserName: () => {},
  setSeconds: () => {},
  acceptConfCall: () => {},
  leaveCall: () => {},
  makeConfCall: () => {},
  setVideoConfId: () => {},
  rejectCall: () => {},
  rejectConf: () => {},
  answerCall: () => {},
  switchCamera: () => {},
  switchCameraOff: () => {},
  switchAudioOn: () => {},
  switchAudioOff: () => {},
  setCallRejected: () => {},
  setOpenCallScreen: () => {},
  setOnConfCall: () => {},
  setInComingConfCall: () => {},
  setWhoIsTyping: () => {},
  setTwilioCallState: () => {},
  handleOutGoingCallHangup: () => {},
  handleOutGoingCall: () => {},
  handleDTMFNum: (num: string) => {},
  handleInAcceptComingCall: () => {},
  handleLeaveConf: () => {},
  shareScreen: (isShare: boolean) => {},
  screenShareEnabled: false,
  signalRConnection: "",
  setSignalRConnection: () => {},
  userScreenShareOn: false,
  setUserScreenShareOn: () => {},
  sendMessage: () => {},
  whoIsTypingEvent: () => {},
  deleteMessage: () => {},
  selectedToUserData: null,
  setSelectedToUserData: () => {},
  editMessage: (
    id: number,
    message: string,
    roomId: number,
    connectionId: string,
  ) => {},
  chatMessageCount: 0,
  setChatMessageCount: (count: number) => {},
  chatRoomCount: {},
  setChatRoomCounts: (count: any) => {},
});

export interface SocketContextType {
  call: Call | null;
  callAccepted: boolean;
  myVideo: React.RefObject<HTMLVideoElement> | null;
  userVideo: React.RefObject<HTMLVideoElement> | null;
  stream: MediaStream | undefined;
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>> | null;
  callEnded: boolean;
  me: string;
  callUser: (id: string) => void;
  leaveCall: () => void;
  answerCall: () => void;
}

// Define the type for the call state
export interface Call {
  isReceivedCall?: boolean;
  from?: string;
  name?: string;
  signal?: SignalData;
}

export const ChatContext = createContext<SocketContextType | null>({
  call: null,
  callAccepted: false,
  myVideo: null,
  userVideo: null,
  stream: undefined,
  name: "",
  setName: () => {},
  callEnded: false,
  me: "",
  callUser: (id: string) => {},
  leaveCall: () => {},
  answerCall: () => {},
});
