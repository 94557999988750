/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, useAppDispatch } from "redux/store";

export type IASIds = number[];

export interface IASReducers {
  ASIds?: IASIds;
  AS: any;
}

const initialState: IASReducers = {
  ASIds: [],
  AS: {},
};

interface IPayload {
  AS?: any;
  selectedAS?: any;
  ASIds: IASIds;
}

interface IAction {
  payload: any;
  type: string;
}

const assignASSlice = createSlice({
  name: "assignAS",
  initialState,
  reducers: {
    setAS: (state, action: IAction) => {
      state.AS = action.payload.AS;
    },
    setASId: (state, action: PayloadAction<{ ASId: number }>) => {
      const { ASId } = action.payload;
      const findAS = state.ASIds?.find((i) => i === ASId);
      if (findAS) {
        state.ASIds = state.ASIds?.filter((i) => i !== ASId) || [];
      } else {
        state.ASIds?.push(ASId);
      }
    },
    setAllASIds: (
      state,
      action: PayloadAction<{ checked: boolean; ASIds: number[] }>,
    ) => {
      const { checked, ASIds } = action.payload;
      if (checked) {
        state.ASIds = ASIds;
      } else {
        state.ASIds = [];
      }
    },

    resetASIds: (state) => {
      state.ASIds = [];
    },
  },
});

const ASStates = (state: RootState) => state.assignAS;

export const selectASIds = createSelector(
  [ASStates],
  (state) => state.ASIds || [],
);

export const { setAS, setASId, resetASIds, setAllASIds } =
  assignASSlice.actions;

export const useAssignASActions = () => {
  const dispatch = useAppDispatch();
  return {
    setAS: (payload: IPayload) => dispatch(setAS(payload)),

    resetASIds: () => dispatch(resetASIds()),
  };
};
const { reducer } = assignASSlice;

export default reducer;
