import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { IconButton } from "@mui/material";
import palette from "theme/palette";

interface IProps {
  handleBottomButton: () => void;
  style?: any;
}

function ScrollBottomButton(props: IProps) {
  const { handleBottomButton, style } = props;
  return (
    <IconButton
      aria-label="scroll to bottom button"
      sx={{
        position: "fixed",
        bottom: "160px",
        // marginBottom: "1.5rem",
        right: "50px",
        zIndex: 6,
        background: palette.common.white,
        boxShadow: "2px 1px 7px #0000002e",
        ...style,
      }}
      onClick={handleBottomButton}
    >
      <KeyboardDoubleArrowDownIcon />
    </IconButton>
  );
}

export default ScrollBottomButton;
