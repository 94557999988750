import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as React from "react";

export interface ITabsPanel {
  tabs?: ITab[];
  setValue: (data: any) => void;
  value: number;
  variant?: "scrollable" | "standard" | "fullWidth" | undefined;
}

export interface ITab {
  component?: React.ReactNode;
  label?: string;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabsPanel(props: ITabsPanel) {
  const { tabs, setValue, value, variant = "scrollable" } = props;

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          aria-label="basic tabs example"
          scrollButtons="auto"
          sx={{
            scrollBehavior: "smooth",
          }}
          value={value}
          variant={variant}
          onChange={handleChange}
        >
          {tabs?.map((tab: ITab, index: number) => {
            return <Tab label={tab?.label} {...a11yProps(index)} />;
          })}
        </Tabs>
      </Box>
      {/* {tabs?.map((tab: ITab, index: number) => {
        return (
          <TabPanel index={index} value={value}>
            {tab?.component}
          </TabPanel>
        );
      })} */}
    </Box>
  );
}

export default React.memo(TabsPanel);
