import CloseIcon from "@mui/icons-material/Close";
import { Box, Card, CardContent, IconButton, Stack } from "@mui/material";
import {
  UserRoleByRoleIdData,
  useFetchUserRoleByRoleName,
} from "pages/user/contacts/query/useFetchUserRoleByRoleName";
import ChatContactList from "pages/user/inbox/component/chat-contact-list";
import useChatListingUtils from "pages/user/inbox/component/chat-listing/hooks/useChatListingUtils";
import CreateGroupPop from "pages/user/inbox/component/create-group/CreateGroupPop";
import { useApiActions } from "pages/user/inbox/query/useApiAction";
import { IChatRoom } from "pages/user/inbox/query/useFetchChatRoom";
import React, { useEffect, useState } from "react";

import SearchInput from "components/seach-input";
import AppRoutes from "navigation/appRoutes";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useChatsActions } from "redux/chats/chats";
import { RootState } from "redux/store";
import palette from "theme/palette";
import { addChatRoomIdToUrl } from "utils";
import ChatRoomList from "../chat-room-list";
import ChatRoomHeader from "./components/chat-room-header";

interface IProps {
  handleSearchChatRoom: (value: string) => void;
  isChatRoomLoading: boolean;
  data: IChatRoom[];
  setToggle: any;
  unreadCounts: number;
  fetchNextRoom: any;
  hasNextRoom: any;

  isFetchingNextRoom: boolean;
  isFetchingPreviousRoom: boolean;
  isRefetchingRoom: boolean;
  newChat?: any;
  setNewChat?: (data: any) => void;
  chatRooms: IChatRoom[];

  handleChat?: (event: any, roomId: number, room: IChatRoom) => void;
  handleCloseSearch: any;
  searchValue: any;
  searching: boolean;
}

function ChatRoomBox(props: IProps) {
  const {
    data,
    isChatRoomLoading,
    handleSearchChatRoom,
    isFetchingPreviousRoom,
    setToggle,
    unreadCounts,
    fetchNextRoom,
    hasNextRoom,
    isRefetchingRoom,
    isFetchingNextRoom,
    newChat,
    setNewChat,
    chatRooms,
    handleChat,
    handleCloseSearch,
    searchValue,
    searching,
  } = props;
  const chats = useSelector((state: RootState) => state.chats);
  const { toggleChatRoomList } = chats;
  const navigate = useNavigate();

  const [userData, setUserData] = useState<UserRoleByRoleIdData[]>([]);

  const [createGroup, setCreateGroup] = useState(false);

  const [selectedGroup, setSelectedGroup] = useState<any>(null);
  const [search, setSearch] = useState("");

  const { data: team, refetch: refetchUser } = useFetchUserRoleByRoleName(
    "all",
    false,
  );

  const { setSelectedChatRoomId, setSelectedChatRoom } = useChatsActions();
  const {
    handleOpenContactList,
    handleCloseContactList,
    filterUserData,
    openList,
  } = useChatListingUtils();

  useEffect(() => {
    if (openList && !team?.data) {
      refetchUser();
    }
  }, [team?.data, openList]);

  useEffect(() => {
    if (team?.data) {
      const tempUsers = JSON.parse(JSON.stringify(team?.data));
      setUserData(tempUsers);
    }
  }, [openList, team]);

  const handleToggle = () => {
    setToggle({ toggleChatRoomList: !toggleChatRoomList });
  };

  const { getRoomId } = useApiActions();

  const handleSearch = (data: string) => {
    setSearch(data);
    const tempUsers = JSON.parse(JSON.stringify(team?.data));
    const filteredData = filterUserData(data, tempUsers);
    return data ? setUserData(filteredData) : setUserData(tempUsers);
  };

  const onUserClose = () => {
    handleCloseContactList();
    handleSearch("");
  };

  const handleUserClick = async (data: any) => {
    const responseRoom = await getRoomId(data?.id);
    const placeholder = {} as IChatRoom;
    const room = {
      ...placeholder,
      id: responseRoom?.data?.id,
      lastMessageOn: "",
      message: "",
      toUser: data?.id,
      toUserName: data?.fullName,
    };
    addChatRoomIdToUrl({
      chatroomid: responseRoom?.data?.id,
      expand: 1,
    });
    setSelectedChatRoomId({ selectedChatRoomId: responseRoom?.data?.id });
    setSelectedChatRoom({ selectedChatRoom: room });

    // setData([]);
    onUserClose();
  };

  const handleOpenCreateGroup = () => {
    setCreateGroup(true);
  };

  const handleCloseCreateGroup = () => {
    setCreateGroup(false);
    setSelectedGroup(null);
  };

  const handleContactClick = (id: number) => {
    navigate(`${AppRoutes.CONTACTS_DETAILS}/${id}`);
  };
  return (
    <Box
      sx={{
        position: "fixed",
        right: "30px",
        bottom: 0,
        zIndex: 999,
      }}
    >
      <Stack alignItems="center" direction="row" gap={1}>
        {/* chat room details */}

        {/* chat room list */}

        <Card variant="outlined">
          <CardContent
            sx={{
              p: 1,
              height: toggleChatRoomList ? "70vh" : "50px",
              width: "315px",
            }}
          >
            <ChatRoomHeader
              handleOpenContactList={handleOpenContactList}
              handleOpenCreateGroup={handleOpenCreateGroup}
              handleToggle={handleToggle}
              toggle={Boolean(toggleChatRoomList)}
              unreadCounts={unreadCounts}
            />
            <Stack
              alignItems="center"
              direction="row"
              gap="4px"
              justifyContent="center"
              sx={{ display: "flex", padding: "0 8px" }}
            >
              <SearchInput
                disabled={isChatRoomLoading}
                placeholder="Search chats..."
                typeSearch="text"
                value={searchValue}
                onSearch={handleSearchChatRoom}
              />
              <IconButton sx={{ p: 0, mt: "21px" }} onClick={handleCloseSearch}>
                <CloseIcon sx={{ fontSize: 17, color: palette.primary.main }} />
              </IconButton>
            </Stack>
            <ChatRoomList
              chatRoomList={data || []}
              data={chatRooms || []}
              fetchNextRoom={fetchNextRoom}
              handleChat={handleChat}
              handleContactClick={handleContactClick}
              hasNextRoom={hasNextRoom}
              isChatRoomLoading={isChatRoomLoading || searching}
              isFetchingNextRoom={isFetchingNextRoom}
              isFetchingPreviousRoom={isFetchingPreviousRoom}
              isRefetchingRoom={isRefetchingRoom}
              newChat={newChat}
              setNewChat={setNewChat}
            />

            <Stack sx={{ paddingTop: "1rem" }}>
              <ChatContactList
                data={userData}
                handleCloseContactList={onUserClose}
                handleSearch={handleSearch}
                handleUserClick={handleUserClick}
                openList={openList}
                search={search}
              />
            </Stack>
            <CreateGroupPop
              handleGroupClose={handleCloseCreateGroup}
              openGroup={createGroup}
              selectedGroup={selectedGroup}
              // selectedRoom={selectedChatRoom}
              // setSelectedRoom={setSelectedChatRoom}
              userData={userData}
            />
          </CardContent>
        </Card>
      </Stack>
    </Box>
  );
}

export default React.memo(ChatRoomBox);
