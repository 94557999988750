import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    Stack,
    TextareaAutosize,
    Typography,
} from "@mui/material";
import { useAlert } from "components/alert";
import { ControlPermissionGuard } from "components/permission-guard";
import { PermissionConfig } from "components/permission-guard/permission.config";
import TextField from "components/textfield";
import VoiceRecognition from "components/voice-recognition";
import { FIELDS } from "constants/constants";
import useDecodedData from "hooks/useDecodedData";
import useForm from "pages/admin/settings/screens/templates/hooks/useForm";
import React, { useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { QueryKeys } from "utils/QueryKeys";
import {
    ITemplatesRequest,
    useApiActions,
} from "../../../../query/useApiAction";
import { IResponseTemplates } from "../../../../query/useFetchTemplates";
import TemplateList from "../../../template-list";

interface IProps {
    type: string;
}

const useStyles = makeStyles({
    textArea: {
        fontSize: "1rem",
        border: "1px solid #c4c4c4",
        borderRadius: "4px",
        position: "relative",
        width: "-webkit-fill-available",
        padding: "8px",
        paddingRight: "2rem",
        margin: "8px 0",
        outline: "none !important",
        "&::placeholder": {
            fontSize: "1rem",
            opacity: "0.8",
        },
    },
});

const initialValues: ITemplatesRequest = {
    type: "",
    title: "",
    subject: "",
    templateText: "",

    client: 0,
    customField: "",
    templateUsers: [0],
};

function ActivitySmsUser(props: IProps) {
    const { type } = props;
    const classes = useStyles();
    const [activeTemplate, setActiveTemplate] = useState();
    const [selectedField, setSelectedField] = useState("");
    const { deleteTemplateSingleId, trySave } = useApiActions();
    const alert = useAlert();
    const ACTIVITY_FIELDS = [
        ...FIELDS,
        { value: "Date and time", id: "%dateTime%" },
        { value: "activityType", id: "%activityType%" },
        { value: "User Name", id: "%userName%" },
        { value: "Timezone", id: "%timezone%" },
    ];
    const accessPermissionsSlice = useSelector(
        (state: RootState) => state.accessPermissionsSlice,
    );
    const queryClient = useQueryClient();

    const chatList = queryClient.getQueryData<IResponseTemplates>([
        QueryKeys.templates,
    ]);
    const decoded = useDecodedData();
    const handleDelete = async (id: number) => {
        await deleteTemplateSingleId(id);
    };

    const deleteTemplateById = (id: number) => {
        alert?.show({
            title: "Confirmation",
            message: "Do you really want to delete",
            cancelText: "No",
            confirmText: "Yes",
            onConfirm: () => handleDelete(id),
        });
    };

    const onSubmit = async (values: ITemplatesRequest) => {
        await trySave({ ...values, type: type.toLowerCase() });

        formik.resetForm();
    };

    const formik = useForm(onSubmit, initialValues);

    const {
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        values,
        errors,
        touched,

        isSubmitting,
    } = formik;

    const handleSelect = (data: any) => {
        setFieldValue("id", data?.id);
        setFieldValue("subject", data?.subject);
        setFieldValue("templateText", data?.templateText);
        setActiveTemplate(data?.id);
    };

    const fetchedSms = useMemo(() => {
        if (!chatList?.data) return [];
        return chatList?.data?.filter(
            (x) => x.type.toLowerCase() === type.toLowerCase(),
        );
    }, [chatList?.data]);

    return (
        <Grid container>
            <Grid item lg={4} sx={{ borderRight: "1px solid #f4f4f4", p: 2 }} xs={12}>
                <Typography
                    sx={{
                        mb: 1,
                        pb: 1,
                        textAlign: "center",
                        borderBottom: "1px solid #c4c4c4",
                    }}
                    variant="h6"
                >
                    SMS Templates
                </Typography>
                {/* <List sx={{ backgroundColor: "#fdfdfd", borderRadius: "6px" }}>
                    {fetchedSms?.map((x: any) => {
                        return (
                            <ListItem
                                sx={{
                                    backgroundColor:
                                        x?.id === active ? palette.info.lightBg2 : "unset",
                                }}
                            >
                                <ListItemButton
                                    sx={{
                                        borderBottom: "1px solid #f4f4f4",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => handleSelect(x)}
                                >
                                    {x?.subject}
                                </ListItemButton>

                                <Box onClick={() => deleteTemplateId(x?.id)}>
                                    <DeleteIcon
                                        style={{
                                            color: "red",
                                            marginTop: "5px",
                                            cursor: "pointer",
                                        }}
                                    />
                                </Box>
                            </ListItem>
                        );
                    })}
                </List> */}
                <TemplateList
                    activeTemplate={activeTemplate}
                    templates={fetchedSms}
                    onDelete={deleteTemplateById}
                    onTemplateSelect={handleSelect}
                />
            </Grid>
            <Grid item lg={8} xs={12}>
                <form>
                    <Stack sx={{ p: 2, maxWidth: "500px" }}>
                        <Box sx={{ mt: 2 }}>
                            <TextField
                                error={!!touched.subject && !!errors.subject}
                                helperText={(touched.subject && errors && errors.subject) || ""}
                                label="Title"
                                name="title"
                                placeholder="Title"
                                style={{ width: "500px" }}
                                value={values.subject}
                                onBlur={handleBlur("subject")}
                                onChange={handleChange("subject")}
                            />
                        </Box>
                        <Stack alignItems="center" direction="row" spacing={2}>
                            <TextField
                                isSelect
                                label="Fields"
                                menuItems={ACTIVITY_FIELDS}
                                name="field"
                                style={{ width: "350px" }}
                                value={selectedField}
                                onSelectHandler={(event) => {
                                    if (event.target.value) {
                                        setSelectedField(event.target.value);
                                    }
                                }}
                            />

                            <Box
                                sx={{
                                    width: "250px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                }}
                            >
                                <Button
                                    fullWidth
                                    variant="text"
                                    onClick={() => {
                                        navigator.clipboard.writeText(selectedField);
                                        setSelectedField("");
                                    }}
                                >
                                    Copy Token
                                </Button>
                            </Box>
                        </Stack>
                        <FormControl
                            error={!!touched.templateText && !!errors.templateText}
                        >
                            <Stack
                                alignItems="center"
                                display="flex"
                                flexDirection="row"
                                justifyContent="center"
                            >
                                <TextareaAutosize
                                    className={`${classes.textArea}`}
                                    minRows={10}
                                    name="message"
                                    placeholder="Type your message..."
                                    style={{
                                        fontSize: "1rem",
                                    }}
                                    value={values.templateText}
                                    onBlur={handleBlur("templateText")}
                                    onChange={handleChange("templateText")}
                                />
                                <VoiceRecognition fieldName="templateText" formik={formik} />
                            </Stack>
                            <FormHelperText>
                                {(touched.templateText &&
                                    errors &&
                                    errors.templateText?.replace("subject", "title")) ||
                                    ""}
                            </FormHelperText>
                        </FormControl>
                        <ControlPermissionGuard
                            controlCode={PermissionConfig.Templates.controlCode.SMSSave}
                            permissions={accessPermissionsSlice?.permissions}
                            roleName={decoded?.RoleName}
                            screenCode={PermissionConfig.Templates.screenCode}
                            screenURL={PermissionConfig.Templates.screenURL}
                        >
                            <Button
                                disabled={isSubmitting}
                                startIcon={<AddCircleIcon />}
                                sx={{ padding: "0.5rem 1rem", width: "200px" }}
                                variant="contained"
                                onClick={() => handleSubmit()}
                            >
                                {isSubmitting ? (
                                    <CircularProgress color="info" size={12} />
                                ) : (
                                    "Submit"
                                )}
                            </Button>
                        </ControlPermissionGuard>
                    </Stack>
                </form>
            </Grid>
        </Grid>
    );
}

export default React.memo(ActivitySmsUser);
