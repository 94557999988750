/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState, useAppDispatch } from "redux/store";

export interface IDncReducer {
  dncList?: string[];
}

const initialState: IDncReducer = {
  dncList: [],
};

interface IPayload {
  dncList: string[];
}

interface IAction {
  payload: IPayload;
  type: string;
}

const dncSlice = createSlice({
  name: "dnc",
  initialState,
  reducers: {
    setDncList: (state, action: IAction) => {
      state.dncList = action.payload.dncList;
    },
  },
});

const dncState = (state: RootState) => state.dnc;

export const getDncListData = createSelector(
  [dncState],
  (state) => state.dncList || [],
);

export const { setDncList } = dncSlice.actions;

export const useDncActions = () => {
  const dispatch = useAppDispatch();
  return {
    setDncList: (payload: IPayload) => dispatch(setDncList(payload)),
  };
};
const { reducer } = dncSlice;

export default reducer;
