import { Box, Button, Modal } from "@mui/material";
import InputEmoji from "react-input-emoji";

import {
  IChatMessage,
  useApiActions,
} from "pages/user/inbox/query/useApiAction";

import { ATTACHMENT_URL } from "config";
import { USER_ICON } from "constants/constants";
import useDecodedData from "hooks/useDecodedData";
import { IResponseGroupMembers } from "pages/user/inbox/query/useFetchMembers";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MentionUser } from "react-input-emoji/dist/src/types/types";
import { useQueryClient } from "react-query";
import { SocketContext } from "SocketContex";
import { QueryKeys } from "utils/QueryKeys";

interface IProps {
  editMsgOpen: boolean;
  handleEditMsgClose: () => void;
  chatId: number;
  editMsg: IChatMessage | null;
  roomId: number;
  connectionId: string;
  groupMembers: any;
  room: any;
}

function MessageEditPopup(props: IProps) {
  const {
    editMsgOpen,
    handleEditMsgClose,
    chatId,
    editMsg,
    roomId,
    connectionId,
    room,
  } = props;
  const chatBoxRef = useRef<HTMLInputElement>(null);
  // const [mentionUsers, setMentionUsers] = useState<MentionUser[]>([]);
  const queryClient = useQueryClient();
  const { editMessage } = useContext(SocketContext);
  const decoded = useDecodedData();

  const groupMemberData = queryClient.getQueryData<IResponseGroupMembers>([
    QueryKeys.groupMembers,
    roomId,
  ]);
  const memberIds = useMemo(
    () =>
      groupMemberData?.data
        ?.map((item) => item.memberId)
        ?.filter((x) => x !== Number(decoded?.id)),
    [groupMemberData],
  );
  const [newEditMsg, setNewEditMsg] = useState<{
    chatId: number;
    chatMessage: string;
  }>({
    chatId,
    chatMessage: editMsg?.message || "",
  });

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef?.current?.focus();
    }
  }, [chatBoxRef]);

  const mentionUsers: MentionUser[] = useMemo(() => {
    if (!groupMemberData?.data) return [];
    const temp = groupMemberData?.data?.map((item) => {
      return {
        id: String(item?.id),
        name: item?.memberName,
        image: `${ATTACHMENT_URL}${item?.userProfileUrl}` || USER_ICON,
      };
    });
    return temp;
  }, [groupMemberData?.data]);

  // useEffect(() => {
  //   if (chatUsers) {
  //     const data: MentionUser[] = chatUsers?.map((item) => {
  //       return {
  //         id: String(item?.id),
  //         name: item?.fullName,
  //         image: item?.profileImage || item?.userProfileUrl || USER_ICON,
  //       };
  //     });
  //     if (data) {
  //       setMentionUsers(data);
  //     }
  //   }
  // }, [chatUsers]);

  const handleMessage = (e: string) => {
    setNewEditMsg({
      chatId: newEditMsg?.chatId,
      chatMessage: e,
    });
  };

  const { editChatMessage } = useApiActions();

  const handleEditMessage = async () => {
    const body: IChatMessage = {
      id: newEditMsg?.chatId,
      message: newEditMsg?.chatMessage,
      roomId,
    };
    await editChatMessage(body);
    editMessage(
      newEditMsg?.chatId,
      newEditMsg?.chatMessage,
      roomId,
      room.isGroup ? memberIds : connectionId,
    );
    handleEditMsgClose();
  };

  // mention will only work with group messages
  const handleMention = useCallback(
    async (text: string) => {
      const name = text.replace(/@/g, "").toLowerCase();
      // Perform asynchronous operation, e.g., API call
      const userList: MentionUser[] = JSON.parse(JSON.stringify(mentionUsers));
      const filterList = userList.filter((item) => {
        const nameLower = item.name.toLowerCase();
        return nameLower.includes(name);
      });
      try {
        return filterList || userList; // Assuming result is of type MentionUser[]
      } catch (error) {
        console.error("Error during mention search:", error);
        return userList; // Return an empty array or handle the error appropriately
      }
    },
    [mentionUsers],
  );

  const handleOnEnter = async () => {
    if (newEditMsg) {
      await handleEditMessage();
    }
  };
  return (
    <Box>
      <Modal
        aria-describedby="edit-message-description"
        aria-labelledby="edit-message-title"
        open={editMsgOpen}
        onClose={handleEditMsgClose}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "60%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: "8px",
          }}
        >
          <Box
            sx={{
              justifyContent: "center",
              alignSelf: "center",
            }}
          >
            <InputEmoji
              // cleanOnEnter
              ref={chatBoxRef}
              shouldReturn
              borderRadius={8}
              fontSize={16}
              inputClass="chat-input"
              placeholder="Type a message..."
              searchMention={handleMention}
              value={newEditMsg?.chatMessage}
              onChange={(value: string) => {
                handleMessage(value);
              }}
              onEnter={handleOnEnter}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
            }}
          >
            <Button
              sx={{ margin: "5px" }}
              variant="contained"
              onClick={handleEditMessage}
            >
              Edit
            </Button>
            <Button
              color="error"
              sx={{ margin: "5px" }}
              variant="contained"
              onClick={handleEditMsgClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default MessageEditPopup;
