/* eslint-disable object-shorthand */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react-hooks/exhaustive-deps */
import { SocketContext } from "SocketContex";
import { useContext } from "react";
import { useSelector } from "react-redux";
import CallingPopup from "./components/CallingPopup";
import DialerManager from "./components/DialerManager";
import IncomingCallSnackbar from "./components/IncomingCallSnackbar";
import OutGoingCallRinging from "./components/OutGoingCallRinging";
import useCallManager from "./hook/useCallManager";
import states from "./states";

export type CallType =
  | "hold"
  | "mute"
  | "calling"
  | "unmute"
  | "unhold"
  | "end";

function CallManager() {
  const contactsStore = useSelector((state: any) => state.contacts);
  const { contact, type } = contactsStore;
  const customerId = type === "campaign" ? contact?.id : 0;
  const callCampaignID =
    type === "campaign" ? window.location.href.split("/")[4] : 0;
  const callManagerData = useCallManager();
  const { dialer } = callManagerData;
  const {
    toNumber,
    fromNumber,
    incomingData,
    callCutByClient,
    setCallCutByClient,
    endPopUp,
    handleOutGoingCallHangup,
    handleOutGoingCall,
    setTwilioCallState,
    twilioCallState,
    handleDTMFNum,
    handleInAcceptComingCall,
    handleLeaveConf,
  } = useContext(SocketContext);
  switch (twilioCallState) {
    case states.MEDIA:
      return <OutGoingCallRinging handleHangup={handleOutGoingCallHangup} />;
    case states.INCOMING:
      return (
        <IncomingCallSnackbar
          from="CallManager"
          handleAcceptCall={() => {
            handleInAcceptComingCall();
          }}
          handleEndCall={handleOutGoingCallHangup}
          incomingData={incomingData}
          number={fromNumber}
          open={states.INCOMING === twilioCallState}
          type="in"
        />
      );
    case states.ON_CALL:
      return (
        <CallingPopup
          callCampaignID={callCampaignID}
          callCutByClient={callCutByClient}
          customerId={customerId}
          endPopUp={endPopUp}
          fromNumber={fromNumber}
          handleDTMFNum={handleDTMFNum}
          handleEndCall={handleOutGoingCallHangup}
          handleLeaveConf={handleLeaveConf}
          incomingData={incomingData}
          setCallCutByClient={setCallCutByClient}
          setState={setTwilioCallState}
          toNumber={toNumber}
        />
      );
    default:
      return (
        <DialerManager handleOutGoingCall={handleOutGoingCall} open={dialer} />
      );
  }
}

export default CallManager;
