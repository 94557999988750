import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Stack,
  SvgIcon,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { UserRoleByRoleIdData } from "pages/user/contacts/query/useFetchUserRoleByRoleName";

import React, { useRef } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import palette from "theme/palette";

interface IProps {
  openList: boolean;
  handleCloseContactList: () => void;
  handleUserClick: (data: any) => void;
  handleSearch: (data: string) => void;
  data: UserRoleByRoleIdData[];
  search: string;
}

function ChatContactList(props: IProps) {
  const {
    openList,
    handleCloseContactList,
    handleUserClick,
    handleSearch,
    data,
    search,
  } = props;
  const isSmall = useMediaQuery("(max-width:767px)");
  const chatSearchRef = useRef<HTMLInputElement | null>(null);

  // useEffect(() => {
  //   if (chatSearchRef.current) {
  //     chatSearchRef.current.focus;
  //   }
  // }, [openList]);

  return (
    <Stack
      sx={{
        display: openList ? "block" : "none",
        position: "absolute",
        backgroundColor: "#fff",
        top: isSmall ? "70px" : "0",
        left: isSmall ? "100px" : "0",
        zIndex: 111,
        // width: openList ? "330px" : "100%",
        height: "400px",
        paddingRight: "8px",
        paddingLeft: "7px",
        // marginTop: openList ? "1rem" : "unset",
        // overflow: openList ? "hidden" : "unset",
        overflow: "hidden",
        boxShadow: "1px 0px 19px 4px #0000002b",
        borderRadius: "6px",
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        gap={1}
        justifyContent="space-between"
      >
        <Typography
          sx={{ fontSize: "12px", color: palette.text.muted }}
          variant="h6"
        >
          Contact list
        </Typography>
        <IconButton sx={{ width: "60px" }} onClick={handleCloseContactList}>
          <CloseIcon />
        </IconButton>
      </Stack>
      <Box sx={{ maxWidth: "100%" }}>
        <TextField
          ref={chatSearchRef}
          InputProps={{
            // sx: { fontSize: "0.8rem", ...inputStyle },
            endAdornment: (
              <InputAdornment position="end">
                {search?.length === 0 ? (
                  <SvgIcon color="action" fontSize="small">
                    <SearchIcon />
                  </SvgIcon>
                ) : (
                  <IconButton
                    aria-label="close"
                    sx={{
                      color: "red",
                    }}
                    onClick={() => {
                      handleSearch("");
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
          placeholder="Search User..."
          sx={{
            backgroundColor: "#fff",
            borderRadius: "8px",
            fontSize: { xs: "1rem", xl: "1.3rem" },
            width: "100%",
            "& .MuiInputBase-input": {
              height: "40px", // Set the desired height for the input
            },
            "& .MuiInputBase-root": {
              height: "40px", // Set the desired height for the whole input field
            },
          }}
          type="text"
          value={search}
          onChange={(event) => handleSearch(event.target.value)}
        />
      </Box>
      <Stack>
        <PerfectScrollbar>
          <Stack sx={{ height: "370px", paddingTop: "1rem" }}>
            <List
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                border: "1px solid #f4f4f4",
                paddingLeft: "1rem",
                borderRadius: 1,
                borderWidth: 1,
              }}
            >
              {data?.map((data) => {
                return (
                  <ListItem
                    key={data.id}
                    disableGutters
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleUserClick?.(data);
                    }}
                  >
                    <Stack width="100%">
                      <ListItemText
                        primary={`${
                          data?.firstName
                            ? `${data?.firstName} ${data?.lastName}`
                            : data?.fullName
                        } (${data?.roleName})`}
                        sx={{
                          fontSize: "0.8rem",
                          lineHeight: 1,
                          color: "black",
                        }}
                      />
                      <Divider />
                    </Stack>
                  </ListItem>
                );
              })}
            </List>
          </Stack>
        </PerfectScrollbar>
      </Stack>
    </Stack>
  );
}

export default React.memo(ChatContactList);
