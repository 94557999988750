// ChatRoomHeader.tsx
import AddCommentIcon from "@mui/icons-material/AddComment";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Badge,
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { ControlPermissionGuard } from "components/permission-guard";
import UserControlPermissionGuard from "components/permission-guard/UserControlPermissionGuard";
import {
  PermissionConfig,
  UserPermissionConfig,
} from "components/permission-guard/permission.config";
import useDecodedData from "hooks/useDecodedData";
import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

interface IChatHeaderProps {
  unreadCounts: number;
  toggle: boolean;
  handleToggle: () => void;
  handleOpenContactList: () => void;
  handleOpenCreateGroup: () => void;
}

function ChatRoomHeader(props: IChatHeaderProps) {
  const {
    unreadCounts,
    toggle,
    handleToggle,
    handleOpenContactList,
    handleOpenCreateGroup,
  } = props;
  const accessPermissionsSlice = useSelector(
    (state: RootState) => state.accessPermissionsSlice,
  );
  const decoded = useDecodedData();
  return (
    <Stack
      alignItems="center"
      borderBottom="1px solid #c1c1c1"
      direction="row"
      gap={1}
      justifyContent="space-between"
      sx={{
        color: "#fff",
        backgroundColor: "#5048e5",
        padding: "8px",
        margin: "-8px",
        marginBottom: 0,
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        gap={1}
        sx={{ cursor: "pointer" }}
        onClick={handleToggle}
      >
        <Badge badgeContent={unreadCounts} color="success">
          <Typography sx={{ color: "inherit" }}>Chats</Typography>
        </Badge>
      </Stack>
      <Stack alignItems="center" direction="row" gap={1}>
        <Box>
          <UserControlPermissionGuard
            controlCode={UserPermissionConfig.startChat}
          >
            <Tooltip title="New chat">
              <span>
                <IconButton
                  disabled={!toggle}
                  sx={{ color: "inherit" }}
                  onClick={handleOpenContactList}
                >
                  <AddCommentIcon sx={{ fontSize: 17 }} />{" "}
                </IconButton>
              </span>
            </Tooltip>
          </UserControlPermissionGuard>

          <ControlPermissionGuard
            controlCode={PermissionConfig.Chats.controlCode.newGroup}
            permissions={accessPermissionsSlice?.permissions}
            roleName={decoded?.RoleName}
            screenCode={PermissionConfig.Chats.screenCode}
            screenURL={PermissionConfig.Chats.screenURL}
          >
            <Tooltip title="Create group">
              <span>
                <IconButton
                  disabled={!toggle}
                  sx={{ color: "inherit" }}
                  onClick={handleOpenCreateGroup}
                >
                  <GroupAddIcon sx={{ fontSize: 20 }} />{" "}
                </IconButton>
              </span>
            </Tooltip>
          </ControlPermissionGuard>
          <Tooltip title="Toggle chat">
            <IconButton sx={{ color: "inherit" }} onClick={handleToggle}>
              {toggle ? (
                <KeyboardArrowDownIcon sx={{ fontSize: 20 }} />
              ) : (
                <KeyboardArrowUpIcon sx={{ fontSize: 20 }} />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>
    </Stack>
  );
}

export default React.memo(ChatRoomHeader);
