/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */

import { RoleConstants } from "./permission.config";

interface IProps {
  children?: any;
  screenCode: any;
  controlCode: any;
  screenURL: any;
  isOverCome?: any;
  permissions: string[];
  roleName: string;
}
function ControlPermissionGuard(props: IProps): any {
  const {
    children,
    screenCode,
    controlCode,
    isOverCome,
    permissions,
    roleName,
  } = props;

  if (isOverCome) {
    return children;
  }
  if (RoleConstants?.superAdmin === roleName) {
    return children;
  }

  if (permissions[screenCode]?.includes(controlCode)) {
    return children;
  }
  return null;
}
export default ControlPermissionGuard;
