// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Spinner } from "components/loader";
import { Suspense } from "react";

// ===========================|| LOADABLE - LAZY LOADING ||=========================== //

const Loadable = (Component: any) =>
  // eslint-disable-next-line func-names
  function (props: any) {
    return (
      <Suspense fallback={<Spinner />}>
        <Component {...props} />
      </Suspense>
    );
  };

export default Loadable;
