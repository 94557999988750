/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */

import jwtDecode from "jwt-decode";
import { store } from "redux/store";
import { RoleConstants } from "./permission.config";

interface IProps {
  children?: any;
  screenCode: any;
}
function SidebarPermissionGuard(props: IProps): any {
  const { children, screenCode } = props;
  const state: any = store.getState();
  const decodedToken: any = jwtDecode(state?.user?.token);
  if (RoleConstants.superAdmin === decodedToken?.RoleName) {
    return children;
  }
  if (state?.accessPermissionsSlice?.permissions[screenCode]) {
    return children;
  }
  return null;
}
export default SidebarPermissionGuard;
