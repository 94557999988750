/* eslint-disable import/no-extraneous-dependencies */
import useCallManager from "pages/call-manager/hook/useCallManager";
import CallManager from "./CallManager";

function CallProvider() {
  const callManagerData = useCallManager();
  if (callManagerData.token) {
    return <CallManager />;
  }
  return null;
}

export default CallProvider;
