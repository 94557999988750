import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { SocketContext } from "SocketContex";
import Stopwatch from "pages/call-manager/components/Stopwatch";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

function VoiceCallMinimize(props: any) {
  const { callStatus, stopwatchStatus, handleHangUpCall } = props;
  const { voiceCallMiniPopup, setVoiceCallMiniPopup } =
    useContext(SocketContext);
  const callInformation: any = useSelector(
    (state: RootState) => state.callManager?.callRequest,
  );

  const chats = useSelector((state: RootState) => state.chats);
  const { selectedChatRoomId } = chats;

  const minimizeCall = () => {
    setVoiceCallMiniPopup?.(false);
  };
  return (
    <Box
      sx={{
        // minHeight: callPopup ? "130px" : "1px",
        // height: callPopup ? "130px" : "1px",
        width: voiceCallMiniPopup ? "400px" : "1px",
        position: voiceCallMiniPopup ? "fixed" : "relative",
        right: selectedChatRoomId ? "877px" : "710px",
        bottom: "10px",
        padding: "15px",
        // border: "1px solid black",
        boxShadow: "0 1px 6px #00000040",
        borderRadius: "10px",
        background: "#fff",
        zIndex: 9999999,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Stack>
        <Typography>to : {callInformation?.To}</Typography>
        <Typography>from :{callInformation?.from}</Typography>
      </Stack>

      <Stopwatch callStatus={callStatus} stopwatchStatus={stopwatchStatus} />
      <Button
        size="small"
        sx={{
          marginLeft: "82px",
          backgroundColor: "#f13737",
          color: "#ffffff",
          m: 1,
          "&:hover": {
            backgroundColor: "#f13737",
            color: "#ffffff",
          },
        }}
        onClick={handleHangUpCall}
      >
        End
      </Button>
      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <IconButton onClick={minimizeCall}>
          <FullscreenIcon sx={{ fontSize: "22px" }} />
        </IconButton>
      </Stack>
    </Box>
  );
}

export default VoiceCallMinimize;
