import {
  createContext,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from "react";

interface DripCampaignContextProps {
  addContact: boolean;
  addToFolder: boolean;
  openContactList: boolean;
  selectedCampaignId: number;
  handleAddContact: (state: boolean) => void;
  handleAddToFolder: (state: boolean) => void;
  handleOpenContactList: (state: boolean) => void;
  handleSelectedCampaign: (state: number) => void;
}

const DripCampaignContext = createContext<DripCampaignContextProps>({
  addContact: false,
  addToFolder: false,
  openContactList: false,
  selectedCampaignId: 0,
  handleAddContact: () => {},
  handleAddToFolder: () => {},
  handleOpenContactList: () => {},
  handleSelectedCampaign: () => {},
});

interface IContextProvider {
  children: ReactNode;
}

function DripCampaignContextProvider({ children }: IContextProvider) {
  const [addContact, setAddContact] = useState(false);
  const [addToFolder, setAddToFolder] = useState(false);
  const [openContactList, setOpenContactList] = useState(false);
  const [selectedCampaignId, setSelectedCampaignId] = useState(0);

  const handleAddContact = useCallback((state: boolean) => {
    return setAddContact(state);
  }, []);
  const handleOpenContactList = useCallback((state: boolean) => {
    return setOpenContactList(state);
  }, []);

  const handleAddToFolder = useCallback((state: boolean) => {
    return setAddToFolder(state);
  }, []);

  const handleSelectedCampaign = useCallback((state: number) => {
    return setSelectedCampaignId(state);
  }, []);

  const allValues = useMemo(() => {
    return {
      addContact,
      openContactList,
      addToFolder,
      selectedCampaignId,
      handleAddToFolder,
      handleAddContact,
      handleOpenContactList,
      handleSelectedCampaign,
    };
  }, [
    addContact,
    addToFolder,
    handleAddContact,
    handleAddToFolder,
    handleOpenContactList,
    handleSelectedCampaign,
    openContactList,
    selectedCampaignId,
  ]);

  return (
    <DripCampaignContext.Provider value={allValues}>
      {children}
    </DripCampaignContext.Provider>
  );
}

export { DripCampaignContext, DripCampaignContextProvider };
