/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { CardFeeData } from "pages/auth/payment/query/useFetchCardData";
import { useAppDispatch } from "redux/store";

export type MemberData = { id: number; value: string };

export interface IPaymentInvoiceReducer {
  cardFeeData: CardFeeData | undefined;
}

const initialState: IPaymentInvoiceReducer = {
  cardFeeData: {} as CardFeeData,
};

interface IPayload {
  cardFeeData?: CardFeeData;
}

interface IAction {
  payload: IPayload;
  type: string;
}
const paymentInvoiceSlice = createSlice({
  name: "paymentInvoice",
  initialState,
  reducers: {
    setCardFeeData: (state, action: IAction) => {
      state.cardFeeData = action?.payload?.cardFeeData;
    },
  },
});

export const { setCardFeeData } = paymentInvoiceSlice.actions;

export const usePaymentInvoiceActions = () => {
  const dispatch = useAppDispatch();
  return {
    setCardFeeData: (payload: IPayload) => dispatch(setCardFeeData(payload)),
  };
};
const { reducer } = paymentInvoiceSlice;

export default reducer;
