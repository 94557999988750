/* eslint-disable prettier/prettier */
import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Box,
  Button,
  DialogContent,
  Divider,
  ListItem,
  ListItemAvatar,
  Typography,
} from "@mui/material";
// import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Slider from "components/layouts/popup-modals/Slider";
import { ATTACHMENT_URL } from "config";
import { IToggleModel } from "pages/user/contacts/details/type";
import { useFetchAllReadChatInfo } from "pages/user/inbox/query/useFetchGroupChatinfo";
import { useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { convertDateUtcToLocal } from "utils";

interface ICreateTask {
  open: boolean;
  handleClose: (_: keyof IToggleModel) => void;
  name?: string;
  title?: string;
  from?: string;
  infoData?: any;
}

function GroupChatInfo(props: ICreateTask) {
  const { open, handleClose, name, infoData } = props;
  const onClose = () => {
    handleClose(name?.toLowerCase() as keyof IToggleModel);
  };
  const {
    data: chatInfo,
    refetch: refetchChatInfo,
  } = useFetchAllReadChatInfo(infoData?.id);

  useEffect(() => {
    if (open) {
      refetchChatInfo();
    }
  }, [open]);

  return (
    <Slider open={open} size="xs">
      <DialogTitle>
        Message Info
        <IconButton
          aria-label="close"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box>
        <PerfectScrollbar>
          <DialogContent dividers>
            <List
              sx={{
                width: "100%",
                maxWidth: 500,
                bgcolor: "background.paper",
              }}
            >
              {typeof infoData === "string" ? (
                <Typography sx={{ p: 1 }}>
                  Last seen:{" "}
                  {convertDateUtcToLocal({
                    utcDate: infoData,
                    hasDatetime: true,
                  })}
                </Typography>
              ) : (
                chatInfo?.data?.map((item) => {
                  return (
                    <>
                      <ListItem>
                        <ListItemAvatar>
                          {/* {item?.userProfileUrl ? ( */}
                          <Avatar
                            alt={item?.userName}
                            src={`${ATTACHMENT_URL}${item?.userProfileUrl}`}
                          />
                          {/* ) : null} */}
                        </ListItemAvatar>
                        <ListItemText
                          primary={item?.userName}
                          secondary={convertDateUtcToLocal({
                            utcDate: item?.readDateTime,
                            hasDatetime: true,
                          })}
                          secondaryTypographyProps={{
                            sx: {
                              fontSize: "11px",
                            },
                          }}
                        />
                      </ListItem>
                      <Divider component="li" />
                    </>
                  );
                })
              )}
            </List>
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="center"
              paddingTop="10px"
              spacing={2}
            >
              <Button
                autoFocus
                color="error"
                startIcon={<CloseIcon />}
                style={{ padding: "0.5rem 1rem" }}
                variant="contained"
                onClick={onClose}
              >
                Cancel
              </Button>
            </Stack>
          </DialogContent>
        </PerfectScrollbar>
      </Box>
    </Slider>
  );
}

export default GroupChatInfo;
