import { Box } from "@mui/material";
import { SocketContext } from "SocketContex";
import useDecodedData from "hooks/useDecodedData";
import { debounce } from "lodash";
import { useApiActions } from "pages/user/inbox/query/useApiAction";
import {
  IChatRoom,
  useFetchChatRoom,
} from "pages/user/inbox/query/useFetchChatRoom";
import React, {
  SyntheticEvent,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { useChatsActions } from "redux/chats/chats";
import { RootState } from "redux/store";
import { addChatRoomIdToUrl, handleChatCount, sumObjectValues } from "utils";
import ChatDetails from "./components/chat-details";
import ChatRoomBox from "./components/chat-room-box";

function MinimizedChat() {
  const chats = useSelector((state: RootState) => state.chats);
  const { selectedChatRoomId, toggleChatRoomList } = chats;
  const [searchValue, setSearchValue] = useState("");
  const [originalList, setOriginalList] = useState<any[]>([]);

  const [newChat, setNewChat] = useState<any>(null);
  const [pageNo] = useState(1);
  // const { data: fetchedUnreadCount } = useFetchUnreadCount();
  const {
    setToggleChatRoom,
    setToggleChatRoomList,
    setSelectedChatRoomId,
    setSelectedChatRoom,
  } = useChatsActions();
  const { chatRooms, setChatRoomCounts, setChatRooms, chatRoomCount } =
    useContext(SocketContext);
  const { tryMarkUnread, tryReadChatById } = useApiActions();
  const [searching, setSearching] = useState<boolean>(false);
  const scrollToMiniRef = useRef<HTMLDivElement>(null);
  const queryClient = useQueryClient();
  const decoded = useDecodedData();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const {
    chatRoomList,
    isLoading: isChatRoomLoading,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
    isRefetching,
    refetch: refetchRoom,
  } = useFetchChatRoom(searchValue, pageNo, toggleChatRoomList);
  // const { setChatroomId } = useMiniChatDetailsUtils();
  // const unreadCount = fetchedUnreadCount;

  useEffect(() => {
    if (chatRoomList && !searchValue) {
      setOriginalList(chatRoomList);
    }
  }, [toggleChatRoomList]);

  const debouncedSearch = debounce(async () => {
    // handleRefetch();
    await refetchRoom();
    setSearching(false);
  }, 800);

  const handleSearchChatRoom = (value: string) => {
    setSearching(true);
    setSearchValue(value);
    if (!value || value === "" || value === " ") {
      setChatRooms(originalList);
      setSearching(false);
    } else {
      debouncedSearch();
    }
  };

  useLayoutEffect(() => {
    setToggleChatRoom({ toggleChatRoom: false });
    setToggleChatRoomList({ toggleChatRoomList: false });
  }, []);

  const handleMarkUnread = async (messageId: number, roomId: number) => {
    const body = {
      messageId,
      roomId,
      value: true,
    };
    await tryMarkUnread(body);
  };

  const handleChat = async (
    event: SyntheticEvent,
    roomId: number,
    room: IChatRoom,
  ) => {
    event?.stopPropagation();
    localStorage.setItem("roomId", roomId.toString());
    handleChatCount(room, queryClient);

    setChatRoomCounts((prev: any) => {
      return { ...prev, [room.id]: 0 };
    });

    addChatRoomIdToUrl({
      chatroomid: roomId,
      expand: 1,
    });

    setSelectedChatRoom({ selectedChatRoom: null });
    setSelectedChatRoomId({ selectedChatRoomId: 0 });

    const newChatRooms = chatRooms?.map((chat: any) => {
      if (chat.id === roomId) {
        return { ...chat, unReadMessagesCount: 0 };
      }

      return chat;
    });
    setChatRooms(newChatRooms);
    setToggleChatRoom({ toggleChatRoom: true });
    const updatedRoom: IChatRoom = {
      ...room,
      toUser: Number(decoded?.id) === room.toUser ? room.fromUser : room.toUser,
      toUserName:
        Number(decoded?.id) === room.toUser
          ? room.fromUserName
          : room.toUserName,
      toProfileUrl:
        Number(decoded?.id) === room.toUser
          ? room.fromProfileUrl
          : room.toProfileUrl,
      fromProfileUrl:
        Number(decoded?.id) === room.toUser
          ? room.toProfileUrl
          : room.fromProfileUrl,
    };
    setSelectedChatRoom({
      selectedChatRoom: updatedRoom,
    });
    setSelectedChatRoomId({ selectedChatRoomId: roomId });
    // setChatroomId(String(roomId));

    tryReadChatById(roomId);
    // scrollToBottom(messagesEndRef);
    // navigate(`${AppRoutes.INBOX}/${roomId}`);
  };

  const handleCloseSearch = () => {
    setSearchValue("");
    debouncedSearch();
  };

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          right: "362px",
          bottom: 0,
          zIndex: 999,
        }}
      >
        {!!selectedChatRoomId && (
          <ChatDetails
            fetchPreviousRoom={fetchPreviousPage}
            handleChat={handleChat}
            handleMarkUnread={handleMarkUnread}
            hasPreviousRoom={hasPreviousPage}
            isChatRoomLoading={isChatRoomLoading}
            isFetchingNextRoom={isFetchingNextPage}
            isFetchingPreviousRoom={isFetchingPreviousPage}
            isRefetchingRoom={isRefetching}
            messagesEndRef={messagesEndRef}
            newChat={newChat}
            refetching={refetchRoom}
            scrollToMiniRef={scrollToMiniRef}
            setNewChat={setNewChat}
          />
        )}
      </Box>
      <ChatRoomBox
        chatRooms={chatRooms || []}
        data={chatRoomList || []}
        fetchNextRoom={fetchNextPage}
        handleChat={handleChat}
        handleCloseSearch={handleCloseSearch}
        handleSearchChatRoom={handleSearchChatRoom}
        hasNextRoom={hasNextPage}
        isChatRoomLoading={isChatRoomLoading}
        isFetchingNextRoom={isFetchingNextPage}
        isFetchingPreviousRoom={isFetchingPreviousPage}
        isRefetchingRoom={isRefetching}
        newChat={newChat}
        searching={searching}
        searchValue={searchValue}
        setNewChat={setNewChat}
        setToggle={setToggleChatRoomList}
        unreadCounts={sumObjectValues(chatRoomCount) || 0}
      />
    </>
  );
}

export default React.memo(MinimizedChat);
