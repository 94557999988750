/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-plusplus */
/* eslint-disable global-require */
import CallIcon from "@mui/icons-material/Call";
import CloseIcon from "@mui/icons-material/Close";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import GroupsIcon from "@mui/icons-material/Groups";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import VideoCallIcon from "@mui/icons-material/VideoCall";
import {
  Avatar,
  Box,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { SocketContext } from "SocketContex";
import { ATTACHMENT_URL } from "config";
import useDecodedData from "hooks/useDecodedData";

import useChatHeaderUtils from "pages/user/inbox/component/chat-header/hooks/useChatHeaderUtils";
import ChatPopover from "pages/user/inbox/component/pop-over";
import VideoPopup from "pages/user/inbox/component/video-popup";
import { IChatRoom } from "pages/user/inbox/query/useFetchChatRoom";
import { useFetchMembers } from "pages/user/inbox/query/useFetchMembers";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useChatsActions } from "redux/chats/chats";
import palette from "theme/palette";
import { addChatRoomIdToUrl, textEllipsis } from "utils";
import { getInitials } from "utils/get-initials";

interface IProps {
  room: IChatRoom;
  editGroup?: (data: any) => void;
  refetch: () => void;
  toggle: boolean;
  setToggle: any;
  fromMinimize?: boolean;
  handleChat?: (event: any, roomId: number, room: IChatRoom) => void;
}

function MiniChatHeader(props: IProps) {
  const {
    room,
    editGroup,
    refetch,
    toggle,
    setToggle,
    fromMinimize,
    handleChat,
  } = props;
  const settingsRef = useRef(null);
  const [openAccountPopover, setOpenAccountPopover] = useState(false);
  const { setSelectedChatRoomId, setSelectedChatRoom } = useChatsActions();
  const [groupMembersList, setGroupMembersList] = useState<any[]>([]);
  const decoded = useDecodedData();
  const {
    data: groupMembers,
    isLoading: groupMembersIsLoading,
    refetch: groupMembersRefetch,
  } = useFetchMembers(room?.id || 0);

  // useEffect(() => {
  //   if (expand != null) {
  //     setToggle({ toggleChatRoom: !!Number(expand) });
  //     addChatRoomIdToUrl({
  //       chatroomid: room?.id,
  //       expand: Number(expand),
  //     });
  //   } else {
  //     setToggle({ toggleChatRoom: false });

  //   }
  // }, [expand]);

  const handleToggleView = (event: any) => {
    setToggle({ toggleChatRoom: !toggle });

    if (!toggle) {
      handleChat?.(event, room?.id, room);
    } else {
      addChatRoomIdToUrl({
        chatroomid: room?.id,
        expand: toggle ? 0 : 1,
      });
    }
  };
  const handleCloseRoom = () => {
    addChatRoomIdToUrl({
      chatroomid: 0,
      expand: 0,
    });
    setSelectedChatRoomId({ selectedChatRoomId: 0 });
    setSelectedChatRoom({ selectedChatRoom: null });
  };
  const {
    // groupMembersList,
    handelGroupsMembers,
    handlePopupClose,
    handlePopupOpen,
    handleVideoCall,
    open,
  } = useChatHeaderUtils({
    groupMembers: groupMembers?.data || [],
    selectedRoom: room,
  });

  const { callAccepted, me, callRejected, cameraOn, onlineUserList } =
    useContext(SocketContext);
  const onlineUserId =
    Number(decoded?.id) === room?.toUser ? room.fromUser : room.toUser;

  const convertMembersToString = async () => {
    const response = await handelGroupsMembers();
    setGroupMembersList(response);
  };
  useEffect(() => {
    if (room?.isGroup) {
      convertMembersToString();
    }
  }, [groupMembers?.data, room]);

  useEffect(() => {
    if (toggle) {
      refetch();
    }
  }, [toggle]);

  useEffect(() => {
    if (callRejected) {
      handlePopupClose();
    }
  }, [callRejected]);

  useEffect(() => {
    if (me) {
      refetch();
    }
  }, [me]);

  useEffect(() => {
    if (room?.isGroup) {
      handelGroupsMembers();
    }
  }, [groupMembers?.data]);

  useEffect(() => {
    if (room?.isGroup) {
      groupMembersRefetch();
    }
  }, [room]);

  return (
    <>
      <Stack
        alignItems="center"
        borderBottom="1px solid #c1c1c1"
        direction="row"
        gap={1}
        justifyContent="space-between"
        sx={{
          // position: "absolute",
          // top: 0,
          // right: 0,
          // left: 0,
          zIndex: 9,
          color: "#fff",
          backgroundColor: "#5048e5",
          padding: "8px",
          // margin: "-8px",
        }}
      >
        <Stack direction="row" sx={{ alignItems: "center" }}>
          <Box
            sx={{ marginRight: "8px", cursor: "pointer" }}
            onClick={handleToggleView}
          >
            {room?.isGroup ? (
              room.profileUrl ? (
                <Avatar src={`${ATTACHMENT_URL}${room.profileUrl}`}>
                  <GroupsIcon />
                </Avatar>
              ) : (
                <Avatar>
                  <GroupsIcon />
                </Avatar>
              )
            ) : room.fromProfileUrl || room.toProfileUrl ? (
              <Avatar
                src={
                  Number(decoded?.id) === room.toUser
                    ? `${ATTACHMENT_URL}${room.fromProfileUrl}`
                    : `${ATTACHMENT_URL}${room.toProfileUrl}`
                }
              >
                {Number(decoded?.id) === room.toUser
                  ? getInitials(room?.fromUserName)
                  : getInitials(room.toUserName)}
              </Avatar>
            ) : (
              <Avatar>
                {Number(decoded?.id) === room.toUser
                  ? getInitials(room?.fromUserName)
                  : getInitials(room.toUserName)}
              </Avatar>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box>
              {/* <Badge badgeContent={room?.unReadMessagesCount} color="success"> */}
              <Typography sx={{ cursor: "pointer" }} onClick={handleToggleView}>
                {room?.isGroup
                  ? textEllipsis(room?.groupName, fromMinimize ? 20 : 40)
                  : room?.toUserName}
              </Typography>
              {/* </Badge> */}
              {room?.isGroup ? (
                // <Tooltip
                //   sx={{ cursor: "pointer" }}
                //   title="double click see all Members"
                // >
                <Typography
                  fontSize="12px"
                  onDoubleClick={() => {
                    editGroup?.({
                      groupName: room?.groupName,
                      id: room?.id,
                    });
                  }}
                >
                  {groupMembersList && !groupMembersIsLoading
                    ? groupMembers && groupMembers?.data?.length > 4
                      ? `you, ${textEllipsis(
                        groupMembersList?.toString(),
                        fromMinimize ? 35 : 35,
                      )}`
                      : `you, ${textEllipsis(
                        groupMembersList?.toString(),
                        fromMinimize ? 35 : 35,
                      )}`
                    : "Loading ..."}
                </Typography>
              ) : // </Tooltip>
                null}
            </Box>
            <Box>
              {room?.isGroup ? null : (
                <Typography
                  sx={{
                    color: "inherit",
                    fontWeight: "600",
                    fontSize: "10px",
                  }}
                >
                  {onlineUserList?.includes(onlineUserId)
                    ? "Online"
                    : "offline"}
                </Typography>
              )}
              {!room.isGroup && (
                <FiberManualRecordIcon
                  sx={{
                    color: onlineUserList?.includes(onlineUserId)
                      ? "green"
                      : "red",
                    fontSize: "12px",
                    position: "absolute",
                    top: "3.1em",
                    zIndex: 1,
                    left: "2.4rem",
                  }}
                />
              )}
            </Box>
          </Box>
        </Stack>

        <Stack direction="row" sx={{ gap: 1, position: "relative" }}>
          <IconButton
            disabled={callAccepted}
            sx={{
              marginRight: "5px",
              color: callAccepted ? palette.text.muted : "inherit",
            }}
            onClick={() => handleVideoCall(room, true)}
          >
            <VideoCallIcon color="inherit" />
          </IconButton>
          {!room?.isGroup ? (
            <IconButton
              color="success"
              sx={{
                marginRight: "5px",
                fontSize: "1.3rem",
                color: "inherit",
              }}
              onClick={() => handleVideoCall(room, false)}
            >
              <CallIcon color="inherit" sx={{ fontSize: "1.3rem" }} />
            </IconButton>
          ) : null}

          {/* <Box sx={{ display: room?.isGroup ? "flex" : "none" }}>
            <PopupState popupId="edit-group-popover" variant="popover">
              {(popupState) => (
                <div>
                  <Tooltip title="Utilities">
                    <IconButton
                      {...bindTrigger(popupState)}
                      sx={{ color: "inherit" }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                  </Tooltip>
                  <Popover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    sx={{
                      height: "60vh",
                      top: "10px",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <Box
                      sx={{ display: "flex", flexDirection: "column", p: 2 }}
                    >
                      <Button
                        sx={{ marginTop: "5px" }}
                        variant="text"
                        onClick={() => {
                          editGroup?.({
                            groupName: room?.groupName,
                            id: room?.id,
                          });
                          bindTrigger(popupState);
                        }}
                      >
                        Edit group
                      </Button>
                    </Box>
                    <Divider />
                  </Popover>
                </div>
              )}
            </PopupState>
          </Box> */}
          <Box>
            <Box>
              <Tooltip title="Close chat">
                <IconButton sx={{ color: "inherit" }} onClick={handleCloseRoom}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Toggle chat">
                <IconButton
                  sx={{ color: "inherit" }}
                  onClick={handleToggleView}
                >
                  {toggle ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Stack>
      </Stack>
      <VideoPopup
        fromMinimize
        cameraOn={cameraOn}
        handleClose={handlePopupClose}
        handleOpen={handlePopupOpen}
        open={open}
        toCall={
          Number(decoded?.id) === room.toUser
            ? room?.fromUserName
            : room.toUserName
        }
      />
      <ChatPopover
        anchorEl={settingsRef.current}
        open={openAccountPopover}
        onClose={() => {
          return setOpenAccountPopover(false);
        }}
      />
    </>
  );
}

export default React.memo(MiniChatHeader);
