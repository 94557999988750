/* eslint-disable object-shorthand */
/* eslint-disable prettier/prettier */
import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/es/storage";

import accessPermissionsSlice from "./access-permissions/accessPermissions";
import activity from "./activity/activity";
import activityFetchResponse from "./activity/activityFetchResponse";
import agreementDownload from "./agreement-download/agreementDownload";
import assignAS from "./assign-as/assignAS";
import callCampaign from "./call-campaign/callCampaign";
import callLogs from "./call-logs/callLogs";
import callManager from "./call-manager/callManager";
import callNote from "./call-note/callNote";
import chatUserList from "./chat-users-list/chatUsersList";
import chats from "./chats/chats";
import common from "./common/common";
import contactActions from "./contacts/contactActions";
import contacts from "./contacts/contacts";
import customFields from "./custom-fields/CustomFields";
import customers from "./customers/customers";
import dashboard from "./dashboard/dashboard";
import disposition from "./disposition/disposition";
import dnc from "./dnc/dnc";
import draftEmail from "./email/draftEmail";
import emailSlice from "./email/email";
import emailDetailsSlice from "./email/emailItem";
import platformNameSlice from "./email/platformName";
import selectedClintId from "./email/selectedClintId";
import leadGroup from "./lead-group/leadGroup";
import leadSources from "./lead-source/leadSource";
import leadStatuses from "./lead-status/leadStatus";
import minimizedChat from "./minimized-chat/minimizedChat";
import paymentInvoice from "./payment-invoice/paymentInvoice";
import permissions from "./permissions/permissions";
import pipelines from "./pipeline/pipeline";
import reconciliation from "./reconciliation/reconciliation";
import roles from "./roles/roles";
import screens from "./screen/screen";
import sequence from "./sequence/sequenceSlice";
import tags from "./tags/tags";
import team from "./team/team";
import user from "./user/auth";

const persistConfig = {
  key: "root",
  storage,
};



const combinedReducer = combineReducers({
  user,
  chats,
  minimizedChat,
  contacts,
  reconciliation,
  contactActions,
  team,
  screens,
  permissions,
  roles,
  customFields,
  dashboard,
  leadSources,
  leadGroup,
  leadStatuses,
  common,
  pipelines,
  tags,
  activity,
  callManager,
  paymentInvoice,
  assignAS,
  customers,
  sequence,
  chatUserList,
  callCampaign,
  disposition,
  dnc,
  accessPermissionsSlice,
  agreementDownload,
  email: emailSlice,
  emailItem: emailDetailsSlice,
  setPlatformName: platformNameSlice,
  selectedClintId,
  callLogs,
  callNote,
  activityFetchResponse,
  draftEmail,
});

const persistedReducer = persistReducer(persistConfig, combinedReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});

const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export { persistor, store };

