import { Box, Button, Typography } from "@mui/material";
import { SocketContext } from "SocketContex";
import useDecodedData from "hooks/useDecodedData";
import VideoPopup from "pages/user/inbox/component/video-popup";
import { useContext, useState } from "react";

function VideoCallProvider() {
  const [open, setOpen] = useState(false);
  const [callConnect, setCallConnect] = useState(false);
  const decoded = useDecodedData();
  const {
    callAccepted,
    call,
    answerCall,
    rejectCall,
    callRejected,
    setCallPopup,
    setOpenCallScreen,
  } = useContext(SocketContext);

  const handlePopupOpen = () => {
    if (!callAccepted) {
      answerCall();
    }
    setCallConnect(true);
    // setOpen(true);
    // setOpenCallScreen?.(true);
    setOpenCallScreen?.(false);
    setCallPopup?.(true);
  };
  const handlePopupClose = () => {
    setOpen(false);
    setOpenCallScreen?.(false);
  };

  // condition to connect call
  const canConnect =
    call?.isReceivingCall &&
    !callAccepted &&
    !callRejected &&
    call.name !== decoded?.FullName;

  if (canConnect) {
    return (
      <>
        <Box
          sx={{
            minHeight: "130px",
            width: "300px",
            position: "fixed",
            right: "25px",
            bottom: "10px",
            padding: "16px 25px",
            zIndex: 999999,
            // border: "1px solid black",
            boxShadow: "0 1px 6px #00000040",
            borderRadius: "10px",
            background: "#fff",
          }}
        >
          <Typography paddingBottom="1rem">
            {call?.name || "Person"} is {call?.isVideoCall ? "video" : "Voice"}{" "}
            calling...
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "5px",
            }}
          >
            <Button
              sx={{
                backgroundColor: "#129677",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#129677",
                },
              }}
              onClick={handlePopupOpen}
            >
              Accept
            </Button>
            <Button
              sx={{
                backgroundColor: "#d45685",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#d45685",
                },
              }}
              onClick={() => rejectCall()}
            >
              Decline
            </Button>
          </Box>
        </Box>
      </>
    );
  }

  if (callConnect) {
    return (
      <VideoPopup
        handleClose={handlePopupClose}
        handleOpen={handlePopupOpen}
        open={open}
      />
    );
  }
  return null;
}

export default VideoCallProvider;
