import CancelIcon from "@mui/icons-material/Cancel";
import SendIcon from "@mui/icons-material/Send";
import { CircularProgress, IconButton, Stack } from "@mui/material";
import Dropzone from "components/dropzone";
import { SetStateAction, SyntheticEvent, useState } from "react";
// import { FileRejection } from "react-dropzone";
import InputEmoji from "react-input-emoji";

interface IProps {
  handleFile: (files: File[]) => void;
  formik: any;
  setIsDragging: SetStateAction<any>;
  handleKeyPress: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  isDragging: boolean;
  setImageListData: SetStateAction<any>;
  setPreviewList: SetStateAction<any>;
  handleOnEnter: (text: string) => void;
}
function DropFile(props: IProps) {
  const { handleFile, formik, setIsDragging, handleOnEnter } = props;
  const [file, setFile] = useState<File | null>(null);
  const [error] = useState("");

  const { handleSubmit, isSubmitting, values, setFieldValue } = formik;

  const onDrop = (acceptedFiles: File[]) => {
    // this callback will be called after files get dropped, we will get the acceptedFiles. If you want, you can even access the rejected files too
    setFile(acceptedFiles?.[0]);
    handleFile(acceptedFiles);
    setIsDragging(false);
    // if (data) setData([]);
  };

  // const handleDrag = (e:SyntheticEvent)=>{
  //   e.preventDefault();
  //   setIsDragging(true)
  // }
  const handleDragLeave = (e: SyntheticEvent) => {
    e.preventDefault();
    setIsDragging(false);
  };

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        // display: isDragging ? "block" : "none",
        position: "absolute",
        top: "-50vh",
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: 999,
        backgroundColor: "#fff",
      }}
      // onDragEnter={handleDrag}
      onDragLeave={handleDragLeave}
    >
      <Dropzone
        // accept={"csv/*"}
        error={error}
        fileName={file?.name}
        handleDrop={onDrop}
        styles={{ width: "100%", height: "300px", justifyContent: "center" }}
      />
      {/* <InputBox
        formik={formik}
        handleKeyPress={handleKeyPress}
        handleOnEnter={handleOnEnter}
        isDragging={isDragging}
        setImageListData={setImageListData}
        setIsDragging={setIsDragging}
        setPreviewList={setPreviewList}
      /> */}
      <InputEmoji
        // cleanOnEnter
        shouldReturn
        borderRadius={8}
        fontSize={16}
        inputClass="chat-input"
        placeholder="Type a message..."
        value={values.message}
        onChange={(value: string) => {
          // setMessageData(value);
          setFieldValue("message", value);
        }}
        onEnter={handleOnEnter}
      />
      <Stack
        alignItems="center"
        direction="row"
        gap={1}
        justifyContent="space-between"
      >
        <IconButton color="error" onClick={() => setIsDragging(false)}>
          <CancelIcon sx={{ fontSize: "24px" }} />
        </IconButton>
        <IconButton
          aria-label="voice-note"
          color="success"
          disabled={isSubmitting}
          sx={{ p: "10px" }}
          type="button"
          onClick={() => {
            handleSubmit();
            setFile(null);
          }}
        >
          {isSubmitting ? (
            <CircularProgress color="warning" size={17} />
          ) : (
            <SendIcon sx={{ fontSize: "24px" }} />
          )}
        </IconButton>
      </Stack>
    </Stack>
  );
}

export default DropFile;
