import CloseIcon from "@mui/icons-material/Close";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Box, Typography } from "@mui/material";
import useDecodedData from "hooks/useDecodedData";
import { IChat } from "pages/user/inbox/query/useFetchChats";
import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import palette from "theme/palette";
import MessageBox from "../message-item/component/message-box";

interface IProps {
  quotedMessage: IChat | null;
  handleQuoteMessageClose: () => void;
  quotedMessageOpen: boolean;
}

function QuoteMessagePreview(props: IProps) {
  const { quotedMessage, handleQuoteMessageClose, quotedMessageOpen } = props;
  const decoded = useDecodedData();

  return (
    <Box>
      {quotedMessageOpen && (
        <Box
          sx={{
            backgroundColor: "#f1f1f1",
            padding: "8px",
            borderRadius: "8px",
            marginBottom: "4px",
            border: "2px solid #c2c4c6",
            paddingLeft: "8px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box onClick={handleQuoteMessageClose}>
              <CloseIcon sx={{ fontSize: "16px", cursor: "pointer" }} />
            </Box>
          </Box>
          <Box
            sx={{
              marginBottom: "4px",
              backgroundColor: "#fff",
              padding: "4px 8px",
              borderRadius: "8px",
            }}
          >
            <PerfectScrollbar>
              <Box sx={{ maxHeight: "150px" }}>
                <Typography
                  sx={{
                    fontSize: "11px",
                    color: "#505050",
                    fontWeight: 500,
                  }}
                >
                  {quotedMessage?.fromUser === Number(decoded?.id)
                    ? "You"
                    : quotedMessage?.fromUserName}
                </Typography>
                {quotedMessage?.fileUrls &&
                  quotedMessage?.fileUrls?.length > 0 && (
                    <Typography
                      sx={{
                        fontSize: 10,
                        color: palette.text.muted,
                        marginBottom: "2px",
                      }}
                    >
                      <InsertDriveFileIcon sx={{ fontSize: 10 }} /> Attachment
                    </Typography>
                  )}

                {quotedMessage?.message && (
                  <MessageBox
                    chatStatus={quotedMessage?.status}
                    fromUser={quotedMessage?.fromUser}
                    id={quotedMessage?.id}
                    message={quotedMessage?.message}
                  />
                )}
              </Box>
            </PerfectScrollbar>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default React.memo(QuoteMessagePreview);
