import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ISequence } from "pages/admin/settings/screens/sequence/query/useFetchSequenceList";

// export interface ISequence {
//   id: number;
//   name: string;
// }

interface ISequenceInitialState {
  sequence: ISequence;
}

export const initialState: ISequenceInitialState = {
  sequence: {
    id: 0,
    title: "",
    module: "",
    description: "",
    throttle: 0,
    records: 0,
    skipHoliDays: "",
    isSMSResponse: false,
    isCallResponse: false,
    isEmailResponse: false,
    status: 0,
    createdOn: "",
    updatedOn: "",
    days: [],
  },
};

const sequenceSlice = createSlice({
  name: "sequence",
  initialState,
  reducers: {
    setSequence: (state, action: PayloadAction<ISequence>) => {
      // eslint-disable-next-line no-param-reassign
      state.sequence = action.payload;
    },
  },
});

export const { setSequence } = sequenceSlice.actions;

export default sequenceSlice.reducer;
