/* eslint-disable import/no-extraneous-dependencies */
import { FIREBASE_CONFIG, REPLACE_WITH_YOUR_VAPID_KEY } from "config";
import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from "firebase/messaging";

const firebaseCon = initializeApp(FIREBASE_CONFIG);

export const requestForToken = async () => {
  const isSupport = await isSupported();
  if (isSupport) {
    const messaging = getMessaging(firebaseCon);

    return getToken(messaging, { vapidKey: REPLACE_WITH_YOUR_VAPID_KEY })
      .then((currentToken) => {
        if (currentToken) {
          // Perform any other neccessary action with the token
        } else {
          // Show permission request UI
          console.error(
            "No registration token available. Request permission to generate one.",
          );
        }
      })
      .catch((err) => {
        console.error("An error occurred while retrieving token. ", err);
      });
  }
  return null;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export const getPreviousRemainder = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, 2000);
  });
};
