/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState, useAppDispatch } from "redux/store";

export interface IContactActionsReducer {
  massEmail?: boolean;
  massSMS?: boolean;
}

const initialState: IContactActionsReducer = {
  massEmail: false,
  massSMS: false,
};

interface IPayload {
  massEmail?: boolean;
  massSMS?: boolean;
}

interface IAction {
  payload: IPayload;
  type: string;
}

const contactActionsSlice = createSlice({
  name: "contactActions",
  initialState,
  reducers: {
    setMassEmail: (state, action: IAction) => {
      state.massEmail = action.payload.massEmail;
    },
    setMassSMS: (state, action: IAction) => {
      state.massSMS = action.payload.massSMS;
    },
  },
});

const actionState = (state: RootState) => state.contactActions;

export const selectMassEmail = createSelector(
  [actionState],
  (state) => state.massEmail,
);
export const selectMassSMS = createSelector(
  [actionState],
  (state) => state.massSMS,
);

export const { setMassEmail, setMassSMS } = contactActionsSlice.actions;

export const useTableActions = () => {
  const dispatch = useAppDispatch();
  return {
    setMassEmail: (massEmail: boolean) => dispatch(setMassEmail({ massEmail })),
    setMassSMS: (massSMS: boolean) => dispatch(setMassSMS({ massSMS })),
  };
};
const { reducer } = contactActionsSlice;

export default reducer;
