/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { createSelector, createSlice } from "@reduxjs/toolkit";
import { ITags } from "pages/admin/settings/screens/tags/query/useFetchTags";
import { RootState, useAppDispatch } from "redux/store";

export interface ITagReducer {
  tag: ITags | null;
}

const initialState: ITagReducer = {
  tag: null,
};

interface IPayload {
  tag: ITags;
}

interface IAction {
  payload: IPayload;
  type: string;
}
const tagSlice = createSlice({
  name: "tags",
  initialState,
  reducers: {
    setTags: (state, action: IAction) => {
      state.tag = action.payload.tag;
    },
    removeTags: (state) => {
      state.tag = null;
    },
  },
});

const tagStates = (state: RootState) => state.tags;

export const selectTag = createSelector(
  [tagStates],
  (state) => state.tag || {},
);

export const { setTags, removeTags } = tagSlice.actions;

export const useTagsActions = () => {
  const dispatch = useAppDispatch();
  return {
    setTags: (payload: IPayload) => dispatch(setTags(payload)),
    removeTags: () => dispatch(removeTags()),
  };
};
const { reducer } = tagSlice;

export default reducer;
