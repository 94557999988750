import AppRoutes from "navigation/appRoutes";

const RoleConstants = {
  admin: "Admin",
  superAdmin: "SuperAdmin",
  QA: "Q.A",
  client: "Client",
  AS: "A.S",
  Sales: "Sales",
  owner: "Owner",
};

const PermissionConfig = {
  navigationBar: {
    screenCode: "setting_01",
    controlCode: {
      selectCompany: "select_company_01", // only for super admin
    },
    screenURL: "/",
  },
  navbar: {
    screenCode: "nav_bar_01",
    controlCode: {
      selectConfiguration: "select_configuration_01", // Will not be visible only to appointment setters
      selectAddContact: "select_add_contact_01", // Will not be visible only to appointment setters
      selectNextDial: "select_next_dial_01", // Will not be visible only to appointment setters and Client
    },
    screenURL: "/",
  },
  // navigationBarAddContact: {
  //   screenCode: "nav_bar_01",
  //   controlCode: {
  //
  //   },
  //   screenURL: "/",
  // },
  // navigationBarNextDial: {
  //   screenCode: "nav_bar_01",
  //   controlCode: {
  //   },
  //   screenURL: "/",
  // },
  // done
  setting: {
    screenCode: "setting_01",
    controlCode: {
      roll: "role_01",
      myTeam: "my_team_01",
      screens: "screens_01",
      screenControl: "control_01",
      screenAccess: "screen_access_01",
      myClients: "my_clients_01",
      myCompany: "my_company_01",
      campaign: "campaign_01",
      Sequence: "sequence_01",
      callerIDS: "caller_ids_01",
      virtualNumber: "virtual_number_01",
      callResults: "call_results_01",
      callForward: "call_forward_01",
      callSuggestions: "call_suggestions_01",
      leadSource: "lead_source_01",
      leadStatus: "lead_status_01",
      leadGroup: "lead_group_01",
      leadPipeline: "pipeline_lead_01",
      globalDispositionStatuses: "global_disposition_statuses_01",
      timeSlot: "campaign_time_slot_01",
      customFields: "custom_field_01",
      template: "template_01",
      attachments: "attachments_01",
      tags: "tags_01",
      dnc: "dnc_01",
      reconciliation: "reconciliation_01",
      callingScript: "calling-script_01",
      emailConfiguration: "email-configuration_01",
      callLogs: "call_logs_01",
      blackList: "blackList_01",
    },
    //   -----------IMP
    // if you Add Any new Permission in Setting Screen
    // also add in usePermissionSetting.ts file
    screenURL: "/settings",
  },
  // done
  roleList: {
    screenCode: "role_list_01",
    controlCode: {
      add: "role_list_add_001",
      delete: "role_list_delet_001",
      edit: "role_list_edit_001",
    },
    screenURL: "/settings/roles",
  },
  // done
  callLogs: {
    screenCode: "call_logs_01",
    controlCode: {
      // add: "call_logs_add_001",
      // delete: "call_logs_delet_001",
      // edit: "call_logs_edit_001",
      filter: "call_logs_filter_001",
      user: "call_logs_user_001",
      dates: "call_logs_dates_001",
      todays: "call_logs_todays_001",
      clients: "call_logs_clients_001",
      appointmentSetters: "call_logs_AS_001",
      selectDuration: "call_logs_select_duration_001",
      // download: "call_logs_download_001",
    },
    screenURL: "/settings/roles",
    clientDropdown: "client_dropdown_001",
    AppointmentSetterDropdown: "appointment_setter_dropdown_001",
  },
  // done
  userList: {
    screenCode: "user_list_01",
    controlCode: {
      add: "add_user_list_01",
      delete: "user_list_delete_01",
      edit: "user_list_edit_01",
      password: "user_list_password_01",
    },
    screenURL: "/settings/team",
  },
  // done
  screenList: {
    screenCode: "screen_list_01",
    controlCode: {
      add: "add_screen_list_01",
      delet: "screen_list_delete_01",
      edit: "screen_list_edit_01",
    },
    screenURL: "/settings/screens",
  },
  // done
  permissionList: {
    screenCode: "permission_list_01",
    controlCode: {
      add: "add_permission_list_01",
      delete: "permission_list_delete_01",
      edit: "permission_list_edit_01",
    },
    screenURL: "/settings/permissions",
  },
  // done
  screenAccess: {
    screenCode: "screenAccess_01",
    controlCode: {
      save: "save_screenAccess_01",
    },
    screenURL: "/settings/screen-access",
  },
  // done
  myClients: {
    screenCode: "my_clint_01",
    controlCode: {
      delet: "my_client_delete_01",
    },
    screenURL: "/settings",
  },
  // done
  Campaign: {
    screenCode: "campaign_01",
    controlCode: {
      newCampaign: "new_campaign_01",
      newFolder: "new_folder_01",
    },
    screenURL: "/settings/campaign",
  },
  // done
  Sequences: {
    screenCode: "sequences_01",
    controlCode: {
      add: "add_sequences_01",
      edit: "edit_sequences_01",
      clone: "clone_sequences_01",
      delete: "delete_sequences_01",
    },
    screenURL: "/settings/sequence",
  },
  // done
  callerIds: {
    screenCode: "caller_ids_01",
    controlCode: {
      add: "add_caller_ids_01",
      activatedeactivate: "activate_deactivate_caller_ids_01",
      delete: "delete_caller_ids_01",
    },
    screenURL: "/settings/caller-ids",
  },
  // done
  virtualNumber: {
    screenCode: "virtual_number_01",
    controlCode: {
      assignNumber: "assign_number_to_user_01",
      buyNumber: "buy_phone_number_01",
      delete: "delete_virtual_number_01",
    },
    screenURL: "/settings/virtual-number",
  },
  // Done
  LeadSourceList: {
    screenCode: "lead_source_01",
    controlCode: {
      addNewLeadSource: "add_lead_source_list_01",
      delete: "delete_lead_source_list_01",
      edit: "edit_lead_source_list_01",
    },
    screenURL: "/settings/lead-source",
  },
  // done
  LeadStatusList: {
    screenCode: "lead_status_list_01",
    controlCode: {
      addNewLeadStatus: "add_lead_status_list_01",
      delete: "delete_lead_status_list_01",
      edit: "edit_lead_status_list_01",
    },
    screenURL: "/settings/lead-status",
  },
  // done
  DispositionStatuses: {
    screenCode: "disposition_statuses_01",
    controlCode: {
      create: "create_01",
      delete: "delete_01",
      edit: "edit_01",
    },
    screenURL: "/settings/global-disposition",
  },
  // done
  TimeSlot: {
    screenCode: "campaign_time_slot_01",
    controlCode: {
      add: "add_01",
      delete: "delete_01",
      edit: "edit_01",
      disable: "disable_01",
    },
    screenURL: "/settings/campaign-time-slot",
  },
  // Done
  Attachments: {
    screenCode: "attachments_01",
    controlCode: {
      addFolder: "add_01",
      // delete: "delete_01",
      // edit: "edit_01",
    },
    screenURL: "/settings/attachments",
  },
  // done
  TagList: {
    screenCode: "tag_list_01",
    controlCode: {
      addNewTag: "add_new_tag_01",
      delete: "delete_01",
      edit: "edit_01",
    },
    screenURL: "/settings/attachments",
  },
  // Done
  CallCampaign: {
    screenCode: "call_campaign_01",
    controlCode: {
      Create: "create_01", // done
      delete: "delete_01", // pending
      edit: "edit_01", // pending
      autoDial: "auto_dial_01", // done
      redirect: "redirect_01",
    },
    screenURL: "/call-campaign",
  },
  // done
  Assign_AS: {
    screenCode: "assign_as_01",
    controlCode: {
      // searchContact: "search_contact_01",
      assign: "assign__as_01",
      actionButton: "un_assign_as_01",
    },
    screenURL: "/assign-as",
  },
  // Done
  QualityControl: {
    screenCode: "quality_control_01",
    controlCode: {
      saveButton: "assine_as_01",
      // assign: "assign_01",
    },
    screenURL: "/qualitycontrol",
  },
  // Done
  CalendarActivityTable: {
    screenCode: "calendar_activity_table_01",
    controlCode: {
      addNewActivity: "add_new_activity_01",
      generateReport: "generate_report_01",
      delete: "delete_01",
      edit: "edit_01",
    },
    screenURL: "/calendar",
  },
  // Done
  CallResult: {
    screenCode: "call_result_01",
    controlCode: {
      Add: "add_01",
      edit: "edit_01",
      delete: "delete_01",
    },
    screenURL: "/settings/call-results",
  },
  // Done
  CallSuggestions: {
    screenCode: "call_suggestions_01",
    controlCode: {
      Add: "add_01",
      delete: "delete_01",
      edit: "edit_01",
      info: "info_01",
    },
    screenURL: "/settings/trigger",
  },
  // Done
  LeadGroup: {
    screenCode: "lead_group_01",
    controlCode: {
      Add: "add_01",
      delete: "delete_01",
      edit: "edit_01",
    },
    screenURL: "/settings/lead-group",
  },
  // Done
  LeadPipeline: {
    screenCode: "lead_pipeline_01",
    controlCode: {
      Add: "add_01",
      delete: "delete_01",
      edit: "edit_01",
    },
    screenURL: "/settings/pipelines",
  },
  // Done
  Dnc: {
    screenCode: "dnc_01",
    controlCode: {
      Add: "add_01",
      delete: "delete_01",
      dncFor: "dncFor_01", // done
    },
    screenURL: "/settings/dnd",
  },
  // Done
  reports: {
    screenCode: "reports_01",
    controlCode: {
      contactReports: "contact_reports_01",
      agentsReports: "agents_reports_01",
      campaignReports: "campaign_reports_01",
      salesReports: "sales_reports_01",
      timeStatusReports: "time_status_reports_01",
    },
    screenURL: "/reports",
  },
  // Done (out of scope)
  AddDeal: {
    screenCode: "add_deal_01",
    controlCode: {
      addMain: "add_main_01",
      createNew: "create_new_01",
      addIcon: "add_icon_01",
    },
    screenURL: "/deals",
  },
  // Done
  Chats: {
    screenCode: "chats_01",
    controlCode: {
      newGroup: "new_group_01",
      startChat: "start_chat_01",
    },
    screenURL: "/inbox",
  },
  // Done
  agentsReports: {
    screenCode: "agents_reports_01",
    controlCode: {
      downloadReports: "download_reports_01",
    },
    screenURL: "/reports/agent-report",
  },
  // done
  campaignReports: {
    screenCode: "campaign_reports_01",
    controlCode: {
      generateReports: "generate_reports_01",
    },
    screenURL: "/reports/campaign-report",
  },
  // Done
  salesReports: {
    screenCode: "sales_reports_01",
    controlCode: {
      downloadReports: "download_reports_01",
    },
    screenURL: "/reports/sales-performance-report",
  },
  // Done
  timeStatusReports: {
    screenCode: "time_status_reports_01",
    controlCode: {
      downloadReports: "download_reports_01",
    },
    screenURL: "/reports/time-status-report",
  },
  // Done
  CustomFields: {
    screenCode: "custom_fields_01",
    controlCode: {},
    screenURL: "/settings/custom-fields",
  },
  // Done
  Templates: {
    screenCode: "templates_01",
    controlCode: {
      SMSSave: "sma_save_01",
      SMSDelete: "sms_delete_01",
      emailSave: "email_save_01",
      emailDelete: "email_delete_01",
      agreementsSave: "agreements_save_01",
      agreementsDelete: "agreements_delete_01",
      videoSave: "video_save_01",
      videoDelete: "video_delete_01",
      voiceSave: "voice_save_01",
      voiceDelete: "voice_delete_01",

      // userForm
      userFormSave: "user_form_save_01",
      userFormDelete: "user_form_delete_01",
    },
    screenURL: "/settings/custom-fields",
  },
  // Done
  Reconciliation: {
    screenCode: "reconciliation_01",
    controlCode: {
      merge: "marge_01",
    },
    screenURL: "/settings/reconciliation",
  },
  CallingScript: {
    screenCode: "calling-script_01",
    controlCode: {
      // addNewCallingScript: "add-new-calling-script_01",
      // delete: "delete_01",
    },
    screenURL: "/settings/calling-script",
  },

  EmailConfiguration: {
    screenCode: "email-configuration_01",
    controlCode: {
      delete: "delete_01",
    },
    screenURL: "/settings/email-configuration",
  },
  // Done
  DashBoard: {
    screenCode: "dashboard",
    controlCode: {
      asAccordionDashboard: "as_accordion_dashboard_01_not_in_use",
      onlineAppointmentSetter: "online_AS_01_not_in_use", // done
      onlineSalesAgents: "online_sales_agents_01_not_in_use", // done
      //
      salesAccordionDashboard: "sales_accordion_dashboard_01",
      statisticsBox: "statistics_box_01",
      salesPerformance: "sales_performance_01",
      campaignPerformance: "campaign_performance_01",
      superAdminAccordionDashboard: "super_admin_accordion_dashboard_01",
      adminAccordionDashboard: "admin_accordion_dashboard_01",
      listPerformance: "list_performance_01",
      minimizedChat: "minimized_chat_01",
    },
    screenURL: "/",
  },
  // Done
  asCalender: {
    screenCode: "as_calendar_01",
    controlCode: {},
    screenURL: "/as-calendar",
  },
  // inprogress
  EmailClient: {
    screenCode: "email_client_01",
    controlCode: {},
    screenURL:
      "https://www.contactaholic.com:4100/a9435883-6930-4973-8b7e-ae31207e5b9a",
  },

  // Done
  AllocatedAS: {
    screenCode: "allocated_as_01",
    controlCode: {},
    screenURL: "/as-calendar",
  },
  // Done
  ASACtivity: {
    screenCode: "as_activity_01-as_01",
    controlCode: {},
    screenURL: "/as-calendar",
  },
  // Done
  contacts: {
    screenCode: "contacts_01",
    controlCode: {
      addContact: "add_contact_01",
      assign: "assign_01",
      uploadFile: "upload_file_01",
      generateReport: "generate_report_01",
      userInfo: "user_info_01",
      delete: "delete_01",
      selectLeadOwner: "select_lead_owner_01",
      filterByLead: "filter_by_lead_01",
      action: "action_01",
    },
    screenURL: AppRoutes.CONTACTS,
  },
  ContactImportList: {
    screenCode: "contact_import_list_01",
    controlCode: {
      Create: "create_01", // done
      delete: "delete_01", // pending
      edit: "edit_01", // pending
      redirect: "redirect_01",
    },
    screenURL: "/contact-import-list",
  },

  ApprovedCustomers: {
    screenCode: "approved_customers_01",
    controlCode: {
      // addContact: "add_contact_01",
      // assign: "assign_01",
    },
    screenURL: "/approved-customers",
  },
  // Done
  payments: {
    screenCode: "payment_history_01",
    controlCode: {
      resend: "resend_01",
      invoice: "Invoice_01",
    },
    screenURL: "/payment-history",
  },
  invoicePayments: {
    screenCode: "payment_invoice_01",
    controlCode: {
      resend: "resend_01",
      invoice: "Invoice_01",
    },
    screenURL: "/payment-history",
  },
  paymentsMethod: {
    screenCode: "payment_method_01",
    controlCode: {
      add: "add_01",
      status: "status_01",
      edit: "edit_01",
      delete: "delete_01",
    },
    screenURL: "/payment-method",
  },
  powerDial: {
    screenCode: "power_dial_01",
    controlCode: {
      add: "  ",
      status: "status_01",
      edit: "edit_01",
      delete: "delete_01",
    },
    screenURL: "/payment-method",
  },
  contactDetails: {
    screenCode: "contact_details_01",
    controCode: {
      leadOwner: "lead_owner_01",
      leadSource: "lead_source_01",
      contract: "contract_01",
      deals: "deals_01",
      appointment: "appointment_01",
      task: "task_01",
      call: "call_01",
      campaigns: "campaigns_01",
      formEntries: "form_entries_01",
      agreements: "agreements_01",
      userForm: "user_form_01",
      paymentHistory: "payment_history_01",
      paymentInvoice: "payment_invoice_01",
      savedCards: "saved_cards_01",
      notes: "notes_01",
      activities: "activities_01",
      files: "files_01",
      log: "log_01",
      updateOwner: "update_owner_01", // done
      assignedHistory: "assigned_history_01",
      appointmentSetter: "appointment_setter_01",
    },
    screenURL: `${AppRoutes.CONTACTS_DETAILS}/id`,
  },
};

const UserPermissionConfig = {
  recording: "is_recording_01",
  transcription: "is_transcription_01",
  email: "is_email_01",
  sms: "is_sms_01",
  call: "is_call_01",
  mic: "is_mic_01",
  videoMail: "is_video_mail_01",
  callHistory: "call_history_01",
  emailHistory: "email_history_01",
  smsHistory: "sms_history_01",
  suggestion: "suggestion_01",
  startChat: "is_start_chat_01",
  isClientRecording: "is_clientRecording_01",
  viewcustomFieldsContactDetail: "view_custom_field_01",
  downloadLog: "call_logs_download_01",
};

export { PermissionConfig, RoleConstants, UserPermissionConfig };

