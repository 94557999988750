import { Box, Stack, Typography } from "@mui/material";
import { SocketContext } from "SocketContex";
import { ContentLoader } from "components/content-loader";
import useDecodedData from "hooks/useDecodedData";
import { debounce } from "lodash";
import {
  CHATROOM_PAGE_LIMIT,
  IChatRoom,
} from "pages/user/inbox/query/useFetchChatRoom";
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { useParams } from "react-router-dom";
import { useChatsActions } from "redux/chats/chats";
import ChatRoomListItem from "../chat-room-list-item";

interface IProps {
  data: IChatRoom[];
  chatRoomList: IChatRoom[];
  isChatRoomLoading: boolean;
  isFetchingNextRoom: boolean;
  fetchNextRoom: any;
  hasNextRoom: any;
  isFetchingPreviousRoom: boolean;
  isRefetchingRoom: boolean;
  newChat?: any;
  setNewChat?: (data: any) => void;
  handleChat?: (event: any, roomId: number, room: IChatRoom) => void;
  handleContactClick: (id: number) => void;
}

function ChatRoomList(props: IProps) {
  const {
    isChatRoomLoading,
    data,
    hasNextRoom,
    isFetchingNextRoom,
    fetchNextRoom,
    isFetchingPreviousRoom,
    isRefetchingRoom,
    newChat,
    setNewChat,
    chatRoomList,
    handleChat,
    handleContactClick,
  } = props;
  const [selectedRoomId, setSelectedRoomId] = useState(0);

  const params = useParams();
  const decoded = useDecodedData();
  const [chatNotFound, setChatNotFound] = useState<boolean>(false);
  const miniContainerRef = useRef<HTMLDivElement | null>(null);
  const { setSelectedChatRoom } = useChatsActions();

  // const queryClient = useQueryClient();
  const { chatRooms, setChatRooms, onlineUserList } = useContext(SocketContext);

  const debouncedFetchRoom = debounce(() => {
    fetchNextRoom();
  }, 300);

  useEffect(() => {
    if (params.id && data) {
      const roomId = Number(params.id) || 0;
      const room = data.find((x: any) => x.id === roomId);

      setSelectedRoomId(roomId);
      if (room) {
        setSelectedChatRoom({
          selectedChatRoom: {
            ...room,
            toUser:
              Number(decoded?.id) === room.toUser ? room.fromUser : room.toUser,
            toUserName:
              Number(decoded?.id) === room.toUser
                ? room.fromUserName
                : room.toUserName,
            toProfileUrl:
              Number(decoded?.id) === room.toUser
                ? room.fromProfileUrl
                : room.toProfileUrl,
            fromProfileUrl:
              Number(decoded?.id) === room.toUser
                ? room.toProfileUrl
                : room.fromProfileUrl,
          },
        });
      }
    }
  }, [data, params.id]);

  const observer = useRef<IntersectionObserver | null>(null);

  const lastMiniChatRoomElementRef = useCallback(
    (node: HTMLElement | null) => {
      if (
        isFetchingNextRoom ||
        isChatRoomLoading ||
        isFetchingPreviousRoom ||
        isRefetchingRoom
      )
        return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(
        ([entry]) => {
          if (
            entry.isIntersecting &&
            hasNextRoom &&
            chatRoomList.length > CHATROOM_PAGE_LIMIT - 2
          ) {
            debouncedFetchRoom();
          }
        },
        // {
        //   root: miniContainerRef.current, // Access the current property here
        //   rootMargin: "10px",
        //   threshold: 1.0, // Adjust threshold as necessary
        // },
      );

      if (node) observer.current.observe(node);
    },
    [
      isFetchingNextRoom,
      hasNextRoom,
      isFetchingPreviousRoom,
      isRefetchingRoom,
      chatRoomList,
    ],
  );

  useLayoutEffect(() => {
    if (chatRoomList) {
      setChatNotFound(chatRoomList.length === 0);
      if (chatRoomList.length !== chatRooms.length) {
        const list = chatRoomList;
        const sortedList = list?.sort((a: any, b: any) => {
          if (a.isPinned !== b.isPinned) {
            return b.isPinned - a.isPinned;
          }

          return b.lastMessageOn - a.lastMessageOn;
        });
        setChatRooms(sortedList);
      }
    }
  }, [chatRoomList]);

  useEffect(() => {
    if (newChat) {
      const newChats = chatRooms
        ?.map((item) => {
          if (item.id === newChat?.roomId)
            return {
              ...item,
              message:
                newChat.fileUrls && newChat?.fileUrls.length > 0
                  ? `Images/${newChat.extension}`
                  : newChat.message,
              extension: newChat.extension,
              lastMessageOn: newChat?.timeStamp,
              lastMessageUserId: newChat.lastMessageUserId,
              lastMessageUserName: newChat.lastMessageUserName,
            };
          return item;
        })
        ?.sort((a: any, b: any) => {
          if (a.isPinned !== b.isPinned) {
            return b.isPinned - a.isPinned;
          }

          return b.lastMessageOn - a.lastMessageOn;
        });
      setChatRooms(newChats);
      setNewChat?.(null);
    }
  }, [newChat]);

  return (
    <PerfectScrollbar>
      <Stack ref={miniContainerRef} padding="10px 10px 20px 0px">
        {isChatRoomLoading || (chatRoomList?.length === 0 && !chatNotFound) ? (
          <Box
            sx={{
              alignItems: "center",
              height: "100%",
            }}
          >
            <ContentLoader type="ChatRoom" />
          </Box>
        ) : chatRooms && chatRooms?.length > 0 ? (
          chatRooms?.map((item, index) => {
            const onlineUserId =
              Number(decoded?.id) === item.toUser ? item.fromUser : item.toUser;
            const isOnline = onlineUserList?.includes(onlineUserId);

            if (data.length === index + 1) {
              return (
                <div
                  ref={lastMiniChatRoomElementRef}
                  className="last-chat-room"
                >
                  <ChatRoomListItem
                    handleChat={handleChat}
                    handleContactClick={handleContactClick}
                    isOnline={isOnline}
                    item={item}
                    selectedRoomId={selectedRoomId}
                  />
                </div>
              );
            }

            return (
              <div>
                <ChatRoomListItem
                  handleChat={handleChat}
                  handleContactClick={handleContactClick}
                  isOnline={isOnline}
                  item={item}
                  selectedRoomId={selectedRoomId}
                />
              </div>
            );
          })
        ) : (
          <Box
            sx={{
              // height: "75vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: "#133159",
                fontWeight: "600",
                paddingTop: "1rem",
              }}
            >
              Chats Not Found. Start chatting
            </Typography>
          </Box>
        )}
      </Stack>
    </PerfectScrollbar>
  );
}

export default React.memo(ChatRoomList);
