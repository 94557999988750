/**
 * @format
 */
import useDecodedData from "hooks/useDecodedData";
import { useMemo } from "react";
import { useInfiniteQuery } from "react-query";
import { getAllChats } from "services/chat.services";
import { timeStampToTime } from "utils";
import { QueryKeys } from "utils/QueryKeys";
import { IChatList } from "../component/chats/hooks/useCachedChats";

export interface IChat {
  id: number;
  uuId: string;
  groupId: number;
  groupName: string;
  userId: number;
  toUser: number;
  fromUser: number;
  toUserName: string;
  fromUserName: string;
  lastMessageOn: string;
  type: string;
  unReadMessagesCount: number;
  fileUrls?: string[];
  status: string;
  isRead: boolean;
  readLater: boolean;
  message: string;
  timeStamp: string;
  createdOn: string;
  updatedOn: string;
  quoteMessage: QuoteMessage;
  data: any;
  chatReadDetails: ChatReadDetail[];
  fromProfileUrl: string;
  toProfileUrl: string;
  userName?: string;
  userProfileUrl?: string;
  readDateTime?: string;
  chatStatus?: string;
  extension?: string;
  roomId?: number;
  isMentioned: boolean;
}
export interface ChatReadDetail {
  userName: string;
  readDateTime: string;
  userProfileUrl: string;
}
export interface QuoteMessage {
  quoteMessageId: number;
  message: string;
  toUser: number;
  fromUser: number;
  toUserName: string;
  fromUserName: string;
  fileUrls: string[];
}

export interface IResponseChats {
  message: string;
  data: IChat[];
  totalDocs: number;
  limit: number;
  statusCode: number;
  pageNo: number;
}

export async function getAllChatsPagination(
  userId: number,
  ChatRoomId: number,
  pageNo: number,
  pageLimit?: number,
): Promise<any> {
  try {
    const response: any = await getAllChats(
      userId,
      ChatRoomId,
      pageNo,
      pageLimit,
    );
    if (response.statusCode === 200) {
      return response;
    }
    return {
      data: [],
      statusCode: 404,
      totalDocs: 0,
      limit: 0,
      message: "",
      pageNo,
    };
  } catch (error) {
    return Promise.reject(error);
  }
}
export const CHAT_PAGE_LIMIT = 35;

const useFetchChats = (ChatRoomId: number, pageNumber = 1, enabled = true) => {
  const decode = useDecodedData();
  const cacheKey = [QueryKeys.allChats, ChatRoomId];

  const {
    data,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
    fetchPreviousPage,
    hasPreviousPage,
    isFetchingPreviousPage,
    isLoading,
    isSuccess,
    isFetchedAfterMount,
    isRefetching,
  } = useInfiniteQuery(
    cacheKey,
    (params) => {
      const { pageParam = pageNumber } = params;

      return getAllChatsPagination(
        Number(decode?.id),
        ChatRoomId,
        pageParam,
        CHAT_PAGE_LIMIT,
      );
    },

    {
      enabled,
      keepPreviousData: true,
      // cacheTime: 1000 * 60 * 1,
      // onSuccess: async (data) => {
      //   console.log("data", data);

      //   await getAllChatRoomPagination(Number(decode?.id), 1, 10, "");
      //   await getUnreadCount(Number(decode.id));
      //   // queryClient.invalidateQueries([QueryKeys.allChatRoomPagination]);
      //   // queryClient.invalidateQueries([QueryKeys.unreadCount]);
      // },
      getNextPageParam: (lastPage) => {
        if (lastPage && lastPage?.data?.length > 0) {
          const nextPage = lastPage.pageNo + 1;
          return nextPage;
        }
        return undefined;
      },
      getPreviousPageParam: (firstPage) => {
        const previousPage = firstPage?.pageNo > 1 ? firstPage.pageNo - 1 : 1;
        if (firstPage && firstPage?.data?.length > 0) {
          return previousPage;
        }
        return undefined;
      },
    },
  );

  const chatList: IChat[] = useMemo(() => {
    if (!data) return [];

    const allData = data?.pages?.flatMap((page: any) => page.data);
    return Array.from(new Set(allData));
  }, [data]);
  const groupedChats = useMemo(() => {
    if (!chatList) return {};
    // const sortedChatList = chatList.sort((a: any, b: any) => {
    //   return b.timeStamp - a.timeStamp;
    // });
    return chatList.reduce<{ [date: string]: IChat[] }>(
      (messageGroup, message) => {
        const messageData = messageGroup;
        const stringDate = timeStampToTime({
          utcDate: Number(message?.timeStamp),
          hasDateOnly: true,
        });

        // Check if the message for this date already exists in the messageGroup
        if (!messageData[stringDate]) {
          messageData[stringDate] = [];
        }

        // Check if the message with the same content exists for this date
        const existingMessage = messageData[stringDate].find(
          (msg) => msg.id === message.id && msg.uuId === message.uuId,
        );
        // Add the message to the messageData only if it doesn't already exist
        if (!existingMessage) {
          messageData[stringDate].push(message);
        }
        return messageData;
      },
      {},
    );
  }, [chatList]);

  const getUpdatedChats = () => {
    const tempArr: IChatList[] = [];
    if (!chatList || !groupedChats) return [];

    Object.entries(groupedChats)?.map((item) => {
      return tempArr.push({ messageDate: item[0], data: item[1] });
    });

    return tempArr;
  };
  const updatedChats = getUpdatedChats();
  return {
    chatList,
    refetch,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage: (page?: number) =>
      page ? fetchNextPage({ pageParam: page }) : fetchNextPage(),
    fetchPreviousPage: (page?: number) =>
      page ? fetchPreviousPage({ pageParam: page }) : fetchPreviousPage(),
    updatedChats,
    isSuccess,
    isFetchedAfterMount,
    isRefetching,
    hasPreviousPage,
    isFetchingPreviousPage,
  };
};

export { useFetchChats };

