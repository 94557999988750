import { Box, TextField } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useCallNoteSliceActions } from "redux/call-note/callNote";
import { RootState } from "redux/store";

function CalllogsSection() {
  const { setCallNote } = useCallNoteSliceActions();
  const globalNote = useSelector((state: RootState) => state.callNote.note);
  const [note, setNote] = useState(globalNote);
  // const callManagerData = useCallManager();

  const handleNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newNote = event.target.value;
    setNote(newNote);
    setCallNote({ note: newNote });
  };

  return (
    <Box>
      <TextField
        multiline
        defaultValue="Call Note..."
        id="outlined-multiline-static"
        label="Call Note"
        rows={3}
        sx={{ width: "600px" }}
        value={note}
        onChange={handleNoteChange}
        // onChange={(event) => {
        //   setNote(event.target.value);
        // }}
      />
    </Box>
  );
}
export default CalllogsSection;
