/**
 * @format
 */
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import { ITemplatesRequest } from "../query/useApiAction";

const defaultValues: ITemplatesRequest = {
  type: "",
  subject: "",
  templateText: "",
  client: 0,
  customField: "",
  templateUsers: [0],
};

const useUserForm = (
  onSubmit: (
    values: ITemplatesRequest,
    formikHelpers: FormikHelpers<ITemplatesRequest>,
  ) => void | Promise<unknown>,
  initialValues: ITemplatesRequest = defaultValues,
) => {
  let validation: any = null;

  validation = {
    // templateText: Yup.string().required(ErrorMessages.templates.templatesText),
    // subject: Yup.string().required(ErrorMessages.templates.subject),
    // title: Yup.string().required(ErrorMessages.templates.subject),
  };

  const schema = Yup.object().shape(validation);
  return useFormik<ITemplatesRequest>({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit,
  });
};

export default useUserForm;
