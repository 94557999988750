/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { useAppDispatch } from "redux/store";

export interface IChatJoin {
  id: number;
  companyId: number;
  roleId: number;
  contactId: number;
  contactName: any;
  roleName: string;
  fullName: string;
  address: string;
  email: string;
  password: string;
  mobileNumber: string;
  fcmToken: string;
  status: number;
  createdOn: string;
  updatedOn: string;
  connectionId: any;
  signalRConnectionId: string;
}

export interface IChatUsersListReducer {
  connectionId?: string | IChatJoin | null;
  userIdList?: IChatJoin[] | null;
  callDuration?: number;
  toggleSearch?: boolean;
}

const initialState: IChatUsersListReducer = {
  connectionId: null,
  userIdList: null,
  callDuration: 0,
  toggleSearch: false,
};

interface IPayload {
  connectionId?: string | null | IChatJoin;
  userIdList?: IChatJoin[] | null;
  callDuration?: number;
  toggleSearch?: boolean;
}

interface IAction {
  payload: IPayload;
  type: string;
}

const chatJoinSlice = createSlice({
  name: "chatCallList",
  initialState,
  reducers: {
    setToggleSearch: (state, action: IAction) => {
      state.toggleSearch = action.payload.toggleSearch;
    },
    setUserOnline: (state, action: IAction) => {
      state.connectionId = action.payload.connectionId;
    },
    setUserJoinList: (state, action: IAction) => {
      state.userIdList = action.payload.userIdList;
    },
    setCallDuration: (state) => {
      if (state.callDuration !== undefined) {
        state.callDuration += 1;
      }
    },
    resetCallDuration: (state) => {
      if (state.callDuration) {
        state.callDuration = 0;
      }
    },
  },
});

export const {
  setUserOnline,
  setUserJoinList,
  setCallDuration,
  resetCallDuration,
  setToggleSearch,
} = chatJoinSlice.actions;

export const useChatUsersActions = () => {
  const dispatch = useAppDispatch();
  return {
    setToggleSearch: (payload: IPayload) => dispatch(setToggleSearch(payload)),
    setUserOnline: (payload: IPayload) => dispatch(setUserOnline(payload)),
    setUserJoinList: (payload: IPayload) => dispatch(setUserJoinList(payload)),
    setCallDuration: () => dispatch(setCallDuration()),
    resetCallDuration: () => dispatch(resetCallDuration()),
  };
};
const { reducer } = chatJoinSlice;

export default reducer;
