// /src/components/GlobalQuerySync.tsx
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function GlobalQuerySync() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const currentParams = new URLSearchParams(location.search);

    // Ensure query parameters persist on page changes
    const handleNavigation = () => {
      const newParams = new URLSearchParams(location.search);
      let modified = false;
      console.log({ newParams });

      // Add current parameters to the new location if missing
      currentParams.forEach((value, key) => {
        if (!newParams.has(key)) {
          newParams.set(key, value);
          modified = true;
        }
      });

      if (modified) {
        navigate(`${location.pathname}?${newParams.toString()}`, {
          replace: true,
        });
      }
    };

    // Listen to navigation events
    window.addEventListener("popstate", handleNavigation);
    return () => window.removeEventListener("popstate", handleNavigation);
  }, [location, navigate]);

  return null;
}

export default React.memo(GlobalQuerySync);
