import {
  Box,
  LinearProgress,
  LinearProgressProps,
  Typography,
} from "@mui/material";

interface IProps extends LinearProgressProps {
  value: number;
}

function ProgressBar(props: IProps) {
  const { value } = props;
  return (
    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
      <Box sx={{ minWidth: 35, width: "110px" }}>
        <Typography
          color="primary"
          sx={{ fontSize: "11px" }}
          variant="body2"
        >{`Sending... (${value}%)`}</Typography>
      </Box>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress value={value} variant="determinate" />
      </Box>
    </Box>
  );
}

export default ProgressBar;
