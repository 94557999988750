import { Box } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

type Props = any;

function AllActivityLoader(props: Props) {
  return (
    <Box {...props}>
      <Box sx={{ display: "flex", alignItems: "center", marginTop: 5 }}>
        <Box sx={{ width: "50%" }}>
          <Skeleton
            animation="wave"
            height={25}
            sx={{ borderRadius: 0.5 }}
            width={20}
          />
        </Box>
        <Box sx={{ margin: 1, width: "100%", paddingRight: 5 }}>
          <Skeleton
            animation="wave"
            height={25}
            sx={{ borderRadius: 0.5 }}
            width="85%"
          />
          <Skeleton
            animation="wave"
            height={25}
            sx={{ borderRadius: 0.5 }}
            width="100%"
          />
        </Box>
        <Box sx={{ paddingRight: 5, display: "flex", width: "100%" }}>
          <Skeleton
            animation="wave"
            height={25}
            sx={{ borderRadius: 0.5 }}
            width="85%"
          />
          <Skeleton
            animation="wave"
            height={25}
            sx={{ borderRadius: 0.5 }}
            width="100%"
          />
        </Box>
        <Box sx={{ paddingRight: 5, margin: 1, width: "100%" }}>
          <Skeleton
            animation="wave"
            height={25}
            sx={{ borderRadius: 0.5 }}
            width="80%"
          />
          <Skeleton
            animation="wave"
            height={25}
            sx={{ borderRadius: 0.5 }}
            width="100%"
          />
        </Box>

        <Box sx={{ paddingRight: 5, margin: 1, width: "100%" }}>
          <Skeleton
            animation="wave"
            height={25}
            sx={{ borderRadius: 0.5 }}
            width="80%"
          />
          <Skeleton
            animation="wave"
            height={25}
            sx={{ borderRadius: 0.5 }}
            width="100%"
          />
        </Box>
        <Box sx={{ margin: 1, width: "100%" }}>
          <Skeleton
            animation="wave"
            height={25}
            sx={{ borderRadius: 0.5 }}
            width="80%"
          />
          <Skeleton
            animation="wave"
            height={25}
            sx={{ borderRadius: 0.5 }}
            width="100%"
          />
        </Box>
      </Box>
    </Box>
  );
}

export { AllActivityLoader };
