/* eslint-disable jsx-a11y/media-has-caption */
// src/VideoPlayer.tsx

import { useRef } from "react";

interface IProps {
  url: string;
  type?: string;
  style?: any;
}

function VideoPlayer(props: IProps) {
  const { type, url, style } = props;
  const videoRef = useRef<HTMLVideoElement>(null);
  // const [isPlaying, setIsPlaying] = useState(false);

  // const toggleVideo = () => {
  //   if (videoRef.current) {
  //     if (isPlaying) {
  //       videoRef.current.pause();
  //     } else {
  //       videoRef.current.play();
  //     }
  //     setIsPlaying(!isPlaying);
  //   }
  // };

  return (
    <div>
      <video
        ref={videoRef}
        controls
        height="180"
        style={{ maxHeight: "300", ...style }}
        width="640"
        {...props}
      >
        <source src={url} type={type || "video/mp4"} />
      </video>
      {/* <div>
        <Button type="button" onClick={toggleVideo}>
          {isPlaying ? "Pause" : "Play"}
        </Button>
      </div> */}
    </div>
  );
}

export default VideoPlayer;
