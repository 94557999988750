/* eslint-disable @typescript-eslint/no-unused-vars */

/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { IChatRoom } from "pages/user/inbox/query/useFetchChatRoom";
import { useAppDispatch } from "redux/store";

export interface IMinimizedChatReducer {
  chatRoomId?: number | null;
  selectedChatRoom?: IChatRoom | null;
  toggleChatList?: boolean;
  toggleChatRoom?: boolean;
}

const initialState: IMinimizedChatReducer = {
  chatRoomId: null,
  selectedChatRoom: null,
  toggleChatList: false,
  toggleChatRoom: false,
};

interface IPayload {
  chatRoomId?: number | null;
  selectedChatRoom?: IChatRoom | null;
  toggleChatList?: boolean;
  toggleChatRoom?: boolean;
}

interface IAction {
  payload: IPayload;
  type: string;
}
const minimizedChatSlice = createSlice({
  name: "minimizedChat",
  initialState,
  reducers: {
    setChatRoomId: (state, action: IAction) => {
      state.chatRoomId = action?.payload?.chatRoomId;
    },
    setSelectedChatRoom: (state, action: IAction) => {
      state.selectedChatRoom = action?.payload?.selectedChatRoom;
    },
    setToggleChatRoom: (state, action: IAction) => {
      state.toggleChatRoom = action?.payload?.toggleChatRoom;
    },
    setToggleChatList: (state, action: IAction) => {
      state.toggleChatList = action?.payload?.toggleChatList;
    },
  },
});

export const {
  setChatRoomId,
  setSelectedChatRoom,
  setToggleChatRoom,
  setToggleChatList,
} = minimizedChatSlice.actions;

export const useMinimizedChatActions = () => {
  const dispatch = useAppDispatch();
  return {
    setChatRoomId: (payload: IPayload) => dispatch(setChatRoomId(payload)),
    setToggleChatRoom: (payload: IPayload) =>
      dispatch(setToggleChatRoom(payload)),
    setToggleChatList: (payload: IPayload) =>
      dispatch(setToggleChatList(payload)),
    setSelectedChatRoom: (payload: IPayload) =>
      dispatch(setSelectedChatRoom(payload)),
  };
};
const { reducer } = minimizedChatSlice;

export default reducer;
