import { PER_PAGE } from "constants/constants";
import { IResponse } from "constants/interfaces";
import { IDispositionRequest } from "pages/admin/settings/screens/global-disposition/hooks/useAddDispositionForm";
import { IResponseDisposition } from "pages/admin/settings/screens/global-disposition/query/useFetchDisposition";
import client from "utils/ApiClient";
import API_URLS from "./endPoints";

export async function saveDisposition(
  body: IDispositionRequest,
): Promise<IResponse> {
  const url = `${API_URLS.ADD_DISPOSITION}`;
  return client.post(url, body);
}
async function updateDispositionSetting(
  body: IDispositionRequest,
): Promise<IResponse> {
  return client.put(API_URLS.UPDATE_DISPOSITION_SETTING, body);
}

async function removeDisposition(Id: number): Promise<IResponse> {
  return client.delete(`${API_URLS.DELETE_DISPOSITION}/${Id}`);
}

export async function fetchDisposition(
  callCampaignID: number,
  userId: number,
  pageNo: number,
  pageLimit: number,
  searchKeyword = "",
): Promise<IResponseDisposition> {
  let url = `${API_URLS.FETCH_ALL_DISPOSITION}?userId=${userId}&page=${
    pageNo + 1
  }&pageSize=${pageLimit || PER_PAGE}&campaignId=${callCampaignID}`;
  if (searchKeyword) {
    url = `${url}&searchKeyword=${searchKeyword}`;
  }
  return client.get(url);
}

export { removeDisposition, updateDispositionSetting };
