/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { useAppDispatch } from "redux/store";

export interface ICallManagerReducer {
  token?: string;
  callStatus?: string;
  message?: any;
  identity?: string;
  suggestions?: any;
  dialer?: boolean;
  toNumbers?: any[];
  device: any;
  callInfo: any;
  callType: string;
  callInvite: string;
  endCallFromAdmin?: boolean;
  callRequest?: any;
  toId?: number;
  toType?: string;
  callerIds?: any[];
  fullName?: any;
}

const initialState: ICallManagerReducer = {
  token: "",
  identity: "",
  callStatus: "",
  message: [],
  suggestions: [],
  dialer: false,
  toNumbers: [],
  device: null,
  callInfo: null,
  callType: "",
  callInvite: "",
  endCallFromAdmin: false,
  callRequest: {},
  toId: 0,
  toType: "",
  callerIds: [],
  fullName: "",
};

interface IPayload {
  callStatus?: string | undefined;
  token?: any;
  message?: any;
  identity?: string;
  suggestions?: any;
  dialer?: boolean;
  toNumbers?: any[];
  device?: any;
  callInfo?: any;
  callType?: string;
  callInvite?: string;
  endCallFromAdmin?: boolean;
  callRequest?: any;
  toId?: number;
  toType?: string;
  callerIds?: any[];
  fullName?: any;
}

interface IAction {
  payload: IPayload;
  type: string;
}

const activitySlice = createSlice({
  name: "callManager",
  initialState,
  reducers: {
    setCallManagerToken: (state, action: IAction) => {
      state.token = action.payload.token;
      state.identity = action.payload.identity;
    },
    setCallManagerCallStatus: (state, action: IAction) => {
      state.callStatus = action.payload.callStatus;
    },
    setCallManagerCallMessage: (state, action: IAction) => {
      state.message.push(action.payload.message);
    },
    setCallManagerSuggestions: (state, action: IAction) => {
      state.suggestions.push(action.payload.suggestions);
    },
    setCallManagerDialer: (state, action: IAction) => {
      state.dialer = action.payload.dialer;
      state.toNumbers = action.payload.toNumbers;
      state.toId = action.payload.toId;
      state.toType = action.payload.toType;
    },
    setCallManagerDevice: (state, action: IAction) => {
      state.device = action.payload.device;
    },
    logoutCallManager: (state: any) => {
      state.token = "";
      state.callStatus = "";
      state.message = [];
      state.suggestions = [];
      state.dialer = false;
      state.device = null;
    },
    cleanCallManagerMessage: (state: any) => {
      state.message = [];
      state.suggestions = [];
    },
    setCallInfo: (state: any, action: IAction) => {
      state.callInfo = action.payload.callInfo;
    },
    setCallType: (state: any, action: IAction) => {
      state.callType = action.payload.callType;
    },
    setCallInvite: (state: any, action: IAction) => {
      state.callInvite = action.payload.callInvite;
    },
    resetCallInfo: (state: any) => {
      state.callInfo = null;
    },
    resetCallInvite: (state: any) => {
      state.callInvite = null;
    },
    isEndCallFromAdmin: (state: any, action: IAction) => {
      state.endCallFromAdmin = action.payload.endCallFromAdmin;
    },
    setCallRequest: (state: any, action: IAction) => {
      state.callRequest = action.payload.callRequest;
    },
    setCallerIds: (state: any, action: IAction) => {
      state.callerIds = action.payload.callerIds;
    },
  },
});

export const {
  setCallManagerToken,
  setCallManagerCallStatus,
  setCallManagerCallMessage,
  setCallType,
  setCallManagerSuggestions,
  setCallManagerDialer,
  logoutCallManager,
  setCallManagerDevice,
  cleanCallManagerMessage,
  setCallInfo,
  resetCallInfo,
  setCallInvite,
  resetCallInvite,
  setCallRequest,
  isEndCallFromAdmin,
  setCallerIds,
} = activitySlice.actions;

export const useCallManagerActions = () => {
  const dispatch = useAppDispatch();
  return {
    setCallManagerToken: (payload: IPayload) =>
      dispatch(setCallManagerToken(payload)),
    setCallType: (payload: IPayload) => dispatch(setCallType(payload)),
    setCallManagerDevice: (payload: IPayload) =>
      dispatch(setCallManagerDevice(payload)),
    setCallManagerCallStatus: (payload: IPayload) =>
      dispatch(setCallManagerCallStatus(payload)),
    setCallManagerCallMessage: (payload: IPayload) =>
      dispatch(setCallManagerCallMessage(payload)),
    setCallManagerSuggestions: (payload: IPayload) =>
      dispatch(setCallManagerSuggestions(payload)),
    setCallManagerDialer: (payload: IPayload) =>
      dispatch(setCallManagerDialer(payload)),
    setCallInfo: (payload: IPayload) => dispatch(setCallInfo(payload)),
    setCallInvite: (payload: IPayload) => dispatch(setCallInvite(payload)),
    isEndCallFromAdmin: (payload: IPayload) =>
      dispatch(isEndCallFromAdmin(payload)),
    resetCallInfo: () => dispatch(resetCallInfo()),
    resetCallInvite: () => dispatch(resetCallInvite()),
    logoutCallManager: () => dispatch(logoutCallManager()),
    cleanCallManagerMessage: () => dispatch(cleanCallManagerMessage()),
    setCallRequest: (payload: IPayload) => dispatch(setCallRequest(payload)),
    setCallerIds: (payload: IPayload) => dispatch(setCallerIds(payload)),
  };
};
const { reducer } = activitySlice;

export default reducer;
