/* eslint-disable @typescript-eslint/no-unused-vars */
import { PER_PAGE } from "constants/constants";
import { IResponse } from "constants/interfaces";
import {
  IChatMessage,
  ICreateGroupRequest,
} from "pages/user/inbox/query/useApiAction";
import {
  IParamsChatSearch,
  IResponseChatBySearch,
} from "pages/user/inbox/query/useFetchChatBySearch";
import { IResponseChatRooms } from "pages/user/inbox/query/useFetchChatRoom";
import { IResponseChats } from "pages/user/inbox/query/useFetchChats";
import { IResponseChatCounts } from "pages/user/inbox/query/useFetchChatUnreadCount";
import { ChatImgFile } from "pages/user/inbox/query/useFetchGetFile";
import { IResponseReadChatInfo } from "pages/user/inbox/query/useFetchGroupChatinfo";
import { IResponseGroupMembers } from "pages/user/inbox/query/useFetchMembers";
import { IResponseReadLaterGetAll } from "pages/user/inbox/query/useFetchReadLater";
import { IResponseUserById } from "pages/user/inbox/query/useFetchUserById";
import client from "utils/ApiClient";
import API_URLS from "./endPoints";

async function getAllChats(
  userId: number,
  chatRoomId: number,
  pageNo: number,
  pageLimit = 10,
): Promise<IResponseChats> {
  const url = `${API_URLS.GET_ALL_CHATS}?chatroomid=${chatRoomId}&PageSize=${pageLimit}&PageNumber=${pageNo}&UserId=${userId}`;
  return client.get(url);
}
async function getAllReadLaterChats(
  chatRoomId: number,
  pageSize = 20,
): Promise<IResponseReadLaterGetAll> {
  const url = `${API_URLS.GET_ALL_READ_LATER}?roomId=${chatRoomId}&PageSize=${pageSize}`;
  return client.get(url);
}
async function getAllReadChatInfo(
  chatId: number,
): Promise<IResponseReadChatInfo> {
  const url = `${API_URLS.GET_ALL_READ_CHAT_INFO}?chatId=${chatId}`;
  return client.get(url);
}

async function getChatRoomPagination(
  userId: number,
  pageNo: number,
  pageLimit = 10,
  search = "",
): Promise<IResponseChatRooms> {
  let url = `${API_URLS.GET_CHATROOM_PAGINATION}?Page=${pageNo}&PageSize=${
    pageLimit || PER_PAGE
  }&UserId=${userId}`;
  // if (search) {
  url = `${url}&search=${search}`;
  // }

  return client.get(url);
}
async function fetchChatBySearch(
  params: IParamsChatSearch,
): Promise<IResponseChatBySearch> {
  const url = `${API_URLS.GET_CHAT_BY_SEARCH}?message=${params?.search}&roomId=${params?.roomId}&pageSize=${params?.pageSize}`;
  return client.get(url);
}

async function sendChatMessage(
  body: FormData,
  handleUploadProgress?: (progressEvent: any) => void,
  isGroup?: boolean,
): Promise<IResponseChats> {
  const url = isGroup
    ? API_URLS.SEND_MESSAGE_GROUP
    : API_URLS.SEND_MESSAGE_CHAT;
  return client.post(url, body, {
    onUploadProgress: (progressEvent) => {
      handleUploadProgress && handleUploadProgress(progressEvent);
    },
  });
}
async function sendChatMessageGroup(
  body: FormData,
  handleUploadProgress?: (progressEvent: any) => void,
): Promise<IResponseChats> {
  return client.post(API_URLS.SEND_MESSAGE_GROUP, body, {
    onUploadProgress: (progressEvent) => {
      handleUploadProgress && handleUploadProgress(progressEvent);
    },
  });
}

async function updateChatAsRead(chatId: number): Promise<IResponseChats> {
  const url = `${API_URLS.UPDATE_CHAT_READ}?roomId=${chatId}`;
  return client.put(url);
}

async function fetchUnreadCount(userId: number): Promise<IResponseUserById> {
  return client.get(`${API_URLS.GET_UNREAD_COUNT}/${userId}`);
}

async function fetchChatUnreadCount(
  userId: number,
): Promise<IResponseChatCounts> {
  return client.get(`${API_URLS.GET_CHAT_UNREAD_COUNT}/${userId}`);
}

async function fetchMembers(groupId: number): Promise<IResponseGroupMembers> {
  return client.get(`${API_URLS.FETCH_GROUP_MEMBERS}?groupid=${groupId}`);
}
async function unreadMarked(
  messageId: number,
  roomId: number,
): Promise<IResponseChats> {
  return client.put(
    `${API_URLS.MARK_UNREAD}?messageId=${messageId}&chatRoomId=${roomId}`,
  );
}

async function fetchRoomId(
  toUser: number,
  fromUser: number,
): Promise<IResponseChats> {
  return client.get(
    `${API_URLS.GET_ROOM_ID}?fromUser=${fromUser}&toUser=${toUser}`,
  );
}

async function createGroup(
  id: number,
  body: ICreateGroupRequest,
): Promise<IResponse> {
  const url = id ? `${API_URLS.EDIT_GROUP}` : `${API_URLS.CREATE_GROUP}`;
  return id ? client.put(url, body) : client.post(url, body);
}

async function getAllChatFiles(
  chatRoomId: number,
  type?: string,
): Promise<ChatImgFile> {
  let url = `${API_URLS.GET_ALL_FILES_BY_ROOM_ID}?chatRoomId=${chatRoomId}`;

  if (type) {
    url = `${url}&type=${type}`;
  }
  return client.get(url);
}

export function deleteChatMessageId(
  id?: number | undefined,
): Promise<IResponse> {
  return client.delete(`${API_URLS.DELETE_CHAT_MESSAGE}/${id}`);
}
function deleteChatRoom(id?: number | undefined): Promise<IResponse> {
  return client.delete(`${API_URLS.DELETE_CHAT_ROOM}/${id}`);
}

async function chatMessageEdit(body: IChatMessage): Promise<IResponse> {
  const url = `${API_URLS.EDIT_CHAT_MESSAGE}`;
  return client.put(url, body);
}

async function chatJoin(
  connectionId: string,
  userId: number,
  roleName: string,
  type: string,
): Promise<IResponseChats> {
  const url =
    type === "remove"
      ? API_URLS.CHAT_MESSAGE_UNJOIN
      : API_URLS.CHAT_MESSAGE_JOIN;

  return client.get(
    `${url}?connectionId=${connectionId}&userId=${userId}&role=${roleName}`,
  );
}

async function removeSignalR(userId: number): Promise<IResponseChats> {
  const url = API_URLS.REMOVE_SIGNALR;
  return client.get(`${url}?userId=${userId}`);
}

async function logoutAgent(userId: number): Promise<IResponseChats> {
  return client.delete(`${API_URLS.LOGOUT_AGENT}/${userId}`);
}
async function callHangupAgent(userId: number): Promise<IResponseChats> {
  return client.delete(`${API_URLS.CALL_HANGUP_AGENT}/${userId}`);
}
async function pinChatRoom(chatId: number): Promise<IResponse> {
  const url = `${API_URLS.PIN_CHAT_MESSAGE}?id=${chatId}`;
  return client.post(url);
}
async function unPinChatRoom(chatId: number): Promise<IResponse> {
  const url = `${API_URLS.UN_PIN_CHAT_MESSAGE}?id=${chatId}`;
  return client.post(url);
}

async function readChatById(chatId: number): Promise<IResponse> {
  const url = `${API_URLS.READ_CHAT_BY_ID}?chatId=${chatId}`;
  return client.get(url);
}

async function assignChatToContact(
  roomId: number,
  contactId: number,
): Promise<IResponse> {
  const url = `${API_URLS.ASSIGN_CHAT_TO_CONTACT}?roomId=${roomId}&contactId=${contactId}`;
  return client.put(url);
}
async function unassignChatToContact(
  roomId: number,
  contactId: number,
): Promise<IResponse> {
  const url = `${API_URLS.UNASSIGN_CHAT_TO_CONTACT}?roomId=${roomId}&contactId=${contactId}`;
  return client.put(url);
}

async function checkIsLogin(userId: number) {
  const url = `${API_URLS.CHECK_ISLOGIN}?userId=${userId}`;
  return client.get(url);
}

async function saveToken(values: any) {
  const url = `${API_URLS.SAVE_TOKEN}`;
  return client.post(url, values);
}

export {
  assignChatToContact,
  callHangupAgent,
  chatJoin,
  chatMessageEdit,
  checkIsLogin,
  createGroup,
  deleteChatRoom,
  fetchChatBySearch,
  fetchChatUnreadCount,
  fetchMembers,
  fetchRoomId,
  fetchUnreadCount,
  getAllChatFiles,
  getAllChats,
  getAllReadChatInfo,
  getAllReadLaterChats,
  getChatRoomPagination,
  logoutAgent,
  pinChatRoom,
  readChatById,
  removeSignalR,
  saveToken,
  sendChatMessage,
  sendChatMessageGroup,
  unassignChatToContact,
  unPinChatRoom,
  unreadMarked,
  updateChatAsRead
};

