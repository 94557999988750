const ErrorMessages = {
  login: {
    email: "Please enter your email",
    password: "Please enter your password",
    success: "Logged successful",
  },
  signup: {
    firstName: "Please enter your first Name",
    lastName: "Please enter your last Name",
    companyName: "Please enter your Company Name",
    File: "Please Select File",
    companyDescription: "Please enter your Company Description",
    confirmPassword: "Password does not match",
  },
  team: {
    faceName: "Please enter your face name",
    fullName: "Please enter your full name",
    email: "Please enter your email",
    role: "Please select role",
    password: "Please enter your password",
    password_matches:
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
    success: "Logged successful",
    validate: {
      fullName: "name can not be a number",
      email: "Please enter a valid email address",
    },
  },
  screens: {
    name: "Please enter screen name",
    code: "Please enter screen code",
    url: "Please enter screen url",
    invalid_url: "Please enter correct url",
  },
  pipeline: {
    name: "Please enter pipeline name",
  },
  callerId: {
    PhoneNumber: "Please enter phone number",
    FriendlyName: "Please enter friendly name",
  },

  formBuilder: {
    label: "Please enter label ",
  },
  roles: {
    name: "Please enter role name",
  },
  leadSource: {
    name: "Please enter  name",
  },
  leadGroup: {
    name: "Please enter group name.",
    distribution: "Select atleast One member.",
  },
  leadStatus: {
    name: "Please enter name",
    updated: "Lead Status updated successfully!!",
  },
  screensAccess: {
    role: "Please select role",
  },
  callingScript: {
    script: "Please enter script name.",
    title: "Please enter Title.",
  },
  emailConfiguration: {
    email: "Please enter email .",
    appPassword: "Please enter appPassword .",
    password: "Please enter password .",
  },
  permissions: {
    screen: "Please select screen",
    permission: "Please enter permission",
    permissionDesc: "Please enter permission description",
    permissionCode: "Please enter permission code",
    screenUrl: "Please enter screen url",
    screenCode: "Please enter screen code",
  },
  forgotPassword: {
    resetLink: "We have e-mailed Your password reset link!",
    password_Change: "Password changed successfully",
    confirmPassword: "Your passwords do not match.",
  },
  setting: {
    notion_token: "Please enter notion token",
  },
  pages: {
    pageName: "Please enter page name",
    pageCode: "Please enter page code provided by notion.io",
  },
  tags: {
    name: "Please add tag name",
    tagAssigned: "tag assigned successfully!",
  },
  forms: {
    title: "Please enter form title",
    desc: "Please enter form description",
  },
  pages_access: {
    pageId: "Please select page.",
    userId: "Please select user",
  },
  address: {
    address: "please enter address",
    success: "Address added successfully!!",
    updated: "Address updated successfully!!",
    deleted: "Address deleted successfully!!",
  },
  agreement: {
    address: "please enter address",
    success: "Address added successfully!!",
    updated: "Address updated successfully!!",
    signed: "Agreement submmited successfully!!",
  },
  uploadAgreement: {
    title: "please enter title",
    // success: "Address added successfully!!",
    // updated: "Address updated successfully!!",
    // signed: "Agreement submmited successfully!!",
  },
  add_contact: {
    // Contact Info
    countryCode: "Please select country code",
    firstName: {
      firstName: "Please enter your first name.",
      validate: "First name can not be a number.",
    },
    lastName: {
      lastName: "Please enter your last name.",
      validate: "Last name can not be a number.",
    },
    number: {
      phone: "please enter your phone number.",
      mobile: "please enter your mobile number.",
      max: "Number cannot exceed 10 digits.",
      validate: "Number cannot be string or characters.",
    },
    // mobile: "please enter your mobile no",
    email: "Please enter your email",
    secondEmail: "Please enter your 2nd email",
    companyName: "Please enter company name",
    companyWebsite: "please enter company website",
    salesRep: "please select sales rep",
    entityType: "please select entity type",
    leadStatus: "please select lead status",
    pipelineLead: "please select pipeline lead",
    followUp: "please select follow up",
    recordOwner: "please select record owner",
    leadSource: "please select lead source",
    emailFollowUpOptions: "please select email follow up options",
    role: "please select role",
    description: "please enter description",
    referredBy: "select referral",
    dateContractsSent: "please enter date contract sent",
    taxId: "please enter tax id",
    seqStartDate: "please select seq start date",

    // Address
    googleAddress: "please enter google location",
    street: "please enter street",
    state: "please enter state",
    city: "please enter city",
    zipCode: "please enter zip code",
    country: "please enter country",

    // Contract Data
    serviceAgreementMonth: "please enter service agreement month",
    setupFree: "please enter setup free",
    employeesType: "please select empolyee type",
    serviceAgreementDays: "please select service agreement in days",
    hoursDayWorked: "please select hours per day worked",
    virtualEmployees: "please select virtual employees",
    rate: "please enter rate",
    totalHoursMonth: "please select total hours per month",
    perApptFee: "please enter PER APPT FEE",
    totalHoursCycle: "please enter total hours per cycle",
    daysPriorCancel: "please enter days prior to cancel",

    // CC(credit card)
    ccName: "please enter your name",
    type: "please select type",
    cardNumber: "please enter card number",
    expiryDate: "please enter expiry date",
    security: "please enter security (cvv)",
    address: "please enter address",
    dateOfPayment: "please enter date of payment",
  },

  create_contact: {
    listName: "please enter list name",
    listDescription: "please enter list description",
  },
  create_deal: {
    dealTitle: "please enter deal title",
    dealValue: "please enter deal value",
    selectPipLine: "please select pipeline id",
    leadStatusId: "please select lead status id",
    estimateCloseDate: "please select estimate close date",
  },
  notes: {
    addNotes: "please add note",
    success: "Note added successfully!!",
    updated: "Note updated successfully!!",
    deleted: "Note deleted successfully!!",
  },
  files: {
    addFiles: "please add files",
    success: "files added successfully!!",
    updated: "files updated successfully!!",
    deleted: "files deleted successfully!!",
  },
  templates: {
    subject: "Please enter subject",
    templatesText: "Please enter template text",
  },

  edit_contact: {
    firstName: "please enter your first name.",
    lastName: "please enter your last name.",
    email: "please enter your email",
    phoneNumber: "please enter phone number",
    lastContacted: "please enter last connected",
    leadStatus: "please select lead status",
    success: "Contact updated successfully!!",
  },

  edit_contract: {
    serviceAgreementMonth: "please enter your service Agreement Month",
    setupFee: "please enter your setup Fee",
    employeeType: "please enter your employee Type",
    serviceAgreementDays: "please enter your service Agreement Days",
    hoursPerDayWork: "please enter your hours Per Day Work",
    virtualEmployees: "please enter your virtual Employees",
    rate: "please enter your rate",
    totalHoursPerMonth: "please enter your total Hours PerMonth",
    perAppointmentFee: "please enter your per Appointment Fee",
    totalHoursPerCycle: "please enter your total Hours Per Cycle",
    daysPriorTocancel: "please enter your days Prior sTocancel",
    success: "Contract added successfully!!",
    updated: "Contract updated successfully!!",
  },

  activity: {
    activityTypeId: "Please select activity type",
    contactId: "Please select contact",
    title: "Please enter your title",
    description: "please select description",
    assignTo: "please select who to assign",
    duration: "please select duration",
    date: "please select date",
    time: "please select time",
    contactName: "please enter contact name",
    setReminderBefore: "please enter before meeting min",
    success: "Activity added successfully!!",
    updated: "Activity updated successfully!!",
    deleted: "Activity deleted successfully!!",
  },
  other: {
    preference: "Preference saved successfully!!",
  },
  send_message: {
    text: "please type your message.",
    to: "please select to number.",
  },
  send_email: {
    to: "please select to",
    text: "please type your body.",
    emailTo: "please select email.",
    subject: "Please enter subject",
    videoUrl: "Please add video",
    throttleTime: "Please enter Throttle Time",
    records: "Please enter records per  Throttle Time",
  },
  addDeal: {
    leadSource: "please select lead source",
    leadStatusId: "please select a stage",
    dealTitle: "please select deal title",
    dealValue: "please enter deal value",
    estimateCloseDate: "please select estimate close date",
    firstName: "please enter first name",
    lastName: "please enter last name",
    email: "please enter email",
    phone: "please enter phone no",
    searchLead: "please select lead",
    valid: {
      dealValue: "deal value can not be a string",
    },
  },
  reports: {
    success: "Report added successfully!!",
    updated: "Report updated successfully!!",
    deleted: "Report deleted successfully!!",
  },
  addFolder: {
    folderName: "please add folder name",
  },
  createGroup: {
    name: "enter group name",
  },
  payment: {
    cardNumber: "Please enter card number",
    cardCode: "Please enter cvv number",
    expirationDate: "Please enter expire date",
    cardName: "Please enter your name",
    email: "Please enter your email",
    address: "Please enter your  address",
    city: "Please enter your  city",
    country: "Please enter your  country",
    state: "Please enter your  state",
    zip: "Please enter your  zip",
    cardSign: "Please sign here",
    paidDate: "Please select payment date",
    addPayment: {
      balancePayment: {
        valid: "Balance cannot be empty",
        min: "Balance cannot be less than 0",
        number: "Balance cannot be other than number",
      },
      downPayment: {
        // valid: "Balance cannot be empty",
        min: "Balance cannot be less than 0",
        max: "Balance cannot be greater than total amount",
        number: "Balance cannot be other than number",
      },
      totalAmount: {
        min: "Total Amount cannot be less than 1",
        number: "Total Amount cannot be other than number",
      },
      paymentItem: {
        valid: "Please select invoice item",
        amount: "Please add item amount",
        qty: "quantity cannot be empty",
        qtyMin: "quantity cannot be less then 1",
        amountMin: "Amount cannot be 0",
        min: "cannot be less than 0",
        number: " cannot be other than number",
      },
      CCard: {
        ccNumber: "please add card number ",
        ccDate: "Please add Expiry date",
        cvv: "please add cvv number",
        ccVvMin: "cvv cannot be less then 3",
        amountMin: "Amount cannot be 0",
        min: "card number cannot be less than 10",
        number: " cannot be other than number",
      },
      paymentMethod: "Please add payment method",
      recordOwner: "Please add payment Record owner",
    },
  },
  callResult: {
    title: "title cannot be empty",
  },
  disposition: {
    statusCode: "Status Code cannot be empty",
    description: "Description cannot be empty",
  },
  campaignTimeSlot: {
    title: "Description cannot be empty",
  },
  callCampaign: {
    name: "Name cannot be empty",
    file: "Please select contact list",
    saleStatuses: "Status cannot be empty",
    dialableStatuses: "Status cannot be empty",
    contact: "Contact cannot be empty",
    setter: "Appointment setter cannot be empty",
    callerID: "Caller cannot be empty",
    rank: "rank cannot be empty",
    key: "key cannot be empty",
    campaignType: "CampaignType cannot be empty",
    agentSelectionMethod: "Agent Selection Method cannot be empty",
    dialableLeadsAlertThreshold:
      "Dialable Leads Alert Threshold cannot be empty",
    dropRateAlertThresholdPercent:
      "Drop Rate Alert ThresholdPercent cannot be empty",
    listOrder: "List Order cannot be empty",
    minimumDialQueueSize: "MinimumDialQueueSize cannot be empty",
    defaultTransferCallerId: "DefaultTransferCallerId cannot be empty",
  },
  powerDial: {
    myListId: "Please select my list",
    campaignId: "Please select campaign",
  },
};

export default ErrorMessages;
