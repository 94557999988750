/**
 * @format
 */
import ErrorMessages from "constants/ErrorMessages";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import { ICreateGroupRequest } from "../query/useApiAction";

const defaultValues: ICreateGroupRequest = {
  id: 0,
  userId: 0,
  name: "",
  members: [],
};

const schema = Yup.object().shape({
  name: Yup.string().required(ErrorMessages.createGroup.name),
  members: Yup.array()
    .of(Yup.string().required("Member is required"))
    .required("At least one member is required"),
});

const useCreateGroupForm = (
  onSubmit: (
    values: ICreateGroupRequest,
    formikHelpers: FormikHelpers<ICreateGroupRequest>,
  ) => void | Promise<unknown>,
  initialValues: ICreateGroupRequest = defaultValues,
) => {
  return useFormik<ICreateGroupRequest>({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit,
  });
};

export default useCreateGroupForm;
