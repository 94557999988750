/* eslint-disable import/no-extraneous-dependencies */
import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Divider, Stack, Typography } from "@mui/material";
import useUserInfo from "hooks/useUserInfo";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import { useCallManagerActions } from "redux/call-manager/callManager";
import { QueryKeys } from "utils/QueryKeys";
import {
  getPreviousRemainder,
  onMessageListener,
  requestForToken,
} from "../../config/firebase";

interface INotifiy {
  body: string;
  title: string;
  onClose: () => void;
}

export function ToastDisplay(props: INotifiy) {
  const { title, body, onClose } = props;

  return (
    <Stack
      sx={{
        width: "100%",
      }}
    >
      <Typography
        component="h3"
        sx={{
          fontWeight: "bold",
          marginBottom: 1,
          width: "300px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {title}
      </Typography>
      <Divider />
      <Typography
        component="p"
        sx={{
          width: "300px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {body}
      </Typography>

      <Box
        sx={{
          position: "absolute",
          right: "-8px",
          top: "-5px",
        }}
      >
        <CancelIcon color="error" onClick={onClose} />
      </Box>
    </Stack>
  );
}

function Notification() {
  const [notification, setNotification] = useState({ title: "", body: "" });
  const queryClient = useQueryClient();
  const location = useLocation();
  const { isLoggedIn } = useUserInfo();

  let notify: any;
  const { setCallManagerCallMessage } = useCallManagerActions();

  useEffect(() => {
    if (notification.title && isLoggedIn) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      notify = toast(
        <ToastDisplay
          body={notification?.body}
          title={notification?.title}
          onClose={onCloseHandler}
        />,
      );
      queryClient.invalidateQueries([QueryKeys.activityReminder]);
      queryClient.invalidateQueries([QueryKeys.unreadCount]);
    }
  }, [notification]);

  requestForToken();

  onMessageListener()
    ?.then((payload: any) => {
      if (payload.notification.title === "transcriptNotificationTitle") {
        setCallManagerCallMessage({
          message: { data: payload.notification.body, type: "Client" },
        });
        return null;
      }
      if (location.pathname !== "/inbox") {
        setNotification({
          title: payload?.notification?.title,
          body: payload?.notification?.body,
        });
      }
      return getPreviousRemainder();
    })
    .catch((err) => console.error("failed: ", err));

  if (!notification.title || !notification.body) {
    return null;
  }

  function onCloseHandler() {
    toast.dismiss(notify);
  }

  return (
    <Toaster
      position="bottom-right"
      reverseOrder={false}
      toastOptions={{
        style: {
          width: "320px",
          // height: 100,
          textAlign: "start",
          padding: "8px",

          marginTop: "8px",
          marginBottom: "8px",
          zIndex: 9999,
          // backgroundColor: "red",
        },
        duration: 5000,
      }}
    />
  );
}

export default Notification;
