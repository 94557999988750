import { useSnackbar } from "components/snackbar";
import ErrorMessages from "constants/ErrorMessages";
import { smsTypeConstant } from "constants/constants";
import { IResponse } from "constants/interfaces";
import useDecodedData from "hooks/useDecodedData";
import { ITemplatesRequest } from "pages/admin/settings/screens/templates/query/useApiAction";
import { useQueryClient } from "react-query";
import { useActivityFetchActions } from "redux/activity/activityFetchResponse";
import { useContactActions } from "redux/contacts/contacts";
import {
  completeActivity,
  deleteActivity,
  saveActivity,
  saveUploadAgreement,
  updateCustomData,
} from "services/activity.service";
import { generateToken } from "services/auth.service";
import {
  assignLeadOwner,
  changeLeadOwnerByContactId,
  changeLeadSourceByContactId,
  deleteNote,
  editContact,
  editContract,
  removeLeadOwnerById,
  saveAddress,
  saveNote,
  toggleInvoice,
} from "services/contacts.service";
import {
  deleteDnc,
  deleteDncByContactId,
  dncAdd,
  dncAddV1,
} from "services/dnc.service";
import {
  EditAgreement,
  addDraftMessage,
  addToDraftEmail,
  deleteAgreement,
  deleteDraftMail,
  deleteScheduledMail,
  editAgreementContent,
  editDraftEmail,
  editEmailScheduled,
  sendAgreement,
  sendDraftMail,
  sendEmail,
  sendEmailV1,
  sendMMS,
  sendMassEmail,
  sendMassSMS,
  sendMessage,
  userPlatformSetDefault,
} from "services/sender.service";
import { saveTemplate } from "services/templates.service";
import { addUserForm, editUserForm } from "services/userForm.service";
import { QueryKeys } from "utils/QueryKeys";
import { IAddActivityForm } from "../../hooks/useAddActivityForm";
import { IViewInfoForm } from "../component/contact-tabs/view-info-popup/useViewInfoForm";
import { INotesForm } from "../component/notes/useNotesForm";
import { IAddress } from "../hooks/useAddressForm";
import { IEditAgreementForm } from "../hooks/useEditAgreementForm";
import { IEditContact } from "../hooks/useEditContactForm";
import { IEditContract } from "../hooks/useEditContractForm";

export interface IUploadAgreementRequest {
  File: string;
  Title: string;
  ContactId: number;
  UserId: number;
  AgreementSentDate: string;
  AgreementSignedDate: string;
}

export interface IPreference {
  preferenceName: string;
  preferences: string;
}

export interface IDnc {
  id?: number;
  userId?: number;
  roleId?: number;
  contactId?: number;
  createdBy?: number;
  level?: number;
  phone?: string;
  IsGlobal?: boolean;
  isAll?: boolean;
  isSales?: boolean;
  isAdmin?: boolean;
  isAppointmentSetter?: boolean;
  roleName?: string;
  CustomerId?: number;
  file?: string;
}

export interface IEditAgreement {
  id: number;
  contactId: number;
  // userId: number;
  subject: string;
}

export interface IEditUserForm {
  contactFormId: number;
  contactFormContent: string;
}

const useApiActions = () => {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const decode = useDecodedData();
  const { resetContactIds } = useContactActions();
  const { setActivityFetch } = useActivityFetchActions();

  const trySaveActivity = async (values: IAddActivityForm) => {
    try {
      const newValues = {
        ...values,
        // time: `${values.date}T${values.time}:00Z`,
        // date: `values.date`,
        durationCount: Number(values.durationCount || 0),
      };
      const response: IResponse = await saveActivity({
        ...newValues,
        userId: Number(decode.id),
      });
      setActivityFetch({ activityFetch: response || "" });

      if (response.statusCode === 200) {
        queryClient.invalidateQueries([
          QueryKeys.activities,
          values?.contactId,
        ]);
        queryClient.invalidateQueries([QueryKeys.activityReminder]);
        queryClient.invalidateQueries([QueryKeys.activitiesToday]);
        queryClient.invalidateQueries([QueryKeys.dueToday, newValues.id]);
        queryClient.invalidateQueries([
          QueryKeys.activitiesToday,
          values.contactId,
        ]);
        // queryClient.invalidateQueries([QueryKeys.activities]);
        queryClient.invalidateQueries([QueryKeys.allActivityByUserIdByMonth]);
        queryClient.invalidateQueries([QueryKeys.allActivityAtOnce]);

        queryClient.invalidateQueries([QueryKeys.allCounts, values?.contactId]);
        // queryClient.invalidateQueries([
        //   QueryKeys.activities,
        //   values?.contactId,
        // ]);
        queryClient.invalidateQueries([
          QueryKeys.getAllActivities,
          values?.contactId,
        ]);
        queryClient.invalidateQueries([QueryKeys.allActivityByUserId]);
        queryClient.invalidateQueries([QueryKeys.activitiesToday]);

        snackbar?.show({
          title: values?.id
            ? ErrorMessages?.activity.updated
            : ErrorMessages?.activity.success,

          type: "success",
        });
      }
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
    }
  };

  const trySaveUploadAgreement = async (values: any, contactId: any) => {
    // const newVal = { ...values, userId: Number(decoded?.id) };

    try {
      const response: IResponse = await saveUploadAgreement(values);
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.agreementById, contactId]);
        queryClient.invalidateQueries([QueryKeys.allCounts, contactId]);
        queryClient.invalidateQueries([QueryKeys.allActivityAtOnce]);
        snackbar?.show({
          title: response?.message,
          type: "success",
        });
      }
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
    }
  };

  const tryUpdateCustomData = async (values: any) => {
    try {
      const response: IResponse = await updateCustomData(values);
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.contacts, values?.contactId]);

        snackbar?.show({
          title: values?.id
            ? ErrorMessages?.activity.updated
            : ErrorMessages?.activity.success,

          type: "success",
        });
      }
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
    }
  };

  const tryEditContact = async (values: IEditContact) => {
    try {
      const response: IResponse = await editContact({
        ...values,
        userId: Number(decode.id),
      });
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.contacts]);
        queryClient.invalidateQueries([QueryKeys.contacts, Number(values.id)]);
        queryClient.invalidateQueries([QueryKeys.getContactByDripCampaignId]);
        snackbar?.show({
          title: ErrorMessages.edit_contact.success,
          type: "success",
        });
      }
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return msg;
    }
  };

  const tryEditAgreement = async (values: IEditAgreement) => {
    try {
      const response: IResponse = await EditAgreement({
        ...values,
        userId: Number(decode.id),
      });
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.getEmails]);
        snackbar?.show({
          title: "agreement edit successfully!!",
          type: "success",
        });
      }
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return msg;
    }
  };

  const tryEditUserAgreementForm = async (value: IEditAgreementForm) => {
    try {
      const response: any = await editAgreementContent(value);
      // userId: Number(decode.id),
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.agreementById]);
        queryClient.invalidateQueries([QueryKeys.allCounts]);
        snackbar?.show({
          title: response.message,
          type: "success",
        });
      }
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return err;
    }
  };

  const trySendEmail = async (values: any) => {
    try {
      const response: IResponse = await sendEmail({
        ...values,
        userId: Number(decode.id),
      });
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.getEmails]);
        snackbar?.show({
          title: response.message,
          type: "success",
        });
      }
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return false;
    }
  };

  const trySendEmailV1 = async (values: any) => {
    try {
      const response: IResponse = await sendEmailV1({
        ...values,
        userId: Number(decode.id),
      });
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.getEmailsV2]);
        queryClient.invalidateQueries([QueryKeys.getAllConversation]);
        snackbar?.show({
          title: response.message,
          type: "success",
        });
      }
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });

      if (err?.statusCode === 404) {
        return err;
      }

      // const response = {
      //   data: err?.data,
      //   msg: err?.message,
      // };
      return false;
    }
  };

  const tryAddToDraftEmail = async (values: any) => {
    try {
      const response: IResponse = await addToDraftEmail({
        ...values,
        userId: Number(decode.id),
      });
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.getEmailsV2]);
        queryClient.invalidateQueries([QueryKeys.getAllConversation]);
        snackbar?.show({
          title: response.message,
          type: "success",
        });
      }
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });

      if (err?.statusCode === 404) {
        return err;
      }

      // const response = {
      //   data: err?.data,
      //   msg: err?.message,
      // };
      return false;
    }
  };

  const tryEditDraftEmail = async (values: any) => {
    try {
      const response: IResponse = await editDraftEmail({
        ...values,
        userId: Number(decode.id),
      });
      if (response.statusCode === 200) {
        console.log("response", response.message);
      }
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });

      if (err?.statusCode === 404) {
        return err;
      }
      return false;
    }
  };

  const trySendDraftMail = async (emailId: number) => {
    try {
      const response: IResponse = await sendDraftMail(emailId);
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.getEmailsV2]);
        snackbar?.show({
          title: response.message,
          type: "success",
        });
      }
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return false;
    }
  };

  const tryDeleteDraftMail = async (emailId: number) => {
    try {
      const response: IResponse = await deleteDraftMail(emailId);
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.getEmailsV2]);
        snackbar?.show({
          title: response.message,
          type: "success",
        });
      }
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return false;
    }
  };

  const tryEditEmailScheduled = async (values: any) => {
    try {
      const response: IResponse = await editEmailScheduled({
        ...values,
        userId: Number(decode.id),
      });
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.getEmailsV2]);
      }
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });

      if (err?.statusCode === 404) {
        return err;
      }
      return false;
    }
  };

  const tryDeleteScheduledMail = async (emailId: number) => {
    try {
      const response: IResponse = await deleteScheduledMail(emailId);
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.getEmailsV2]);
        snackbar?.show({
          title: response.message,
          type: "success",
        });
      }
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return false;
    }
  };

  const trySetDefault = async (email: string, clientId: number) => {
    try {
      const response: IResponse = await userPlatformSetDefault(email, clientId);
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.getEmailsV2]);
        snackbar?.show({
          title: response.message,
          type: "success",
        });
      }
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return false;
    }
  };

  const trySendMassSMS = async (values: any) => {
    try {
      const response: IResponse = await sendMassSMS({
        ...values,
        userId: Number(decode.id),
      });
      queryClient.invalidateQueries([QueryKeys.getSms]);

      if (response.statusCode === 200) {
        resetContactIds();
        snackbar?.show({
          title: response.message,
          type: "success",
        });
      }
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return false;
    }
  };
  const trySendMMS = async (values: any) => {
    try {
      const response: IResponse = await sendMMS(values);

      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.getSms]);
        resetContactIds();
        snackbar?.show({
          title: response.message,
          type: "success",
        });
      }
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return false;
    }
  };
  const trySendMassEmail = async (values: any) => {
    try {
      const response: IResponse = await sendMassEmail({
        ...values,
        userId: Number(decode.id),
      });
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.getEmails]);
        resetContactIds();
        snackbar?.show({
          title: response.message,
          type: "success",
        });
      }
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return false;
    }
  };

  const trySendAgreement = async (values: any) => {
    try {
      const response: any = await sendAgreement({
        ...values,
        userId: Number(decode.id),
      });
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.getEmails]);
        queryClient.invalidateQueries([
          QueryKeys.agreementById,
          values?.contactId,
        ]);
        queryClient.invalidateQueries([QueryKeys.allCounts, values?.contactId]);
        snackbar?.show({
          title: response.message,
          type: "success",
        });
      }
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return err;
    }
  };

  const tryDeleteAgreement = async (id: number) => {
    try {
      const response: IResponse = await deleteAgreement(id);
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.getEmails]);
        snackbar?.show({
          title: response.message,
          type: "success",
        });
      }
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return msg;
    }
  };

  const trySaveTemplate = async (values: ITemplatesRequest) => {
    try {
      const response: IResponse = await saveTemplate(values);
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.templates]);
        snackbar?.show({
          title: response?.message,
          type: "success",
        });
      }
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
    }
  };

  const createToken = async (value: string) => {
    try {
      const response: IResponse = await generateToken(value);
      // if (response.statusCode === 200) {
      return response;
      // }
      // return null;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return err;
    }
  };

  const trySendMessage = async (values: any) => {
    try {
      const response: IResponse =
        values?.smsType === smsTypeConstant.Draft
          ? await addDraftMessage(values)
          : await sendMessage(values);
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.sendMessage]);
        queryClient.invalidateQueries([QueryKeys.getSms]);
        queryClient.invalidateQueries([QueryKeys.getAllConversation]);
        snackbar?.show({
          title: response.message,
          type: "success",
        });
      }
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return false;
    }
  };

  const tryEditContract = async (values: IEditContract) => {
    try {
      const response: IResponse = await editContract({
        ...values,
        rate: values.rate || 0,
        userId: Number(decode.id),
      });
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.contract]);
        queryClient.invalidateQueries([QueryKeys.contract, values?.id]);
        queryClient.invalidateQueries([QueryKeys.contacts]);
        snackbar?.show({
          title: values?.id
            ? ErrorMessages.edit_contract.updated
            : ErrorMessages.edit_contract.success,
          type: "success",
        });
      }
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
    }
  };

  const trySaveAddress = async (values: IAddress | IViewInfoForm) => {
    try {
      const response: IResponse = await saveAddress({
        ...values,
        userId: Number(decode.id),
      });
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.contacts]);
        queryClient.invalidateQueries([QueryKeys.contacts, values?.id]);
        snackbar?.show({
          title: values?.id
            ? ErrorMessages.address.updated
            : ErrorMessages.address.success,
          type: "success",
        });
      }
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return err;
    }
  };

  const trySaveNote = async (values: INotesForm) => {
    const newValues = {
      ContactId: values?.ContactId,
      Notesdescription: values?.Notesdescription,
      CustomerId: values?.CustomerId,
      AttachmentIds: values?.AttachmentIds,
      Attachmens: values?.Attachments,
      Type: values?.Type,
      UserId: decode?.id,
      Id: values?.Id,
      ParentNoteId: values?.ParentNoteId,
      NoteType: values?.NoteType,
    };
    const formData = new FormData();
    values?.TagUserId?.map((i: any) => {
      formData.append("TagUserId", i);
      return true;
    });
    values?.Files?.forEach((image: any) => {
      formData.append("Files", image);
    });
    Object.keys(newValues).forEach((key) => {
      // @ts-ignore
      formData.append(key, newValues?.[key]);
    });
    try {
      const response: IResponse = await saveNote(formData, values?.Id);
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.notes, values.ContactId]);
        queryClient.invalidateQueries([QueryKeys.clientNotes]);
        snackbar?.show({
          title: values?.Id
            ? ErrorMessages.notes.updated
            : ErrorMessages.notes.success,
          type: "success",
        });
      }
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
    }
  };

  const tryDeleteNote = async (id: number, contactId: number) => {
    try {
      const response: IResponse = await deleteNote(id);
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.notes, contactId]);
        queryClient.invalidateQueries([QueryKeys.clientNotes]);
        snackbar?.show({
          title: ErrorMessages.notes.deleted,
          type: "success",
        });
      }
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
    }
  };

  const tryDeleteActivity = async (id: number) => {
    try {
      const response: IResponse = await deleteActivity(id);
      if (response.statusCode === 200) {
        snackbar?.show({
          title: ErrorMessages.activity.deleted,
          type: "success",
        });
        queryClient.invalidateQueries([QueryKeys.allCounts]);
        queryClient.invalidateQueries([QueryKeys.allActivityByUserIdByMonth]);
        queryClient.invalidateQueries([QueryKeys.allActivityAtOnce]);
        queryClient.invalidateQueries([QueryKeys.activities]);
        queryClient.invalidateQueries([QueryKeys.allActivityByUserId]);
        queryClient.invalidateQueries([QueryKeys.activitiesToday]);
      }
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
    }
  };

  const tryCompleteActivity = async (id: number, contactId: number) => {
    try {
      const response: IResponse = await completeActivity(id);
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.activities, contactId]);
        queryClient.invalidateQueries([QueryKeys.getAllActivities, contactId]);
        queryClient.invalidateQueries([QueryKeys.allCounts, contactId]);
        queryClient.invalidateQueries([QueryKeys.allActivity]);
        queryClient.invalidateQueries([QueryKeys.dueToday]);
        queryClient.invalidateQueries([QueryKeys.activitiesToday]);

        snackbar?.show({
          title: ErrorMessages.activity.updated,
          type: "success",
        });
      }
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
    }
  };

  const changeLeadOwner = async (values: any): Promise<any> => {
    try {
      const response = await changeLeadOwnerByContactId(values);
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.contacts]);
        snackbar?.show({
          title: response.message,
          type: "success",
        });
        return response;
      }
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return err;
    }
  };

  const modifyLeadOwner = async (values: any): Promise<any> => {
    try {
      const response = await assignLeadOwner(values);
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.contacts]);
        snackbar?.show({
          title: response.message,
          type: "success",
        });
        return response;
      }
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return err;
    }
  };

  const removeLeadOwner = async (contactId: number, leadOwnerId: number) => {
    try {
      const response: IResponse = await removeLeadOwnerById(
        contactId,
        leadOwnerId,
      );
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.contacts]);
        snackbar?.show({
          title: response.message,
          type: "success",
        });
      }
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return false;
    }
  };

  const changeLeadSource = async (
    leadSourceId: number,
    contactId: number,
  ): Promise<boolean> => {
    try {
      const response = await changeLeadSourceByContactId(
        leadSourceId,
        contactId,
      );
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.contacts]);
        snackbar?.show({
          title: response.message,
          type: "success",
        });
        return true;
      }
      return false;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
    }
    return false;
  };

  const trydncAdd = async (body: IDnc): Promise<boolean> => {
    try {
      const response: IResponse = await dncAdd({
        ...body,
        userId: Number(decode.id),
        roleId: Number(decode.RoleId),
        createdBy: Number(decode.id),
        level: 1,
      });

      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.dncRoleId]);
        queryClient.invalidateQueries([QueryKeys.dndList]);
        snackbar?.show({
          title: response.message,
          type: "success",
        });
        return true;
      }
      return false;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
    }
    return false;
  };

  const tryDncAddV1 = async (body: FormData): Promise<boolean> => {
    try {
      const response: IResponse = await dncAddV1(body);

      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.dncRoleId]);
        queryClient.invalidateQueries([QueryKeys.dndList]);
        queryClient.invalidateQueries([QueryKeys.dndListByUserId]);
        snackbar?.show({
          title: response.message,
          type: "success",
        });
        return true;
      }
      return false;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
    }
    return false;
  };

  const tryDeleteDnc = async (id: number) => {
    try {
      const response: IResponse = await deleteDnc(id);
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.dndList]);
        snackbar?.show({
          title: response.message,
          type: "success",
        });
      }
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return msg;
    }
  };

  const tryDeleteDncByContactId = async (id: number) => {
    try {
      const response: IResponse = await deleteDncByContactId(id);
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.dncRoleId]);
        queryClient.invalidateQueries([QueryKeys.dndList]);
        queryClient.invalidateQueries([QueryKeys.dndListByUserId]);
        snackbar?.show({
          title: response.message,
          type: "success",
        });
      }
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return msg;
    }
  };

  const tryToggleInvoice = async (id: number): Promise<boolean> => {
    try {
      const response: IResponse = await toggleInvoice(id);

      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.contacts]);
        snackbar?.show({
          title: response.message,
          type: "success",
        });
        return true;
      }
      return false;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
    }
    return false;
  };

  const trySendUserForm = async (values: any) => {
    try {
      const response: any = await addUserForm({
        ...values,
        userId: Number(decode.id),
      });
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.getAllContactsForm]);
        // queryClient.invalidateQueries([
        //   QueryKeys.agreementById,
        //   values?.contactId,
        // ]);
        queryClient.invalidateQueries([QueryKeys.allCounts, values?.contactId]);
        snackbar?.show({
          title: response.message,
          type: "success",
        });
      }
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return err;
    }
  };

  const tryEditUserForm = async (value: IEditUserForm) => {
    try {
      const response: any = await editUserForm(value);
      // userId: Number(decode.id),
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.getAllContactsForm]);
        queryClient.invalidateQueries([QueryKeys.allCounts]);
        snackbar?.show({
          title: response.message,
          type: "success",
        });
      }
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return err;
    }
  };

  return {
    tryDeleteDnc,
    tryEditUserForm,
    tryDeleteDncByContactId,
    trySendUserForm,
    tryDncAddV1,
    tryEditContact,
    tryEditContract,
    trySaveAddress,
    trySaveNote,
    tryDeleteNote,
    trySaveActivity,
    tryDeleteActivity,
    tryCompleteActivity,
    changeLeadSource,
    removeLeadOwner,
    changeLeadOwner,
    modifyLeadOwner,
    trydncAdd,
    createToken,
    trySendEmail,
    trySaveTemplate,
    trySendMessage,
    trySendAgreement,
    tryToggleInvoice,
    tryUpdateCustomData,
    trySendMassEmail,
    trySendMassSMS,
    trySendMMS,
    trySaveUploadAgreement,
    trySendEmailV1,
    tryAddToDraftEmail,
    tryEditDraftEmail,
    tryEditEmailScheduled,
    tryDeleteScheduledMail,
    trySendDraftMail,
    tryDeleteDraftMail,
    trySetDefault,
    tryDeleteAgreement,
    tryEditUserAgreementForm,
    tryEditAgreement,
  };
};

export { useApiActions };
