import { Card, CardContent, Typography } from "@mui/material";
import TabPanel from "components/TabPanel";
import TabsPanel from "components/TabsPanel";
import React, { useState } from "react";
import ActivityEmailClient from "./components/activity-email-client";
import ActivityEmailUser from "./components/activity-email-user";
import ActivitySmsClient from "./components/activity-sms-client";
import ActivitySmsUser from "./components/activity-sms-user";

interface IProps {
    formik: any;
    // data?: any[];
    //   onSave?: (text: string) => void;
}

enum EActivityTemplateType {
    SMS_CLIENT_INVITE = "SMS Client Invite",
    Email_CLIENT_INVITE = "Email Client Invite",
    SMS_USER_INVITE = "SMS User Invite",
    Email_USER_INVITE = "Email User invite",
    SMS_CLIENT_REMINDER = "SMS Client reminder",
    Email_CLIENT_REMINDER = "Email Client reminder",
    SMS_USER_REMINDER = "SMS User reminder",
    Email_USER_REMINDER = "Email User reminder",
}
export enum EActivityTemplateTypeFilter {
    CLIENT_INVITE_SMS = "activity-sms-client-invite",
    CLIENT_INVITE_Email = "activity-email-client-invite",
    USER_INVITE_SMS = "activity-sms-user-invite",
    USER_INVITE_Email = "activity-email-user-invite",
    CLIENT_REMINDER_SMS = "activity-sms-client-reminder",
    CLIENT_REMINDER_Email = "activity-email-client-reminder",
    USER_REMINDER_SMS = "activity-sms-user-reminder",
    USER_REMINDER_Email = "activity-email-user-reminder",
}

function ActivityTemplates(props: IProps) {
    const { formik } = props;
    const [value, setValue] = useState(0);

    const handleChangeTab = async (newValue: number) => {
        setValue(newValue);
        formik?.resetForm();
    };

    const templateTabsData = [
        {
            label: EActivityTemplateType.SMS_CLIENT_INVITE,
            component: (
                <div>
                    <ActivitySmsClient
                        type={EActivityTemplateTypeFilter.CLIENT_INVITE_SMS}
                    // onSave={onSave}
                    // data={data?.filter((x) => x.type === "Activity-sms")}
                    // formik={formik}
                    />
                </div>
            ),
        },
        {
            label: EActivityTemplateType.Email_CLIENT_INVITE,
            component: (
                <div>
                    <ActivityEmailClient
                        type={EActivityTemplateTypeFilter.CLIENT_INVITE_Email}
                    // data={data?.filter((x) => x.type === "Activity-email")}
                    // formik={formik}
                    />
                </div>
            ),
        },
        {
            label: EActivityTemplateType.SMS_USER_INVITE,
            component: (
                <div>
                    <ActivitySmsUser
                        type={EActivityTemplateTypeFilter.USER_INVITE_SMS}
                    // onSave={onSave}
                    // data={data?.filter((x) => x.type === "Activity-sms")}
                    // formik={formik}
                    />
                </div>
            ),
        },
        {
            label: EActivityTemplateType.Email_USER_INVITE,
            component: (
                <div>
                    <ActivityEmailUser
                        type={EActivityTemplateTypeFilter.USER_INVITE_Email}
                    // data={data?.filter((x) => x.type === "Activity-email")}
                    // formik={formik}
                    />
                </div>
            ),
        },
        {
            label: EActivityTemplateType.SMS_CLIENT_REMINDER,
            component: (
                <div>
                    <ActivitySmsClient
                        type={EActivityTemplateTypeFilter.CLIENT_REMINDER_SMS}
                    // onSave={onSave}
                    // data={data?.filter((x) => x.type === "Activity-sms")}
                    // formik={formik}
                    />
                </div>
            ),
        },
        {
            label: EActivityTemplateType.Email_CLIENT_REMINDER,
            component: (
                <div>
                    <ActivityEmailClient
                        type={EActivityTemplateTypeFilter.CLIENT_REMINDER_Email}
                    // data={data?.filter((x) => x.type === "Activity-email")}
                    // formik={formik}
                    />
                </div>
            ),
        },
        {
            label: EActivityTemplateType.SMS_USER_REMINDER,
            component: (
                <div>
                    <ActivitySmsUser
                        type={EActivityTemplateTypeFilter.USER_REMINDER_SMS}
                    // onSave={onSave}
                    // data={data?.filter((x) => x.type === "Activity-sms")}
                    // formik={formik}
                    />
                </div>
            ),
        },
        {
            label: EActivityTemplateType.Email_USER_REMINDER,
            component: (
                <div>
                    <ActivityEmailUser
                        type={EActivityTemplateTypeFilter.USER_REMINDER_Email}
                    // data={data?.filter((x) => x.type === "Activity-email")}
                    // formik={formik}
                    />
                </div>
            ),
        },
    ];
    return (
        <Card>
            <CardContent>
                <Typography sx={{ paddingBottom: "1rem" }} variant="h4">
                    Activity Templates
                </Typography>
                <TabsPanel
                    setValue={handleChangeTab}
                    tabs={templateTabsData}
                    value={value}
                />
                {templateTabsData.map((tab, index) => {
                    return (
                        <TabPanel key={tab.label} index={index} value={value}>
                            {tab?.component}
                        </TabPanel>
                    );
                })}
            </CardContent>
        </Card>
    );
}

export default React.memo(ActivityTemplates);
