import CallIcon from "@mui/icons-material/Call";
import CallEndIcon from "@mui/icons-material/CallEnd";
import ClearIcon from "@mui/icons-material/Clear";
import PersonIcon from "@mui/icons-material/Person";
import WestIcon from "@mui/icons-material/West";
import { Box, IconButton, Snackbar, Stack, Typography } from "@mui/material";
import { SocketContext } from "SocketContex";
import useDecodedData from "hooks/useDecodedData";
import { useAPIActions } from "pages/call-manager/query/useApiActions";
import { useContext, useEffect, useState } from "react";
import states from "../states";

interface IProps {
  handleAcceptCall: any;
  handleEndCall: any;
  open: any;
  number: string;
  handleColaps?: any;
  incomingData?: any;
  from: string;
  type: "out" | "in";
}

function IncomingCallSnackbar(_props: IProps) {
  const { handleAcceptCall, handleEndCall, open, handleColaps, incomingData } =
    _props;

  const { setTwilioCallState, setInComingData } = useContext(SocketContext);
  const { tryUpdateCallSid, tryGetCompanyDetailsByConfId } = useAPIActions();
  const [, setCompanyName] = useState("");
  const [callDetails, setCallDetails] = useState<any>();
  const decoded = useDecodedData();

  const getCompanyDetails = async () => {
    const response = await tryGetCompanyDetailsByConfId(
      incomingData?.InCommingCallSid,
    );
    if (response?.statusCode === 200) {
      setCompanyName(response?.data?.name || "");
      setCallDetails(response?.data);
      setInComingData({ ...incomingData, ...response?.data });
    }
  };

  useEffect(() => {
    if (incomingData) {
      getCompanyDetails();
    }
  }, [incomingData]);

  const handleAcceptIncomingCall = () => {
    handleAcceptCall();

    setTwilioCallState(states.ON_CALL);
    tryUpdateCallSid(
      {
        userId: Number(decoded.id),
        companyId: Number(decoded.CompanyId),
        callSid: incomingData?.CallSid,
        IncomingCallSid: incomingData?.InCommingCallSid,
        identity: incomingData?.To,
        confId: new Date().getTime().toString(),
        ...{ ...incomingData, ...callDetails },
      },
      "inbond",
    );
  };
  const handleReject = () => {
    handleEndCall("end");
    setTwilioCallState(states.READY);
  };
  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      autoHideDuration={6000}
      open={open}
    >
      <Box
        alignItems="center"
        borderRadius="10px"
        boxShadow={2}
        display="flex"
        height="80px"
        justifyContent="center"
        padding="10px"
        sx={{ backgroundColor: "#ffffff", border: "1px solid #000" }}
        width="550px"
      >
        <Box
          borderRadius="50%"
          display="flex"
          height="60px"
          m="3px"
          sx={{ backgroundColor: "#f5f7f9", mr: 1 }}
          width="60px"
        >
          <PersonIcon
            fontSize="large"
            sx={{ margin: "auto", color: "#cecfd1" }}
          />
        </Box>
        <Box>
          <Stack alignItems="center" direction="row">
            <Box>
              <Typography sx={{ fontSize: 15 }}>
                {callDetails?.toNumber}
              </Typography>
              <Typography sx={{ fontSize: 12, ml: 1 }}>
                {callDetails?.companyName}
              </Typography>
            </Box>
            <Box>
              <WestIcon sx={{ mx: 2 }} />
            </Box>
            <Box>
              <Typography sx={{ fontSize: 15 }}>
                {incomingData?.From}
              </Typography>
              <Typography sx={{ fontSize: 12, ml: 1 }}>
                {callDetails?.contactName || "unknown"}{" "}
                {callDetails?.clientName ? `(${callDetails?.clientName})` : ""}
              </Typography>
            </Box>
          </Stack>
          {/* <h4 style={{ fontSize: "18px" }}>
            {callDetails?.toNumber} {"<-"} {incomingData?.From}
          </h4>{" "}
          <p style={{ fontSize: "12px" }}>{callDetails?.name}</p> */}
        </Box>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          m="auto"
        >
          <IconButton
            sx={{
              backgroundColor: "#f74444",
              width: "40px",
              height: "40px",
              m: "2px",
              borderRadius: "50%",
              display: "flex",
            }}
            onClick={handleReject}
          >
            <CallEndIcon sx={{ margin: "auto", color: "#ffffff" }} />
          </IconButton>
          <IconButton
            sx={{
              backgroundColor: "#68ebca",
              width: "40px",
              height: "40px",
              m: "2px",
              borderRadius: "50%",
              display: "flex",
            }}
            onClick={handleAcceptIncomingCall}
          >
            <CallIcon sx={{ margin: "auto", color: "#ffffff" }} />
          </IconButton>
        </Box>

        <ClearIcon
          sx={{
            color: "#cecfd1",
            position: "absolute",
            right: 3,
            top: 3,
            fontSize: 15,
          }}
          onClick={handleColaps}
        />
      </Box>
    </Snackbar>
  );
}

export default IncomingCallSnackbar;
