/**
 * @format
 */
import { FormikHelpers, useFormik } from "formik";
import { IAddActivityForm } from "pages/user/contacts/hooks/useAddActivityForm";
import * as Yup from "yup";

export interface IAddTaskForm {
  id?: number;
  contactId: number | string;
  userId?: number | string;
  activityTypeId?: number | string;
  title?: string;
  descrition?: string;
  assignToId?: number[];
  date: string | Date;
  time?: string;
  duration?: string;
  durationCount?: number;
  isSendEmailToAttendee?: boolean;
  isSendSmsToAttendee?: boolean;
  timeStamp?: string;
  isReminder?: boolean;
  setReminderBefore?: number | string;
  // isToggle?: any;
  contactName?: string;
  contactEmail?: string;
}

const defaultValues: IAddActivityForm = {
  contactId: "",
  userId: 0,
  activityTypeId: 0,
  title: "",
  descrition: "",
  assignToId: [],
  date: "",
  time: "",
  duration: "",
  contactName: "",
  contactEmail: "",
  setReminderBefore: "",
  colour: "",
  attendeeEmail: [],
  isReminder: false,
  isSendEmailToAttendee: false,
  isSendSmsToAttendee: false,
  isCompleted: false,
  callerId: "",
  //  ----invite template -start
  smsInviteTemplateClient: "",
  emailInviteTemplateClient: "<p></p>",
  emailInviteTemplateSubjectClient: "",
  smsInviteTemplateUser: "",
  emailInviteTemplateUser: "<p></p>",
  emailInviteTemplateSubjectUser: "",
  // ----invite-template end
  // ----- reminder-template - start
  smsReminderTemplateClient: "",
  emailReminderTemplateClient: "<p></p>",
  emailReminderTemplateSubjectClient: "",
  smsReminderTemplateUser: "",
  emailReminderTemplateUser: "<p></p>",
  emailReminderTemplateSubjectUser: "",
  //  ----reminder-template - end
  // smsTemplateTemp: "",
  // emailTemplateTemp: "<p></p>",
  // emailTemplateSubjectTemp: "",
  emailSign: "",
  fromEmail: "",
  userEmails: [],
  userMobiles: [],
};
const schema = Yup.object().shape({
  //   contactId: Yup.number().required(ErrorMessages.activity.contactId),
  //   activityTypeId: Yup.string().required(ErrorMessages.activity.activityTypeId),
  //   title: Yup.string().required(ErrorMessages.activity.title),
  //   assignToId: Yup.string().required(ErrorMessages.activity.assignTo),
  //   descrition: Yup.string().required(ErrorMessages.activity.description),
  //   duration: Yup.string().required(ErrorMessages.activity.duration),
  //   date: Yup.string().required(ErrorMessages.activity.date),
  //   time: Yup.string().required(ErrorMessages.activity.time),
});
const useTaskForm = (
  onSubmit: (
    values: IAddActivityForm,
    formikHelpers: FormikHelpers<IAddActivityForm>,
  ) => void | Promise<unknown>,
  initialValues: any = defaultValues,
) => {
  return useFormik<IAddActivityForm>({
    initialValues,
    enableReinitialize: true,
    validationSchema: schema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit,
  });
};

export default useTaskForm;
